import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Table } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import PageTemplate from '../../../../template/PageTemplate';
// import { Constants } from '../../../utils';
import { getAllCourses } from 'services/course';
import { findTopicsByCourseRequest } from 'services/topics';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: '100%'
  }
};

export const CourseModulesTopics = () => {
  const history = useHistory();
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);


  const getAllCourse = async () => {
    try {
      const res = await findTopicsByCourseRequest(id);
      const _res = res.data.filter(topic => topic.active);

      setData(_res);
    } catch (error) {

    }
  }

  const columns = [
    {
      title: 'Tópicos',
      dataIndex: 'ds_titulo'
    },
    {
      title: 'Alunos',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 2 }}
            shape="circle"
            icon={<AiOutlineSearch size={18}/>}
            onClick={() => {
              history.push(`/backoffice/course-modules/topics/${id}/${record.id}`);
            }}
          />
        </span>
      )
    }
  ];

  useEffect(() => {
    if (!id) {
      history.goBack();
    }

    getAllCourse();
  }, []);

  return (
    <PageTemplate title='Curso por Módulos - Tópicos' backButton={true}>
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
};
