import React from "react";
import copyToClipboard from "copy-to-clipboard";
import { message, Space, Table, Tooltip, Card, Tag } from "antd";
import { Filters, Dates } from "utils";
import {
  FiPrinter,
  FiCopy,
  FiXCircle,
} from "react-icons/fi";
import { Colors, Metrics } from "configs";
import { getLabelByStatus } from "features/FinancialData/utils";


const styles = {
  table: {
    width: "100%",
  },
  icons: {
    cursor: "pointer",
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
    color: Colors.secondary,
  },
  opacity: {
    opacity: 0.4,
  },
};

export default function PanelContent({ data, openModal: openChargeCancelModal }) {
  function copyLinhaDigitavel(value) {
    copyToClipboard(value);
    message.success("Linha digitável copiada com sucesso.");
  }

  function printBankSlip(bankSlipUrl) {
    window.open(bankSlipUrl, "_blank");
  }

  const formatDescription = (value) => {
    const description = value.split('.')[0];
    return description;
  }

  function getColumns(type) {
    const columns = [
      {
        title: "Parcelas",
        dataIndex: "nrParcela",
        key: "nrParcela",
        render: (text, record) => <span>{type === 'CREDIT_CARD' ? record.nrParcela : formatDescription(record.description)}</span>,
      },
      {
        title: "Linha digitável",
        dataIndex: "identificationField",
        key: "identificationField",
        render: (text, record) => (
          <span
            onClick={() => copyLinhaDigitavel(record.identificationField)}
            style={styles.icons}
          >
            {record.identificationField}
          </span>
        ),
      },
      {
        title: "Valor",
        dataIndex: "value",
        key: "value",
        render: (text, record) => (
          <span>{Filters.convertMoneyTextMask(record.value)}</span>
        ),
      },
      {
        title: "Data de Vencimento",
        dataIndex: "dueDate",
        key: "dueDate",
        render: (text, record) => (
          <span>{Dates.transformDateToDayMonthYear(record.dueDate)}</span>
        ),
      },
      {
        title: "Situação",
        dataIndex: "status",
        key: "status",
        render: (text, record) => <Tag color={getLabelByStatus(record.status).color}>{getLabelByStatus(record.status).status}</Tag>,
      },
      {
        title: "Ações",
        dataIndex: "action",
        key: "action",
        width: 10,
        render: (text, record) => (
          <Space>
            {data.podeCancelar && <Tooltip title="Cancelar cobrança">
              <FiXCircle
                onClick={() => openChargeCancelModal({ data, chargeCancel: true })}
                style={styles.icons}
              />
            </Tooltip>}
            <Tooltip title="Copiar linha digitável">
              <FiCopy
                onClick={() => copyLinhaDigitavel(record.linhaDigitavel)}
                style={styles.icons}
              />
            </Tooltip>
            <Tooltip title="Imprimir">
              <FiPrinter
                onClick={() => printBankSlip(record.bankSlipUrl)}
                style={styles.icons}
              />
            </Tooltip>
          </Space>
        ),
      }
    ];

    if (type === "CREDIT_CARD") {
      const actionItems = [
        {
          title: "Total de parcelas",
          dataIndex: "nrParcela",
          key: "nrParcela",
          render: (text, record) => <span>{record.nrParcela}x</span>,
        },
        {
          title: "Valor Total",
          dataIndex: "totalPagamentos",
          key: "totalPagamentos",
          render: (text, record) => (
            <span>{Filters.convertMoneyTextMask(record.value * record.nrParcela)}</span>
          ),
        },
        {
          title: "Situação",
          dataIndex: "status",
          key: "status",
          render: (text, record) => <Tag color={getLabelByStatus(record.status).color}>{getLabelByStatus(record.status).status}</Tag>,
        },
      ];

      if (data.data_course.credit_card.find(item => item.nome_topico)) {
        actionItems.unshift({
          title: "Módulo",
          dataIndex: "nome_topico",
          key: "nome_topico",
        })
      }

      if (data.data_course.credit_card.podeCancelar) {
        actionItems.push({
          title: "Ações",
          dataIndex: "action",
          key: "action",
          width: 10,
          render: () => (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {data.podeCancelar && <Tooltip title="Cancelar cobrança">
                <FiXCircle
                  onClick={() => openChargeCancelModal({ data, chargeCancel: true })}
                  style={styles.icons}
                />
              </Tooltip>}
            </div>
          ),
        });
      }

      return actionItems;
    }


    if (data.data_course.boleto.find(item => item.nome_topico)) {
      columns.unshift({
        title: "Módulo",
        dataIndex: "nome_topico",
        key: "nome_topico",
      })
    }

    return columns;
  }

  function getAllColumns(data) {
    const allBillingTypes = data.reduce((types, item) => {
      if (!types.includes(item.billingType)) {
        types.push(item.billingType);
      }
      return types;
    }, []);

    const columns = allBillingTypes.map(billingType => {
      const columnsForType = getColumns(billingType); // Use a função getColumns para obter as colunas para cada tipo
      return columnsForType.map(column => ({
        ...column,
        key: `${billingType}_${column.key}`, // Adicione um prefixo exclusivo para cada coluna com base no billingType
      }));
    });

    // Flatten a matriz de colunas em uma única lista
    return columns.flat();
  }

  return (
    <>
      {
        data?.data_course?.boleto.length > 0 && (
          <Card title="BOLETOS" bordered={false}>
            <Table style={styles.table} columns={getAllColumns(data.data_course.boleto)} dataSource={data.data_course.boleto} />
          </Card>
        )
      }

      {
        data?.data_course?.credit_card.length > 0 && (
          <Card title="Cartão de crédito" bordered={false}>
            <Table style={styles.table} columns={getAllColumns(data.data_course.credit_card)} dataSource={data.data_course.credit_card} />
          </Card>
        )
      }
    </>
  );
}
