import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import TopicForm from '../Form';
import useFetch from '../../../../hooks/useFetch';
import { updateCourseRequest } from '../../../../services/course';
import { findTopicRequest, findTopicsByCourseRequest } from '../../../../services/topics';


const BackofficeTopicEdit = () => {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching: isFetchingUser, data: user }] = useFetch(findTopicsByCourseRequest, id, true, null);
  const [{ isFetching, data }, createUser] = useFetch(
    updateCourseRequest,
    '',
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Edição de Tópico" background>
      <Spin spinning={isFetching}>
        {user && (
          <TopicForm
            initialValues={user}
            handleSubmitForm={createUser}
            handleCancel={goBack}
          />
        )}
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeTopicEdit;
