import styled from "styled-components";
import { Typography } from "antd";
import { Colors, Metrics } from "configs";

const { Text } = Typography;

export const Container = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  overflow: hidden;
  > div {
    flex: 1;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ maxWidth, maxWidthValue }) =>
    maxWidth ? maxWidthValue : "initial"};
`;

export const TitleText = styled(Text)`
  color: ${Colors.primary};
  font-size: ${Metrics.fontSize.md};

  @media (max-width: 1024px) {
    font-size: ${Metrics.fontSize.md};
  }
`;

export const TitleMark = styled.div`
  border: 2px solid #dca039;
  width: 70px;
  margin-top: 15px;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ reverse }) => (reverse ? "flex-start" : "flex-end")};
  padding-right: ${Metrics.spacing.md};
`;

export const TextContainer = styled.div`
  margin-top: 50px;
  max-width: 80%;
  font-size: 1.1rem;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;
