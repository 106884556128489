import React, { useState } from "react";
import { Colors, Metrics } from "../../configs";

const styles = {
  surface: {
    backgroundColor: "white",
    borderRadius: Metrics.defaultRadius,
    elevation: 4,
    marginBottom: Metrics.spacing.md,
    boxShadow: "1px 1px 6px #cacaca",
  },
  touchable: {
    padding: Metrics.spacing.md,
    borderBottomColor: Colors.primary,
    borderRadius: Metrics.defaultRadius,
  },
  animated: { overflow: "hidden", transition: "all 0.5s ease" },
};
const Accordion = ({ header, content, bordered, active, disabled, handleBuyModule, hasBuy }) => {
  const [open, setOpen] = useState(active);
  /*  const heightAnim = useRef(new Animated.Value(0.5)).current;
  const runAnimation = Animated.timing(heightAnim, {
    toValue: 400,
    duration: 300,
    useNativeDriver: false,
  });
  const backAnimation = Animated.timing(heightAnim, {
    toValue: 0.5,
    duration: 300,
    useNativeDriver: false,
  }); */

  const handleClick = () => {
    if (disabled) return;

    if (hasBuy) {
      handleBuyModule()
      return;
    }

    setOpen((o) => !o)
  }

  return (
    <div style={styles.surface}>
      <div
        onClick={handleClick}
        style={{
          ...styles.touchable,
          borderBottom: bordered ? `1px ${Colors.primary} solid` : 0,
        }}
      >
        {header}
      </div>
      <div
        style={{
          ...styles.animated,
          maxHeight: open ? 4000 : 0.2,
          borderTop: open
            ? `1px ${Colors.primary} solid`
            : `1px transparent solid`,
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;
