import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Metrics } from '../../../../../configs';
import { CourseWizardContext } from '../index';
import useFetch from '../../../../../hooks/useFetch';
import { findTopicsByCourseRequest } from '../../../../../services/topics';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Collapse, Dropdown, Select, Spin, Table } from 'antd';
import TableMenu from '../../components/TableMenu';
import { AiOutlineMore } from 'react-icons/ai';
import { findTrainingsByTopicRequest } from '../../../../../services/training';
import { Col } from '../../../../../common';
import QuestionForm from '../../../Question/Form';
import { Toast } from '../../../../../utils';
import {
  createQuestionRequest,
  deleteQuestionRequest,
  findQuestionRequest,
  findQuestionsByTrainingsRequest,
  updateQuestionRequest,
} from '../../../../../services/question';

const { Panel } = Collapse;

const { Option } = Select;
const styles = {
  formContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    marginTop: Metrics.spacing.md}
}

const QuestionData = () => {
  const { currentStep, setCurrentStep, course, setCourse } = useContext(CourseWizardContext);
  const [activeKey, setActiveKey] = useState();
  const [currentTraining, setCurrentTraining] = useState();
  const [currentTopic, setCurrentTopic] = useState();
  const [initialValues, setInitialValues] = useState({
    treinamento_id: currentTraining,
  });
  const [{ isFetching: isFetchingTopics, data: topics}, fetchTopics] = useFetch(findTopicsByCourseRequest, course.id, true, []);
  const [{ isFetching, data }, createQuestion] = useFetch(
    createQuestionRequest,
    '',
    false,
    null,
    () => {},
    () => {
      if (currentTraining) {
        fetchQuestions(currentTraining);
      }
      setInitialValues({
        treinamento_id: currentTraining,
      })
    });
  const [{ isFetching: isFetchingUpdate }, updateQuestion] = useFetch(
    updateQuestionRequest,
    '',
    false,
    null,
    () => {},
    () => {
      if (currentTraining) {
        fetchQuestions(currentTraining);
      }
      setInitialValues({
        treinamento_id: currentTraining,
      })
    });
  const [{ isFetching: isFetchingTrainings, data: trainings}, fetchTrainings] = useFetch(findTrainingsByTopicRequest, '', false, []);
  const [{ isFetching: isFetchingTraining, data: training}, fetchQuestionById] = useFetch(
    findQuestionRequest,
    '',
    false,
    null,
    () => {},
    (result) => {
      setInitialValues(result.data);
      openAccordion();
    });
  const [{ isFetching: isFetchingQuestions, data: questions}, fetchQuestions] = useFetch(findQuestionsByTrainingsRequest, '', false, []);
  const [{ isFetching: isFetchingDeleteQuestion}, deleteQuestion] = useFetch(
    deleteQuestionRequest,
    '',
    false,
    false,
    () => {},
    () => currentTraining ? fetchQuestions(currentTraining) : () => {});
  const openAccordion = useCallback( () => setActiveKey('1'),[setActiveKey]);
  const closeAccordion = useCallback( () => setActiveKey(''),[setActiveKey]);

  useEffect(() => {
    if (currentTraining) {
      setInitialValues(i => ({ ...i, treinamento_id: currentTraining}))
      fetchQuestions(currentTraining);
    }
  },[currentTraining])

  const columns = [
    {
      title: "Título",
      dataIndex: "ds_titulo",
      key: "ds_titulo",
    },
    {
      title: "Resumo",
      dataIndex: "ds_resumo",
      key: "ds_resumo",
    },
    {
      title: "Ativo",
      dataIndex: "fg_ativo",
      key: "fg_ativo",
      width: 10,
      render: (text, record) => (
        <span>
          { record.fg_ativo ?
            <CheckCircleOutlined /> :
            <CloseOutlined />
          }
        </span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={
              <TableMenu
                record={record}
                handleRemove={deleteQuestion}
                handleEdit={(t)=> {
                  fetchQuestionById(t.id);
                }}
                deleteProp='id'
                descProp='ds_titulo'
                desc='Questão'
              />}
            placement="bottomRight"
            trigger={["click"]}>
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  return (
    <div style={styles.formContainer}>
      <h1><b>Questões e alternativas</b></h1>
      <Col style={{marginBottom: Metrics.spacing.md}}>
        <span>Selecione o <b>tópico</b>:</span>
        <Select style={{ width: 370 }} value={currentTopic} onChange={(e) => {
          setCurrentTraining('');
          setCurrentTopic(e);
          fetchTrainings(e);
        }} placeholder='Selecione...'>
          {topics.map((t, i) =>
            <Option key={i} value={t.id}>{t.ds_titulo}</Option>
          )}
        </Select>
      </Col>
      <Col style={{marginBottom: Metrics.spacing.md}}>
        <span>Selecione o <b>treinamento</b> ao qual pertencem as questões listados e a questão a ser cadastrada:</span>
        <Select style={{ width: 370 }} onChange={setCurrentTraining} value={currentTraining} placeholder='Selecione...' disabled={!currentTopic}>
          {trainings.map((t, i) =>
            <Option key={i} value={t.id}>{t.ds_titulo}</Option>
          )}
        </Select>
      </Col>
      <Collapse activeKey={activeKey} accordion onChange={(e) => {
        if (!currentTraining) {
          Toast.showErrorMessage('Selecione o treinamento ao qual esta questão deve pertencer!');
        } else {
          setActiveKey(e);
        }
      }} style={{marginBottom: Metrics.spacing.lg}}>
        <Panel header="Cadastro/Edição de Questões" key="1" >
          <QuestionForm
            handleSubmitForm={(val) => {
              if (val.id) {
                closeAccordion();
                updateQuestion(val);
              } else {
                closeAccordion();
                createQuestion(val);
              }
            }}
            initialValues={initialValues}
            handleCancel={() => {}} />
        </Panel>
      </Collapse>
      {
        currentTraining &&
          <Table style={styles.table} columns={columns} dataSource={questions} />
      }
      {isFetchingDeleteQuestion &&
      <Spin />
      }
    </div>
  );
};

export default QuestionData;
