import { Layout as LayoutAntd, Menu } from "antd";
import { Colors, Metrics } from "configs";
import styled from "styled-components";

const { Sider } = LayoutAntd;

export const ContentStyled = styled(LayoutAntd.Content)``;

export const SiderStyled = styled(LayoutAntd.Sider)`
  margin-right: ${Metrics.spacing.lg};
  background-color: ${Colors.white};
  border-radius: ${Metrics.defaultRadius};
  transition: all 0.2s ease;
  align-self: flex-start;
  padding-block: 20px;

  ul {
    transition: all 0.2s ease;
    border-radius: ${Metrics.defaultRadius};

    li {
      border-radius: ${Metrics.defaultRadius};
      transition: all 0.2s ease;

      &:hover {
        background-color: #8fdadd !important;
      }
    }

    background-color: ${Colors.white} !important;

    .ant-menu-item {
      transition: all 0.2s ease;
      background-color: ${Colors.white};
      color: ${Colors.primary};
    }
    .ant-menu-item-selected .ant-menu-item-icon + span,
    .ant-menu-dark .ant-menu-item-selected .anticon + span,
    .ant-menu-item-selected .ant-menu-item-icon,
    .ant-menu-dark .ant-menu-item-selected .anticon {
      color: unset;
    }
    .ant-menu-item-selected {
      transition: all 0.2s ease;
      background-color: transparent;
      color: unset;
    }
  }
`;

export const Layout = styled(LayoutAntd)`
  padding: 50px 5vw 0;

  > section {
    margin-top: 25px;
  }
`;

export const HeaderStyled = styled.div`
  padding: 8px 3vw;
  background-color: ${Colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: inherit;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 1px 6px #0000001a;
  border-radius: 10px;
`;

export const LogoContainer = styled.div`
  flex: 1;
`;

export const ItemsMenu = styled(Menu)`
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: center;
  padding: 0 10%;
  line-height: initial !important;
  border: 0 !important;

  .ant-menu-item-selected {
    color: ${Colors.primary} !important;
    svg {
      color: ${Colors.primary} !important;
    }
  }

  .ant-menu-item-active {
    color: ${Colors.primary} !important;

    svg,
    span {
      color: ${Colors.primary} !important;
    }
  }
`;

export const SearchMenu = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const IconWithText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px 10px;
`;

export const MenuItem = styled(Menu.Item)`
  border-bottom: none !important;
  padding: 0 20px !important;
  margin: 0 !important;
`;
