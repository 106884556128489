import styled from 'styled-components';
import { Metrics } from 'configs';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
`;

export const ButtonStyled = styled.button`
  color: white;
  text-align: center;
  font-size: 16px;
  background: #00b2a9;
  padding: 5px 20px;
  border: none;
  border-radius: 50px;
  transition: 0.3s all;
  width: 130px;
  margin: ${Metrics.spacing.min} 0;

  :hover {
    width: 150px;
    background: #41c0ba;
  }
`;
