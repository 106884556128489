import { StyledInput, StyledInputPassword } from "./styles";

const Input = ({ placeholder, prefix, password, name, ...rest }) => {
  return (
    <>
      {!password && (
        <StyledInput
          placeholder={placeholder}
          prefix={prefix}
          name={name}
          {...rest}
        />
      )}
      {password && (
        <StyledInputPassword
          placeholder={placeholder}
          prefix={prefix}
          name={name}
          {...rest}
        />
      )}
    </>
  );
};

export default Input;
