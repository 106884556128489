import React from "react";
import {Formik} from "formik";
import {Form as FormAntd, Space, Typography} from "antd";
import {Colors, Metrics} from "configs";
import Input from "common/FormInputCustom";
import {Button, FormCheckbox} from "common";
import {FiCalendar, FiCreditCard, FiInfo, FiUnlock} from "react-icons/fi";
import {ReactComponent as UserIcon} from "assets/icons/user.svg";
import {CardIcon} from "../../../../../PaymentMethod/util";
import {Filters} from "utils";
import {ValidationSchema} from "./ValidationSchema";

const { Text } = Typography;

export default function Form({
  initialValues = { numero: "", validade: "", cvv: "", nome: "" },
                               onSubmit,
}) {
  return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {({ values, handleSubmit, resetForm }) => (
          <FormAntd style={styles.form}>
            <div style={styles.wrapper}>
              <div style={styles.inputContainer}>
                <Input
                  placeholder="Apelido"
                  prefix={<FiInfo />}
                  name="apelido"
                  value={values.apelido}
                  maxLength={255}
                />
                <Input
                  placeholder="0000 0000 0000 0000"
                  prefix={<FiCreditCard />}
                  suffix={
                    <CardIcon
                      numero={values.numero}
                      bandeira={initialValues.bandeira}
                    />
                  }
                  name="numero"
                  value={Filters.formatCardNumber(values.numero)}
                  maxLength={19}
                />
                <Input
                  placeholder="Nome no cartão"
                  name="nome"
                  prefix={<UserIcon />}
                  value={values.nome}
                  maxLength={50}
                />
                <Space align="flex-start">
                  <Input
                    placeholder="DD/AAAA"
                    prefix={<FiCalendar style={{ fontSize: 20 }} />}
                    name="validade"
                    value={Filters.maskCreditCardValidity(values.validade)}
                    maxLength={7}
                  />
                  <Input
                    placeholder="CVV"
                    prefix={<FiUnlock style={{ fontSize: 20 }} />}
                    name="cvv"
                    value={values.cvv}
                    maxLength={4}
                    password
                    iconRender={() => <></>}
                  />
                </Space>
                <FormCheckbox
                  onChange={console.log}
                  name="padrao"
                  value={values.padrao}
                  label="Cartão padrão"
                  style={{ marginLeft: 16 }}
                />
              </div>
              <Button width="200px" type="submit"
                onClick={handleSubmit}>
                {initialValues && initialValues.id ? "Editar" : "Adicionar"}
              </Button>
            </div>
          </FormAntd>
        )}
      </Formik>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.white,
    padding: "10px 20px",
    borderRadius: "20px",
    minWidth: "30%",
    maxWidth: "400px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: Metrics.spacing.sm,
    marginBottom: Metrics.spacing.sm,
    width: "100%",
  },
};
