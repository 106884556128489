import styled from "styled-components";
import { Collapse as CollapseAntd } from "antd";
import { Colors } from "configs";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

const Collapse = styled(CollapseAntd).attrs((props) => ({
  expandIconPosition: "right",
  expandIcon: ({ isActive }) => {
    return isActive ? (
      <CaretUpOutlined style={styles.icon} />
    ) : (
      <CaretDownOutlined style={styles.icon} />
    );
  },
}))`
  border: none;
  background-color: initial;

  .ant-collapse-header {
    opacity: 0.44;
    background-color: ${Colors.secondary};
    border-radius: 12px 12px !important;
    border: none;
    color: ${Colors.white} !important;
    font-size: 15px;
    padding: 8px 20px !important;
    font-weight: 700;
  }

  .ant-collapse-item-active .ant-collapse-header {
    opacity: 1;
    background-color: ${Colors.primary};
    border-radius: 12px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: none;
  }

  .ant-collapse-item {
    margin-bottom: 20px;
    border: none;
  }

  .ant-collapse-content {
    border-radius: 0px 0px 12px 12px;
    border: none;
  }

  .ant-collapse-content-box {
    padding: 20px 35px;
    color: #0c0c0c;
  }
`;

const styles = {
  icon: {
    fontSize: "25px",
    padding: 0,
    bottom: "auto",
  },
};

export default Collapse;
