import React, { useCallback, useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Dropdown, Menu, Space, Table, Tooltip } from "antd";
import { Modal } from "utils";
import { AiOutlineMore, AiOutlineClose } from "react-icons/ai";
import { Colors, Metrics } from "configs";
import { Form } from "formik-antd";
import { FormButtons, FormDropdown, FormInputText } from "components";
import styled from "styled-components";
import { estados } from "services/data";
import Filters from "utils/Filters";
import { ValidationSchema } from "./ValidationSchema";

const styles = {
  table: {
    width: "100%",
  },
  title: {
    color: Colors.primary,
    fontSize: Metrics.fontSize.sm,
    fontWeight: 700,
    marginBottom: Metrics.spacingLG,
  },
};

const defaultColumns = [
  {
    title: "Nome",
    dataIndex: "nm_paroquia",
    key: "nm_paroquia",
  },
  {
    title: "CNPJ",
    dataIndex: "nu_cnpj",
    key: "nu_cnpj",
  },
  {
    title: "CEP",
    dataIndex: "nu_cep",
    key: "nu_cep",
  },
  {
    title: "UF",
    dataIndex: "sg_uf",
    key: "sg_uf",
  },
  {
    title: "Cidade",
    dataIndex: "nm_cidade",
    key: "nm_cidade",
  },
  {
    title: "Bairro",
    dataIndex: "nm_bairro",
    key: "nm_bairro",
  },
  {
    title: "Endereço",
    dataIndex: "ds_endereco",
    key: "ds_endereco",
  },
];

export default function ParishesForm({ initialValues }) {
  const generateDefaultParish = () => {
    return {
      _id: uuidv4(),
      nm_paroquia: "",
      nu_cnpj: "",
      ds_endereco: "",
      nm_bairro: "",
      nm_cidade: "",
      sg_uf: "",
      nu_cep: "",
    };
  };
  const [parish, setParish] = useState(generateDefaultParish());
  const [parishes, setParishes] = useState([]);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (initialValues) {
      setParishes(initialValues);
    }
  }, [initialValues]);

  const menu = useCallback(
    (record) => {
      const handleDeleteItem = () => {
        let newParishes = [];
        if (record.id) {
          newParishes = parishes.map((p) => {
            if (p._id === record._id) {
              return { ...p, deleted: true };
            }
            return p;
          });
        } else {
          newParishes = parishes.filter((p) => p._id !== record._id);
        }
        setParishes(newParishes);
        setFieldValue("paroquias", newParishes);
      };

      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              setParish(record);
            }}
          >
            Editar
          </Menu.Item>
          {/*<Menu.Item
            key="1"
            danger
            onClick={() =>
              Modal.openDeleteModal({
                description: (
                  <span>
                    Deseja realmente deletar a paróquia
                    <strong> {record.nm_paroquia}</strong>?
                  </span>
                ),
                onOk: () => handleDeleteItem(),
                okText: "Sim",
                cancelText: "Não",
              })
            }
          >
            Excluir
          </Menu.Item>*/}
        </Menu>
      );
    },
    [parishes, setFieldValue]
  );

  const columns = [
    ...defaultColumns,
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) =>
        !record.deleted ? (
          <span>
            <Dropdown
              overlay={menu(record)}
              placement="bottomRight"
              trigger={["click"]}
            >
              <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
            </Dropdown>
          </span>
        ) : (
          <Tooltip title="Marcado para exclusão">
            <AiOutlineClose
              size={25}
              style={{ cursor: "not-allowed", color: Colors.error }}
            />
          </Tooltip>
        ),
    },
  ];

  const handleSubmit = useCallback(
    (formValues) => {
      const haveWithId = parishes.find((p) => p._id === formValues._id);
      let newParishes;
      if (!haveWithId) {
        newParishes = [...parishes, formValues];
      } else {
        newParishes = parishes.map((p) => {
          if (p._id === formValues._id) {
            return formValues;
          }
          return p;
        });
      }
      setParishes(newParishes);
      setFieldValue("paroquias", newParishes);
      setParish(generateDefaultParish());
    },
    [parishes, setFieldValue]
  );

  return (
    <>
      <div style={styles.title}>Formulário de Paróquia</div>
      <FormParish
        initialValues={parish}
        submitForm={handleSubmit}
        handleCancel={() => setParish(generateDefaultParish())}
      />
      <div style={styles.title}>Paróquias</div>
      <Table style={styles.table} columns={columns} dataSource={parishes} />
    </>
  );
}

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

function FormParish({
  initialValues = {
    _id: uuidv4(),
    nm_paroquia: "",
    nu_cnpj: "",
    ds_endereco: "",
    nm_bairro: "",
    nm_cidade: "",
    sg_uf: "",
    nu_cep: "",
  },
  submitForm,
  handleCancel,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={ValidationSchema}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <Form>
          <CustomSpace direction="horizontal">
            <FormInputText
              placeholder="Nome"
              name="nm_paroquia"
              value={values.nm_paroquia}
              style={{ width: "100%" }}
              fullWidth
            />
            <FormInputText
              placeholder="CNPJ"
              name="nu_cnpj"
              value={Filters.inputMaskCNPJ(values.nu_cnpj)}
            />
            <FormInputText
              placeholder="CEP"
              name="nu_cep"
              value={Filters.inputMaskCEP(values.nu_cep)}
            />
            <FormDropdown
              placeholder="UF"
              name="sg_uf"
              value={values.sg_uf}
              list={estados}
            />
          </CustomSpace>
          <CustomSpace direction="horizontal">
            <FormInputText
              placeholder="Cidade"
              name="nm_cidade"
              value={values.nm_cidade}
            />
            <FormInputText
              placeholder="Bairro"
              name="nm_bairro"
              value={values.nm_bairro}
            />
            <FormInputText
              placeholder="Endereço"
              name="ds_endereco"
              value={values.ds_endereco}
            />
          </CustomSpace>
          <FormButtons
            bt2={{ label: "Salvar", onClick: handleSubmit }}
            bt1={{ label: "Cancelar", onClick: handleCancel }}
          />
        </Form>
      )}
    </Formik>
  );
}
