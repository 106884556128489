import PageTemplate from "template/PageTemplate";
import { Collapse } from "common";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import useFetch from "hooks/useFetch";
import { findAllFrequentlyAskedQuestionsRequest } from "services/frequentlyAskedQuestion";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import ContactForm from "./ContactForm";

const { Panel } = Collapse;

const Help = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [{ data, isFetching }] = useFetch(
    findAllFrequentlyAskedQuestionsRequest,
    "",
    true,
    [],
    null
  );

  useEffect(() => {
    setFilteredData(data.filter((d) => d.fg_ativo));
  }, [data]);

  return (
    <PageTemplate title="Ajuda">
      <Spin spinning={isFetching}>
        <Collapse
          expandIconPosition="right"
          expandIcon={({ isActive }) => {
            return isActive ? (
              <CaretUpOutlined style={styles.icon} />
            ) : (
              <CaretDownOutlined style={styles.icon} />
            );
          }}
        >
          {filteredData.map((d, i) => (
            <Panel header={d.ds_titulo} key={i}>
              <p>{d.ds_descricao}</p>
            </Panel>
          ))}
        </Collapse>
        <ContactForm />
      </Spin>
    </PageTemplate>
  );
};

const styles = {
  icon: {
    fontSize: "25px",
    padding: 0,
    top: "auto",
    bottom: "auto",
  },
};

export default Help;
