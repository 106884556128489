import { useFormikContext } from "formik";
import { FormItem } from "formik-antd";
import { Colors } from "../../configs";
import React from "react";
import { Checkbox } from "./styles";

const FormCheckbox = ({
  label,
  name,
  value,
  placeholder,
  required,
  showSearch,
  filterOption,
  propertyLabel = "label",
  propertyValue = "value",
  list = [],
  disabled,
  showValidateSuccess,
  onChange: onChangeParam,
  style,
}) => {
  const { getFieldMeta } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const handleChange = (newValue) => {
    if (!disabled && onChangeParam) {
      onChangeParam(newValue);
    }
  };

  return (
    <FormItem
      style={{ width: "100%", marginBottom: 0 }}
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}
    >
      {/*      {label && (
        <span style={{ color: error && touched && "#ff4d4f" }}>
          {required ? `${label} *` : label}
        </span>
      )}*/}
      <Checkbox
        style={{
          border: error ? `1px solid ${Colors.error}` : "none",
          ...style,
        }}
        value={value}
        name={name}
        onChange={handleChange}
      >
        {label}
      </Checkbox>
    </FormItem>
  );
};

export default FormCheckbox;
