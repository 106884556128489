import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  userClearTree: null,
  userListRequest: ['pageable'],
  userListSuccess: ['userList', 'totalItems'],
  userFindRequest: ['userLogin'],
  userFindSuccess: ['user'],
  userDeleteRequest: ['userLogin'],
  userDeleteSuccess: null,
  userUpdateRequest: ['user'],
  userUpdateSuccess: null,
  userCreateRequest: ['user'],
  userCreateSuccess: null,
  userError: null,
  userReset: null,
  userClearSuccess: null,
});

const INITIAL_STATE = {
  userRegistration: null,
  error: null,
  loading: false,
  registered: false,
  userList: [],
  user: null,
  tree: [],
  totalItems: null,
  createSuccess: false,
  updateSuccess: false,
  deletedSuccess: false,
};

export const userListRequest = (state = INITIAL_STATE) => ({
  ...state,
  isfetching: true,
});

export const userListSuccess = (
  state = INITIAL_STATE,
  { userList, totalItems }
) => ({
  ...state,
  userList,
  totalItems,
  deletedSuccess: false,
  isfetching: false,
});

export const userFindRequest = (state = INITIAL_STATE) => ({
  ...state,
  isfetching: true,
});

export const userFindSuccess = (state = INITIAL_STATE, { user }) => ({
  ...state,
  user,
  isfetching: false,
});

export const userDeleteRequest = (state = INITIAL_STATE) => ({
  ...state,
  isfetching: true,
});

export const userDeleteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  deletedSuccess: true,
  isfetching: false,
});

export const userUpdateRequest = (state = INITIAL_STATE, { user }) => ({
  ...state,
  user,
  isfetching: true,
});

export const userUpdateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  updateSuccess: true,
  isfetching: false,
});

export const userCreateRequest = (state = INITIAL_STATE) => ({
  ...state,
  isfetching: true,
});

export const userCreateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  createSuccess: true,
  isfetching: false,
});

export const userError = (state = INITIAL_STATE) => ({
  ...state,
  isfetching: false,
});

export const userReset = (state = INITIAL_STATE) => ({
  ...state,
  user: null,
});

export const userClearSuccess = (state = INITIAL_STATE) => ({
  ...state,
  createSuccess: false,
  updateSuccess: false,
  deletedSuccess: false,
  isfetching: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.USER_LIST_REQUEST]: userListRequest,
  [Types.USER_LIST_SUCCESS]: userListSuccess,
  [Types.USER_FIND_REQUEST]: userFindRequest,
  [Types.USER_FIND_SUCCESS]: userFindSuccess,
  [Types.USER_DELETE_REQUEST]: userDeleteRequest,
  [Types.USER_DELETE_SUCCESS]: userDeleteSuccess,
  [Types.USER_UPDATE_REQUEST]: userUpdateRequest,
  [Types.USER_UPDATE_SUCCESS]: userUpdateSuccess,
  [Types.USER_CREATE_REQUEST]: userCreateRequest,
  [Types.USER_CREATE_SUCCESS]: userCreateSuccess,
  [Types.USER_ERROR]: userError,
  [Types.USER_RESET]: userReset,
  [Types.USER_CLEAR_SUCCESS]: userClearSuccess,
});
