import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { Button, Table } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import PageTemplate from '../../../template/PageTemplate';
import { Constants } from '../../../utils';
import { findAllTrainingsAndCourses } from 'services/training';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: '100%'
  }
};

const PersonTraining = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const [{ data }, fetch] = useFetch(findAllTrainingsAndCourses, "", false, [], null);

  const columns = [
    {
      title: 'Treinamento',
      dataIndex: 'ds_titulo'
    },
    {
      title: 'Tópico',
      dataIndex: ['topico', 'ds_titulo']
    },
    {
      title: 'Curso',
      dataIndex: ['topico', 'curso', 'ds_titulo']
    },
    {
      title: 'Alunos',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 2 }}
            shape="circle"
            icon={<AiOutlineSearch size={18}/>}
            onClick={() => {
              history.push(`/backoffice/person-training/trainig/${record.id}`);
            }}
          />
        </span>
      )
    }
  ];

  const handleFindTranings = useCallback(
    page => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect( () => handleFindTranings(1), []);

  return (
    <PageTemplate title='Aulas Presenciais - Alunos' backButton={true}>
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
};

export default PersonTraining;
