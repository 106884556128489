import React from 'react';
import { Formik } from 'formik';
import { Form, Space, Tabs } from 'antd';
import { Col, FormButtons, FormInputDate, FormInputText, FormTextArea } from 'components';
import { CourseValidationSchema } from './ValidationSchema';
import { TabPaneStyled, TabsStyled } from './styles';
import useFetch from '../../../../hooks/useFetch';
import { Dropdown, FormDropdown } from '../../../../common';
import { findTopicsRequest } from '../../../../services/topics';
import { findTrainingsRequest } from '../../../../services/training';
import Input from '../../../../common/FormInputCustom';
import { estados, sexo } from '../../../../services/data';
import Filters from '../../../../utils/Filters';
import { findParishRequest } from '../../../../services/parish';
import { findCountiesRequest } from '../../../../services/county';

const {TabPane} = Tabs;

const ParishForm = ({ handleSubmitForm, initialValues, handleCancel }) => {
  // const [{ isFetching, data: trainings }] = useFetch(findTrainingsRequest, '', true, null);
  const [{ isFetching, data: counties }] = useFetch(findCountiesRequest, '', true, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CourseValidationSchema}
      onSubmit={handleSubmitForm}>
      {({ handleSubmit, values }) => (
        <Form>
          <TabsStyled defaultActiveKey="1" onChange={() => {}}>
            <TabPaneStyled tab="Dados gerais" key="1">
              <Col>
                  <FormInputText
                    placeholder="Nome"
                    name="nm_paroquia"
                    value={values.nm_paroquia}
                    required
                  />
                  <FormDropdown
                    placeholder="Comarca"
                    name="comarca_id"
                    propertyLabel='nm_comarca'
                    propertyValue='id'
                    value={values.comarca_id}
                    list={counties}
                    required
                  />
              </Col>
            </TabPaneStyled>
          </TabsStyled>
          <FormButtons
            bt2={{ label: 'Salvar', onClick: handleSubmit }}
            bt1={{ label: 'Cancelar', onClick: handleCancel }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ParishForm;
