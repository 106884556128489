import React, { useEffect } from 'react';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import CourseForm from '../Form';
import { createUserRequest } from '../../../../services/user';
import useFetch from '../../../../hooks/useFetch';
import { Metrics } from '../../../../configs';
import TopicForm from '../Form';
import { createTopicRequest } from '../../../../services/topics';

const BackofficeTopicCreate = () => {
  const history = useHistory();
  const { goBack } = history;
  const initialValues = {
    document: '',
    email: '',
    name: '',
    phone: '',
    password: '',
  };
  const [{ isFetching, data }, createTopic] = useFetch(
    createTopicRequest,
    '',
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Criação de Curso">
      <Spin spinning={isFetching}>
        <TopicForm
          initialValues={initialValues}
          handleSubmitForm={createTopic}
          handleCancel={goBack}
        />
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeTopicCreate;
