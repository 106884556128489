import React from "react";
import { Colors, Metrics } from "configs";
import CoursePlaceholder from "assets/images/courseBg.png";
import ProgressBar from "../PercentageBar";
import { Typography } from "antd";

const { Paragraph } = Typography;

const styles = {
  courseCard: {
    position: "relative",
    backgroundColor: "white",
    margin: "10px 20px 10px 0",
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    width: "300px",
    height: "160px",
    boxShadow: "1px 1px 6px #cacaca",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  resume: {},
  flexRow: {
    display: "flex",
  },
  price: {
    position: "absolute",
    right: 12,
    top: 15,
  },
  img: {
    maxWidth: "60px",
    Width: "60px",
    maxHeight: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "50%",
    marginRight: Metrics.spacingSMMD,
  },
  value: {
    color: Colors.greenCheck,
  },
};

export default ({ item, onClick }) => {
  return (
    <div style={styles.courseCard} onClick={onClick}>
      {/*<div style={styles.price}>
        <span style={styles.value}>
          {Filters.convertMoneyTextMask(item.vl_curso)}
        </span>
      </div>*/}
      <div style={styles.flexRow}>
        <div>
          <img src={CoursePlaceholder} style={styles.img} alt="" />
        </div>
        <div>
          <Paragraph
            ellipsis={{ rows: 2 }}
            style={{
              fontSize: Metrics.fontSize.xsm,
              color: Colors.secondary,
              fontWeight: "bold",
              margin: 0,
            }}
          >
            {item.ds_titulo}
          </Paragraph>
          <div style={styles.resume}>
            <Paragraph
              ellipsis={{ rows: 3 }}
              style={{
                fontSize: "15px",
                color: Colors.secondary,
                fontWeight: 300,
                margin: 0,
              }}
            >
              {item.ds_resumo}
            </Paragraph>
          </div>
        </div>
      </div>
      <ProgressBar pct={item.pr_concluido} width="100%" />
    </div>
  );
};
