import { Typography } from "antd";
import { Colors, Metrics } from "configs";
import { Container, TextContainer } from "./styles";
import { TitleContainer } from '../Courses/styles';
import React from 'react';
import useBreakpoint from 'use-breakpoint';
import { Constants } from '../../../../utils';

const { Text } = Typography;

const texts = [
  "Uma plataforma de cursos focados na Igreja Católica, para preparar você para o dia a dia pastoral.",
  "Cadastre-se para receber todas as novidades, lançamentos e informações do Centro de Formação da Diocese de Joinville",
];

const About = () => {
  const { breakpoint } = useBreakpoint(Constants.BREAKPOINTS, 'desktop');

  return (
    <Container>
      <div style={styles.aboutContainer}>
        { breakpoint !== 'desktop' &&
        <div style={{ marginTop: '120px', width: '1px' }} />
        }
        <Text
          style={{ color: Colors.primary, fontSize: Metrics.fontSize.lg }}
          strong
        >
          Sobre
        </Text>
        <span style={styles.marker} />
      </div>
      <TextContainer>
        {texts.map((t, i) => (
          <Text
            style={{ color: Colors.primary, fontSize: Metrics.fontSize.md }}
            strong
            key={i}
          >
            {t}
          </Text>
        ))}
      </TextContainer>
    </Container>
  );
};

const styles = {
  aboutContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  marker: {
    border: "2px solid #dca039",
    width: "70px",
    marginTop: "15px",
  },
};

export default About;
