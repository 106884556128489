import React, { useState } from "react";
import PageTemplate from "template/PageTemplate";
import ResultFilter from "../../../components/ResultFilter/index";
import { Formik } from "formik";
import { Form } from "formik-antd";
import styled from "styled-components";
import { Space, Table, Button } from "antd";
import { FormDropdown, FormButtons, Row } from "common";
import { getAllCourses, getAllCoursesReport } from "services/course";
import useFetch from "hooks/useFetch";
import { expandedRowRender } from "./NestedTable";
import { Colors } from "configs";
import { generateReportXLSX } from "./ReportGenerator";

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

const styles = {
  table: {
    width: "100%",
  },
};

export default function BackofficeCourseReport() {
  const [courseName, setCourseName] = useState("EMIDI");
  const [{ data: courses }, fetch] = useFetch(getAllCourses, null, true, []);
  const [{ data: coursesReport, isFetching }, fetchReport] = useFetch(
    getAllCoursesReport,
    null,
    true,
    [],
    null,
    ({ data }) => {
      if (data && data.length > 0 && data[0]) {
        setCourseName(data[0].ds_titulo);
      } else {
        setCourseName("");
      }
    }
  );

  function handleFilter(values) {
    fetchReport(values);
  }

  function exportReport() {
    generateReportXLSX(coursesReport);
  }

  function headerComponent() {
    if (!coursesReport || coursesReport.length === 0) {
      return null;
    }
    return (
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <span>Curso: {courseName}</span>
        <Button
          style={{
            backgroundColor: Colors.primary,
            borderColor: Colors.primary,
            color: Colors.white,
          }}
          onClick={exportReport}
        >
          Exportar Relatório
        </Button>
      </Row>
    );
  }

  const columns = [
    {
      title: "Nome",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Comarca",
      dataIndex: "nm_comarca",
      key: "nm_comarca",
    },
    {
      title: "Paróquia",
      dataIndex: "nm_paroquia",
      key: "nm_paroquia",
    },
    {
      title: "Cód. Promocional",
      dataIndex: "ds_codigo_promocional",
      key: "ds_codigo_promocional",
    },
    {
      title: "Progresso",
      dataIndex: "pr_concluido",
      key: "pr_concluido",
      render: (text, record) => <span>{record.pr_concluido} %</span>,
    },
  ];

  return (
    <PageTemplate title="Relatório de progresso do aluno" backButton={false}>
      {/* nome, email, cpf, codigo promocional, progresso */}
      {/* expandir e mostra os topicos */}
      {/* expandir e mostra os treinamentos concluidos */}
      <ResultFilter>
        <Formik
          initialValues={{ curso_id: 3 }}
          onSubmit={(values) => handleFilter(values)}
        >
          {({ handleSubmit, handleReset, values }) => (
            <Form>
              <CustomSpace direction="horizontal">
                <FormDropdown
                  placeholder="Curso"
                  name="curso_id"
                  propertyLabel="ds_titulo"
                  propertyValue="id"
                  value={values.curso_id}
                  list={courses}
                />
              </CustomSpace>
              <FormButtons
                bt2={{ label: "Buscar", onClick: handleSubmit }}
                bt1={{ label: "Limpar", onClick: handleReset }}
              />
            </Form>
          )}
        </Formik>
      </ResultFilter>
      <Table
        style={styles.table}
        columns={columns}
        dataSource={coursesReport}
        loading={isFetching}
        title={headerComponent}
        rowKey={(record) => record.uc_id}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) =>
            record.treinamentos && record.treinamentos.length > 0,
        }}
      />
    </PageTemplate>
  );
}
