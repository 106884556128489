import { Table as ATable } from "antd";
import FormInputDate from "common/FormInputDate";
import Flex from "common/Flex";
import FormInputText from "common/FormInputText";
import FormTree from "common/FormTree";
import FormInputPassword from "common/FormInputPassword";
import FormDropdown from "common/FormDropdown";
import Whitespace from "common/Whitespace";
import FormTextArea from "common/FormTextArea";
import FormSlider from "common/FormSlider";
import FormRadioV2 from "common/FormRadioV2";
import Table from "common/Table";
import FormButtons from "common/FormButtons";
import AnimatedButton from "common/AnimatedButton";
import Text from "common/Text";
import Button from "common/Button";
import Row from "common/Row";
import Col from "common/Col";
import Dropdown from "common/Dropdown";
import FormCheckbox from "common/FormCheckbox";
import Collapse from "common/Collapse";

const { Column } = ATable;

export {
  Dropdown,
  Row,
  Col,
  Flex,
  FormButtons,
  FormInputText,
  FormTree,
  FormCheckbox,
  FormInputPassword,
  Column,
  FormDropdown,
  Whitespace,
  FormTextArea,
  FormSlider,
  FormRadioV2,
  FormInputDate,
  Table,
  AnimatedButton,
  Text,
  Button,
  Collapse,
};
