import { createActions, createReducer } from "reduxsauce";
import { Storage } from "utils";

export const { Types, Creators } = createActions({
  registerRequest: ["credentials", "returnUrl"],
  updateCredentialsRequest: ["credentials", "courseId", "pendent", "returnTo"],
  updateCredentialsFinished: ["user"],
  loginRequest: ["credentials", "returnUrl"],
  loginSuccess: ["token", "account", "tenant"],
  loginFailed: ["error"],
  loginFacebookRequest: ["accessToken"],
  loginGoogleRequest: ["accessToken"],
  forgotPasswordRequest: ["email"],
  forgotPasswordFinished: null,
  forgotPasswordFinishRequest: ["password", "key"],
  forgotPasswordFinishFinished: null,
  changePasswordRequest: ["data"],
  changePasswordEmailCpfRequest: ["data"],
  changePasswordFinished: null,
  changePasswordEmailCpfFinished: null,
  refreshToken: ["token"],
  defaultFinished: null,
  logout: null,
});

const INITIAL_STATE = {
  user: null,
  token: null,
  authenticated: false,
  error: null,
  isFetching: false,
};

const defaultRequest = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

const defaultFinished = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const updateCredentialsFinished = (state = INITIAL_STATE, { user }) => ({
  ...state,
  isFetching: false,
  user,
});

const loginRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: true,
  };
};

const registerRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFetching: true,
  };
};

const loginSuccess = (state = INITIAL_STATE, { token, account, tenant }) => ({
  ...state,
  token,
  user: account,
  authenticated: true,
  isFetching: false,
});

const loginFailed = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error,
  isFetching: false,
});

const logout = (state = INITIAL_STATE) => {
  Storage.clearToken();
  Storage.clear("studium#account");
  return {
    ...state,
    ...INITIAL_STATE,
  };
};

const forgotPasswordRequest = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

const forgotPasswordFinished = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const forgotPasswordFinishRequest = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

const forgotPasswordFinishFinished = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const changePasswordRequest = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const changePasswordEmailCpfRequest = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const changePasswordFinished = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const changePasswordEmailCpfFinished = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

const refreshToken = (state = INITIAL_STATE, { token }) => {
  Storage.setToken(token);
  return {
    ...state,
    token,
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILED]: loginFailed,
  [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.FORGOT_PASSWORD_FINISHED]: forgotPasswordFinished,
  [Types.FORGOT_PASSWORD_FINISH_REQUEST]: forgotPasswordFinishRequest,
  [Types.FORGOT_PASSWORD_FINISH_FINISHED]: forgotPasswordFinishFinished,
  [Types.CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [Types.CHANGE_PASSWORD_EMAIL_CPF_REQUEST]: changePasswordEmailCpfRequest,
  [Types.CHANGE_PASSWORD_FINISHED]: changePasswordFinished,
  [Types.CHANGE_PASSWORD_EMAIL_CPF_FINISHED]: changePasswordEmailCpfFinished,
  [Types.REFRESH_TOKEN]: refreshToken,
  [Types.LOGIN_FACEBOOK_REQUEST]: defaultRequest,
  [Types.LOGIN_GOOGLE_REQUEST]: defaultRequest,
  [Types.DEFAULT_FINISHED]: defaultFinished,
  [Types.UPDATE_CREDENTIALS_REQUEST]: defaultRequest,
  [Types.UPDATE_CREDENTIALS_FINISHED]: updateCredentialsFinished,
  [Types.LOGOUT]: logout,
});
