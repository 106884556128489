import React, { useCallback, useEffect, useState } from "react";
import { Form, Space } from "antd";
import {
  FormButtons,
  FormInputText,
  FormInputPassword,
  FormInputDate,
} from "components";
import { Formik, useFormik } from "formik";
import { findCountiesRequest } from "services/county";
import { findParishesRequest } from "services/parish";
import { findMaritalStatusRequest } from "services/user";
import styled from "styled-components";
import { FormDropdown } from "common";
import useFetch from "hooks/useFetch";
import { estados, sexo } from "services/data";
import Filters from "utils/Filters";
import { UserCredentialsValidationSchema } from "./ValidationSchema";
import { getDiocesesByUf } from "services/diocese";
import { Dates } from "utils";
import { CreateChargeUser } from "../Edit/components/CreateCharge";

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

const UserForm = ({ handleSubmitForm, initialValues, handleCancel }) => {
  const [{ data: maritalStatus }, fetch] = useFetch(
    findMaritalStatusRequest,
    "",
    true,
    []
  );
  const [{ data: counties }] = useFetch(findCountiesRequest, "", true, []);
  const [{ data: parishes }, fetchParishes] = useFetch(
    findParishesRequest,
    "",
    false,
    [],
    null,
    (res) => {
      res.data.length && verifyIsAnotherParish(res.data)
    }
  );

  const [dioceses, setDioceses] = useState([]);
  const [isAnotherParish, setIsAnotherParish] = useState(false);

  const getDioceses = useCallback(async (uf) => {
    try {
      const res = await getDiocesesByUf(uf);
      setDioceses(res.data);
    } catch (error) {
      console.log(81, error);
    }
  }, [initialValues])

  const verifyIsAnotherParish = useCallback((data) => {
    const comarca_id = data[0].comarca_id;
    const findCounties = counties.find(countie => countie.id === comarca_id);

    if (findCounties?.nm_comarca === 'Outra') {
      const findparishes = data?.find(parishe => parishe?.nm_paroquia === 'Outra')
      findparishes?.id && formik.setFieldValue('paroquia_id', findparishes.id)
    }

    setIsAnotherParish(findCounties?.nm_comarca === 'Outra')
  }, [counties, parishes]);

  const handleChange = (name, value) => {
    formik.setFieldValue(name, value);
  }

  useEffect(() => {
    if (initialValues && initialValues.comarca_id) {
      fetchParishes(initialValues.comarca_id);
    }

    if (initialValues && initialValues.sg_uf){
      getDioceses(initialValues.sg_uf)
    }
  }, [initialValues, counties]);

  const formik = useFormik({
    onSubmit: handleSubmitForm,
    initialValues: initialValues,
    validationSchema: UserCredentialsValidationSchema,
  })

  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={formik.handleSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={formik.handleSubmit}>
          <CustomSpace direction="horizontal">
            <FormInputText
              placeholder="Nome"
              name="username"
              value={formik.values.username}
              required
              onChange={e => handleChange('username', e)}
            />
            <FormInputText
              placeholder="E-mail"
              name="email"
              value={formik.values.email}
              disabled={formik.values.id}
              disabledNoColor
              onChange={e => handleChange('email', e)}
            />
            <FormDropdown
              placeholder="Sexo"
              name="ds_sexo"
              value={formik.values.ds_sexo}
              list={sexo}
              onChange={e => handleChange('ds_sexo', e)}
            />
          </CustomSpace>
          <CustomSpace direction="horizontal">
            <FormInputText
              placeholder="CPF"
              name="nu_cpf"
              value={Filters.inputMaskCPF(formik.values.nu_cpf)}
              onChange={e => handleChange('nu_cpf', e)}
              required
            />
            <FormInputText
              placeholder="Número celular"
              name="nu_celular"
              value={Filters.inputMaskCellphone(formik.values.nu_celular)}
              onChange={e => handleChange('nu_celular', e)}
            />
            <FormInputDate
              placeholder="Data de Nascimento"
              name="dt_nascimento"
              value={formik.values.dt_nascimento}
              noMargin
              onChangeParam={e => handleChange('dt_nascimento', e)}
            />
          </CustomSpace>
          <CustomSpace direction="horizontal">
            <FormDropdown
              placeholder="Estado Civil"
              name="estado_civil_id"
              value={formik.values.estado_civil_id}
              list={maritalStatus || []}
              propertyValue="id"
              propertyLabel="ds_estado_civil"
              onChange={e => handleChange('ds_estado_civil', e)}
            />
            <FormDropdown
              placeholder="Comarca"
              name="comarca_id"
              propertyValue="id"
              value={formik.values.comarca_id}
              list={counties || []}
              propertyLabel="nm_comarca"
              onChange={(e) => {
                handleChange('comarca_id', e)
                formik.setFieldValue("paroquia_id", null);
                fetchParishes(e);
              }}
            />

            {
              isAnotherParish ? (
                <FormInputText
                  placeholder="Paróquia"
                  name="another_parish"
                  value={formik.values.another_parish}
                  onChange={e => handleChange('another_parish', e)}
                  required
                />
              ) : (
                <FormDropdown
                  placeholder="Paróquia"
                  name="paroquia_id"
                  value={formik.values.paroquia_id}
                  list={parishes || []}
                  disabled={!formik.values.comarca_id}
                  propertyValue="id"
                  propertyLabel="nm_paroquia"
                  onChange={e => handleChange('paroquia_id', e)}
                />
              )
            }
          </CustomSpace>

          <CustomSpace direction="horizontal">
            <FormInputText
              placeholder="CEP"
              name="nu_cep"
              value={Filters.inputMaskCEP(formik.values.nu_cep)}
              onChange={e => handleChange('nu_cep', e)}
            />
            <FormDropdown
              name="sg_uf"
              list={estados}
              placeholder="UF"
              value={formik.values.sg_uf}
              onChange={e => {
                getDioceses(e)
                handleChange('sg_uf', e)
                formik.setFieldValue('id_diocese', undefined)
              }}
            />
            <FormInputText
              placeholder="Cidade"
              name="nm_cidade"
              value={formik.values.nm_cidade}
              onChange={e => handleChange('nm_cidade', e)}
            />
            <FormInputText
              placeholder="Endereço completo ( com número )"
              name="ds_endereco"
              value={formik.values.ds_endereco}
              onChange={e => handleChange('ds_endereco', e)}
            />
          </CustomSpace>

          <CustomSpace direction="horizontal">
            <FormDropdown
              name="id_diocese"
              list={dioceses}
              propertyValue='id'
              placeholder="Diocese"
              propertyLabel='diocese'
              value={formik.values.id_diocese}
              onChange={e => handleChange('id_diocese', e)}
            />

            <FormInputPassword
              placeholder={
                formik.values.id
                  ? "Senha ( só altere este campo caso tenha necessidade )"
                  : "Senha"
              }
              name="password"
              value={formik.values.password}
              required={!formik.values.id}
              onChange={e => handleChange('password', e)}
            />
          </CustomSpace>

          <CreateChargeUser idUser={formik.values.id} />

          <FormButtons
            bt2={{ label: "Salvar", onClick: handleSubmit }}
            bt1={{ label: "Cancelar", onClick: handleCancel }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
