import React from 'react';
import { Menu } from 'antd';
import { Modal } from '../../../../../utils';

const TableMenu = ({record, handleRemove, handleEdit, deleteProp, descProp, desc}) => {
  return (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          handleEdit(record);
        }}>
        Editar
      </Menu.Item>
      <Menu.Item
        key="1"
        danger
        onClick={() =>
          Modal.openDeleteModal({
            description: (
              <span>
                {`Deseja realmente deletar/inativar o ${desc}`}
                    <strong> {record[descProp]}</strong>?
                  </span>
            ),
            onOk: () => handleRemove(record[deleteProp]),
            okText: 'Sim',
            cancelText: 'Não',
          })
        }>
        Excluir
      </Menu.Item>
    </Menu>
  );
};

export default TableMenu;
