import styled from 'styled-components';
import { Input as InputAntd } from 'antd';

export const Input = styled(InputAntd)`
  border-radius: 15px;
  box-shadow: none !important;
  color: #5c6a79;
  border: none !important;
  background-color: #f6f6f6;
  max-width: 260px;

  .ant-input {
    background-color: #f6f6f6;
    text-align: right !important;
    padding-right: 10px !important;
    color: #5c6a79;
  }

  .ant-input-suffix {
    font-size: 20px;
  }

  .ant-input:placeholder-shown {
    text-align: right;
    padding-right: 10px !important;
    color: #5c6a79;
  }
`;
