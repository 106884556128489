import * as Yup from 'yup';

export const CourseValidationSchema = Yup.object().shape({
  ds_titulo: Yup.string().trim().required('O campo E-mail é obrigatório.'),
  ds_curso: Yup.string().trim().required('O campo Nome Completo é obrigatório.'),
  ds_resumo: Yup.string().trim().required('O campo Cpf é obrigatório.'),
  ds_imagem: Yup.string().trim().required('O campo Cpf é obrigatório.'),
  vl_curso: Yup.number().required('O campo Cpf é obrigatório.'),
  dt_inicio: Yup.date().required('O campo Telefone é obrigatório.'),
  dt_fim: Yup.date().required('O campo Telefone é obrigatório.'),
});
