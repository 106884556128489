import * as Yup from "yup";

export const RegisterValidationSchema = Yup.object().shape({
  username: Yup.string().trim().required("O campo Seu nome é obrigatório."),
  email: Yup.string()
    .trim()
    .email("O E-mail informado não é valido.")
    .required("O campo E-mail é obrigatório."),
  password: Yup.string().trim().required("O campo Senha é obrigatório."),
  confirmPassword: Yup.string()
    .trim()
    .required("O campo Confirme sua senha é obrigatório.")
    .oneOf([Yup.ref("password"), null], "As senhas precisam ser iguais."),
});
