import { Dropdown, Menu, Space, Table } from "antd";
import { FormInputText, FormButtons } from "common";
import { Formik } from "formik";
import { Form } from "formik-antd";
import useFetch from "hooks/useFetch";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineMore } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { deleteUserRequest, findUsersRequest } from "services/user";
import styled from "styled-components";
import PageTemplate from "template/PageTemplate";
import { Constants, Dates, Filters, Modal } from "utils";
import ResultFilter from "../../../components/ResultFilter/index";
import { FilterFunction } from "../../../components/ResultFilter/util";
import moment from "moment";

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: "100%",
  },
};

const BackofficeUser = () => {
  const [tableDataBackup, setTableDataBackup] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const [, fetch] = useFetch(
    findUsersRequest,
    "",
    false,
    [],
    null,
    ({ data }) => {
      setTableDataBackup(data);
      setTableData(data);
    }
  );
  const [{ isFetching, data: deleteData }, deleteUser] = useFetch(
    deleteUserRequest,
    "",
    false,
    null,
    null,
    () => fetch()
  );

  const handleFilter = useCallback(
    (values) => {
      const filteredData = FilterFunction(tableDataBackup, {
        ...values,
        nu_cpf: Filters.removeMaskCPF(values.nu_cpf),
      });
      setTableData(filteredData);
    },
    [tableDataBackup]
  );

  const menu = useCallback(
    (record) => {
      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              history.push(`/backoffice/user/edit/${record.id}`);
            }}
          >
            Editar
          </Menu.Item>
          <Menu.Item
            key="1"
            danger
            onClick={() =>
              Modal.openDeleteModal({
                description: (
                  <span>
                    Deseja realmente deletar o usuário
                    <strong> {record.ds_titulo}</strong>?
                  </span>
                ),
                onOk: () => deleteUser(record.id),
                okText: "Sim",
                cancelText: "Não",
              })
            }
          >
            Excluir
          </Menu.Item>
        </Menu>
      );
    },
    [history]
  );

  const columns = [
    {
      title: "Nome",
      dataIndex: "username",
      key: "username",
      sorter: {
        compare: (a, b) => a.username.localeCompare(b.username),
        multiple: 1,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
    },
    {
      title: "CPF",
      dataIndex: "nu_cpf",
      key: "nu_cpf",
      sorter: {
        compare: (a, b) => a.nu_cpf - b.nu_cpf,
        multiple: 3,
      },
      render: (text, record) => (
        <span>
          {record.nu_cpf
            ? Filters.inputMaskCPF(record.nu_cpf)
            : "Não informado"}
        </span>
      ),
    },
    {
      title: "Celular",
      dataIndex: "nu_celular",
      key: "nu_celular",
      sorter: {
        compare: (a, b) => a.nu_celular - b.nu_celular,
        multiple: 4,
      },
      render: (text, record) => (
        <span>
          {record.nu_celular
            ? record.nu_celular.length >= 12
              ? Filters.inputMaskCellphone(record.nu_celular)
              : record.nu_celular
            : "Não informado"}
        </span>
      ),
    },
    {
      title: "Data de Nascimento",
      dataIndex: "dt_nascimento",
      key: "dt_nascimento",
      sorter: {
        compare: (a, b) => {
          if (!a.dt_nascimento) return 1;
          if (!b.dt_nascimento) return -1;
          return (
            moment(a.dt_nascimento).unix() - moment(b.dt_nascimento).unix()
          );
        },
        multiple: 5,
      },
      render: (text, record) => (
        <span>
          {record.dt_nascimento
            ? Dates.transformDateToDayMonthYear(record.dt_nascimento)
            : "Não informado"}
        </span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  const handleFindUsers = useCallback(
    (page) => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect(() => handleFindUsers(1), []);

  return (
    <PageTemplate
      title="Usuários"
      backButton={false}
      newButton={{
        label: "Novo Usuário",
        onClick: () => history.push("/backoffice/user/create"),
      }}
    >
      <ResultFilter>
        <Formik initialValues={{}} onSubmit={(values) => handleFilter(values)}>
          {({ handleSubmit, handleReset, values }) => (
            <Form>
              <CustomSpace direction="horizontal">
                <FormInputText
                  placeholder="Nome"
                  name="username"
                  value={values.username}
                />
                <FormInputText
                  placeholder="E-mail"
                  name="email"
                  value={values.email}
                  disabled={values.id}
                />
                <FormInputText
                  placeholder="CPF"
                  name="nu_cpf"
                  value={values.nu_cpf}
                />
              </CustomSpace>
              <FormButtons
                bt2={{ label: "Buscar", onClick: handleSubmit }}
                bt1={{ label: "Limpar", onClick: handleReset }}
              />
            </Form>
          )}
        </Formik>
      </ResultFilter>
      <Table style={styles.table} columns={columns} dataSource={tableData} />
    </PageTemplate>
  );
};

export default BackofficeUser;
