import * as Yup from "yup";
import Validations from "utils/Validations";

export const UserCredentialsValidationSchema = Yup.object().shape({
  nu_cpf: Yup.string()
    .trim()
    .nullable()
    .required("O campo CPF é obrigatório.")
    .test("nu_cpf", "O CPF informado não é válido", (val) => {
      if (val && val !== "") {
        const { isValid } = Validations.isCPF(val);
        return isValid;
      }
      return true;
    }),
  email: Yup.string()
    .trim()
    .nullable()
    .required("O campo e-mail é obrigatório.")
    .email("O e-mail informado não é válido."),
  username: Yup.string()
    .trim()
    .nullable()
    .required("O campo nome é obrigatório."),
  password: Yup.string().when("id", {
    is: (value) => !value,
    then: Yup.string().required("O campo senha é obrigatório."),
    otherwise: Yup.string(),
  }),
});
