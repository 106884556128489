import ContactFooter from "components/ContactFooter";
import OnlyImportant from "features/Landing/components/OnlyImportant";
import ImageWithTexts from "features/Landing/components/ImageWithTexts";
import appExample from "assets/images/app-atualizado.png";
import { Container, ContentContainer } from "./styles";
import Intro from "features/Landing/components/Intro";
import CoursesComponent from "features/Landing/components/Courses";
import MobileStore from "assets/images/mobileStore.png";
import AboutSection from "features/Landing/components/About";
import { Row } from '../../common';

export let Courses;
export let App;
export let About;
export let Users;
export let Contact;

const Landing = () => {
  return (
    <Container>
      <ContentContainer>
        <section>
          <Intro />
        </section>
        <section
          ref={(section) => {
            Courses = section;
          }}
        >
          <CoursesComponent />
        </section>
        <section
          ref={(section) => {
            About = section;
          }}
        >
          <AboutSection />
        </section>
        <section
          ref={(section) => {
            App = section;
          }}
        >
          <ImageWithTexts
            reverse
            maxWidth
            image={appExample}
            bottomTextImg={MobileStore}
            titles={[
              "A Studium possibilita acesso na palma da sua mão.",
              "Com aplicativo e site, você aprende quando e onde quiser!",
            ]}
            maxWidthValue="100%"
          />
        </section>
        <section>
          <OnlyImportant />
        </section>
        {/*<section
          ref={(section) => {
            Users = section;
          }}
        >
          <UsersComponent />
        </section>*/}
      </ContentContainer>

      <section
        ref={(section) => {
          Contact = section;
        }}
        id="contact"
      >
        <ContactFooter />
      </section>
    </Container>
  );
};

export default Landing;
