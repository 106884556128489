import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, message, Spin } from 'antd';
import ReactToPrint from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import { useParams, useHistory } from "react-router-dom";

import { Colors, Metrics } from "configs";

import Logo from "assets/images/logo-pdf.png";
import LogoDiocese from "assets/images/logo-diocese-joinville.png";
import CertificateImage from "assets/images/certificado-oficial.jpeg";
import signatureGelioImage from "assets/images/assinatura-padre-gelio.png";
import signatureAirtonImage from "assets/images/assinatura2.png";

import {
  Content,
  Container,
  HeaderContent,
  ButtonContainer,
  SignatureContainer,
  CertificateContainer,
} from './styles';
import { getCoursePdfRequest } from 'services/course';

export const Certificate = () => {
  const { id } = useParams();
  const history = useHistory();

  const componentPdfRef = useRef();

  const [messageApi, contextHolder] = message.useMessage();

  const [dataCourse, setDataCourse] = useState();
  const [loading, setLoading] = useState(false);

  const getCourse = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await getCoursePdfRequest(id)

      if(!data?.curso?.has_certificate){
        throw 'Não permitido'
      }

      setDataCourse(data);
    } catch (error) {
        history.push(`/my-courses`)
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCourse();
  }, [getCourse])

  if(loading) {
    return (
      <Container
        style={{
          display: 'flex'
        }}
      >
        <Spin size="large"/>
      </Container>
    )
  }

  return (
    <Container>
      {contextHolder}
      <strong>Certificado</strong>

      <ReactToPrint
        trigger={() => (
          <ButtonContainer>
            <Button
              type="primary"
              style={{ backgroundColor: Colors.primary, borderColor: Colors.primary, borderRadius: Metrics.defaultRadius }}
              icon={<PrinterOutlined style={{ fontSize: 15 }} />}
              size="medium"
            >
              IMPRIMIR
            </Button>
          </ButtonContainer>
        )}
        content={() => componentPdfRef?.current}
      />

      <CertificateContainer ref={componentPdfRef}>
        <Content style={{
          backgroundImage: `url(${CertificateImage})`
        }}>
          <strong className='title'>Studium - centro de formação</strong>
          <span className='subTitle'>Diocese de joinville</span>

          <HeaderContent>
            <img className='logo' src={Logo} />
            <span>certificado</span>
            <img className='logo' src={LogoDiocese} />
          </HeaderContent>

          <span
            className='subTitle'
            style={{
              marginTop: -15,
              marginBottom: 30
            }}
          >
            Diocese de joinville
          </span>

          <span className='textInfo'>Certificamos que</span>

          <span className='textValue'>{dataCourse?.username}</span>

          <span
            className='textInfo'
            style={{
              marginTop: 40,
              textAlign: 'left'
            }}
          >
            Concluiu com êxito as <span>{dataCourse?.curso?.ds_hrs_video}</span> do curso <span>{dataCourse?.curso?.ds_titulo}</span> <br />
            no ano corrente. Demonstrou um notável comprometimento e dedicação,
            destacando-se pelo empenho exemplar em todas as atividades propostas.
          </span>

          <SignatureContainer>
            <div className='signature'>
              <img src={signatureGelioImage} />
              <span className='signatureName'>Padre Gélio Silva do Nascimento</span>
              <span
                className='textInfo'
                style={{
                  width: 'max-content'
                }}
              >
                Coordenador Diocesano de Pastoral
              </span>
            </div>

            <div className='signature'>
              <img src={signatureAirtonImage} />
              <span className='signatureName'>Airton Bonet</span>
              <span
                className='textInfo'
                style={{
                  width: 'max-content'
                }}
              >
                Coordenador do Centro de Formação
              </span>
            </div>

          </SignatureContainer>

        </Content>

        <Content style={{
          backgroundImage: `url(${CertificateImage})`
        }}>
          <strong className='title'>Studium - centro de formação</strong>
          <span className='subTitle'>Diocese de joinville</span>

          <HeaderContent style={{ marginBottom: 20 }}>
            <span style={{ fontSize: 24 }}>Temas estudados</span>
          </HeaderContent>

          {
            dataCourse?.topico?.map(topico => (
             <span
              key={topico.id}
              className='topic'
              style={{

              }}
             >
              {topico.ds_titulo}
             </span>

            ))
          }

        </Content>
      </CertificateContainer>

      <ReactToPrint
        trigger={() => (
          <ButtonContainer>
            <Button
              type="primary"
              style={{ backgroundColor: Colors.primary, borderColor: Colors.primary, borderRadius: Metrics.defaultRadius }}
              icon={<PrinterOutlined style={{ fontSize: 15 }} />}
              size="medium"
            >
              IMPRIMIR
            </Button>
          </ButtonContainer>
        )}
        content={() => componentPdfRef?.current}
      />

    </Container>
  );
};
