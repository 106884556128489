import introPeoples from "assets/images/intro-peoples.png";
import ImageWithTexts from "features/Landing/components/ImageWithTexts";
import { AnimatedButton, Whitespace } from 'common';
import { useHistory } from "react-router-dom";
import { Metrics } from '../../../../configs';

const Intro = () => {
  const { push } = useHistory();
  const handleRedirect = (routeName) => {
    push(routeName);
  };

  return (
    <div
      style={{
        width: "100%",
        minHeight: "calc(80vh - 100px)",
      }}
    >
      <ImageWithTexts
        maxWidth
        maxWidthValue="80%"
        titleStrong={false}
        titleStyle={{
          fontSize: "2rem",
          fontWeight: 700,
          fontFamily: "Montserrat",
        }}
        textStyle={{
          fontWeight: 500,
          fontFamily: "Montserrat",
        }}
        mark={false}
        titles={[
          "Seja bem-vindo! Apresentamos a Studium, a ferramenta de formação online da Diocese de Joinville.",
        ]}
        video={
          "https://studiumarq.s3.sa-east-1.amazonaws.com/33.261c41ajt-Apresenta%C3%A7%C3%A3o+Dom+Francisco+%28baixa%29.mp4"
        }
        imgStyle={{ maxWidth: "53vw", marginRight: "-8vw" }}
      >
        <AnimatedButton onClick={() => handleRedirect("register")}>
          Cadastrar
        </AnimatedButton>
        <Whitespace height={Metrics.spacingLG} />
      </ImageWithTexts>
    </div>
  );
};

export default Intro;
