import React from "react";
import { Collapse } from "common";

const { Panel } = Collapse;

export default function ResultFilter({ title, children, opened }) {
  return (
    <Collapse defaultActiveKey={opened ? ["1"] : []}>
      <Panel header={title || "Filtros"} key="1">
        {children}
      </Panel>
    </Collapse>
  );
}
