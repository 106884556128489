import {
  Container,
  TitleContainer,
  TitleText,
  TitleMark,
  ImgContainer,
  TextContainer,
} from "./styles";
import ReactPlayer from "react-player";
import React from "react";
import { Row, Whitespace } from '../../../../common';
import { Colors, Metrics } from '../../../../configs';

const ImageWithTexts = ({
  titles,
  text,
  reverse = false,
  image,
  bottomTextImg,
  video,
  textStyle,
  imgStyle,
  maxWidth = false,
  maxWidthValue = "50%",
  titleStyle,
  titleStrong = true,
  mark = true,
  children,
}) => {
  return (
    <Row responsive reverse={reverse} style={{  width: '100%',
      minHeight: '500px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden'}}>
      <div style={{flex: 1}}>
        <TitleContainer maxWidth={maxWidth} maxWidthValue={maxWidthValue}>
          {titles.map((title, key) => (
            <TitleText strong={titleStrong} style={{ ...titleStyle }} key={key}>
              {title}
            </TitleText>
          ))}
          {mark && <TitleMark />}
          <Whitespace height={Metrics.spacingLG} />
          {bottomTextImg && (
            <div style={{ display: "flex", position: 'relative' }}>
              <div style={{position: 'relative'}}>
                <img src={bottomTextImg} style={{ width: "200px", opacity: 0.3 }} />
                <span style={{ fontSize: Metrics.fontSize.xsm, position: 'absolute', background: 'white', color: Colors.primary, fontWeight: 'bolder',
                  boxShadow: '0px 0px 10px #aaa',
                  borderRadius: '8px',
                  padding: '5px',
                  transform: 'rotate(24deg)',
                  right: '-15%',
                top: '-15%'}}>Em breve!</span>
              </div>
            </div>
          )}
        </TitleContainer>
        <TextContainer
          style={{
            ...textStyle,
          }}
        >
          {text}
        </TextContainer>
        {children && <div style={{ marginTop: "50px" }}>{children}</div>}
      </div>
      <ImgContainer reverse={reverse}>
        {video ? (
          <ReactPlayer
            // playing
            //loop
            url={video}
            controls
            width="100%"
            height="auto"
            onContextMenu={(e) => e.preventDefault()}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  disablePictureInPicture: true,
                },
              },
            }}
          />
        ) : image ? (
          <img
            src={image}
            alt="alt"
            style={{ maxWidth: "450px", minWidth: '250px', ...imgStyle }}
          />
        ) : (
          <></>
        )}
      </ImgContainer>
    </Row>
  );
};

export default ImageWithTexts;
