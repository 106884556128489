import React from "react";
import { Colors, Metrics } from "configs";
import { useHistory } from "react-router-dom";
import {
  getAllConcludedCoursesUserRequest,
  getAllRunningCoursesUserRequest,
} from "services/course";
import { Text } from "common";
import { CourseCardSm } from "components";
import useFetch from "../../hooks/useFetch";
import { Spin } from "antd";
import PageTemplate from "template/PageTemplate";

const styles = {
  outterContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: Metrics.spacing.md,
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
    color: Colors.secondary,
  },
  headerNavigation: {
    position: "absolute",
    width: "100%",
    top: 0,
    display: "flex",
    padding: Metrics.spacing.md,
    justifyContent: "space-between",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: Metrics.spacing.lg,
  },
  headerBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  seeAll: {
    boxShadow: "1px 1px 6px #cacaca",
    width: "260px",
    height: "118px",
    backgroundColor: Colors.white,
    margin: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const CoursesCompletedAndProgress = ({ completed = true }) => {
  const history = useHistory();
  const [{ isFetching, data }] = useFetch(
    completed
      ? getAllConcludedCoursesUserRequest
      : getAllRunningCoursesUserRequest,
    "",
    true,
    [],
    null
  );

  return (
    <PageTemplate>
      {isFetching && <Spin />}
      <div style={styles.outterContainer}>
        <Text bold size={Metrics.fontSize.md}>
          {completed ? "Meus cursos concluídos" : "Meus cursos em andamento"}
        </Text>
        <Text
          size={Metrics.fontSize.xsm}
          color={Colors.secondary}
        >{`${data.length} cursos`}</Text>
        <div style={styles.container}>
          {data.map((item, i) => (
            <CourseCardSm
              item={item}
              key={i}
              onClick={() => history.push(`/course-progress/${item.curso_id}`)}
            />
          ))}
        </div>
      </div>
    </PageTemplate>
  );
};

export default CoursesCompletedAndProgress;
