import styled from "styled-components";
import { Colors } from "configs";

export const AddCard = styled.div`
  min-width: 220px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-right: 20px;

  > div {
    background-color: ${Colors.white};
    box-shadow: 1px 2px 3px #00000029;
    min-width: 220px;
    min-height: 200px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.4s;

    > span {
      color: ${Colors.primary};
      font-size: 15px;
      font-weight: bold;
      transition: 0.4s;
    }

    :hover {
      min-width: 210px;
      min-height: 170px;
      background-color: hsl(175deg 95% 70% / 10%);

      > span {
        font-size: 13px;
      }
    }
  }
`;
