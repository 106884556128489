import styled from "styled-components";
import { Colors } from "configs";

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin-bottom: 15px;
    margin-right: 30px;
    width: 180px;
    height: 180px;
    background-color: ${Colors.white};
    border-radius: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 2px 3px #00000029;
    transition: 0.6s;
    padding: 35px 0;
    cursor: pointer;

    .icon {
      margin-bottom: 20px;
      color: ${Colors.primary};
      font-size: 50px;
    }

    .text {
      font-size: 14px;
      color: ${Colors.secondary};
      font-weight: 600;
    }

    :hover {
      background-color: hsl(175deg 95% 70% / 10%);
    }
  }

  @media (max-width: 1525px) {
    > div {
      width: 150px;
      height: 150px;
      padding: 10px 0;

      .icons {
        font-size: 35px;
      }

      .text {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;

    > div {
      margin-top: 20px;

      .icons {
        font-size: 35px;
      }

      .text {
        font-size: 12px;
      }
    }
  }
`;
