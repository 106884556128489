import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { Button, Table } from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import PageTemplate from '../../../template/PageTemplate';
import { Constants } from '../../../utils';
import { findAllTrainingsAndCourses } from 'services/training';
import { getAllCourses } from 'services/course';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: '100%'
  }
};

export const CourseModules = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);

  const history = useHistory();

  const getAllCourse = async () => {
    try {
      const res = await getAllCourses();
      const _res = res.data.filter(course => course.has_module);

      setData(_res);
    } catch (error) {

    }
  }

  const columns = [
    {
      title: 'Curso',
      dataIndex: 'ds_titulo'
    },
    {
      title: 'Tópicos',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Button
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 2 }}
            shape="circle"
            icon={<AiOutlineSearch size={18}/>}
            onClick={() => {
              history.push(`/backoffice/course-modules/topics/${record.id}`);
            }}
          />
        </span>
      )
    }
  ];

  const handleFindTranings = useCallback(
    page => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect(() => {
    getAllCourse();
    // handleFindTranings(1);
  }, []);

  return (
    <PageTemplate title='Curso por Módulos - Alunos' backButton={true}>
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
};
