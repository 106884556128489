import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  updateSearch: ["newValue"],
});

const INITIAL_STATE = {
  search: null,
};

const updateSearch = (state = INITIAL_STATE, { newValue }) => ({
  ...state,
  search: newValue,
});

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_SEARCH]: updateSearch,
});
