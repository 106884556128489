import React, { useState } from "react";
import { Formik } from "formik";
import { Form } from "antd";
import { Col, FormButtons, FormInputText } from "components";
import { CourseValidationSchema } from "./ValidationSchema";
import { TabPaneStyled, TabsStyled } from "./styles";
import ParishesForm from "features/Backoffice/Counties/Form/ParishesForm";

const CountyForm = ({
  handleSubmitForm,
  initialValues,
  handleCancel,
  activeTab,
  setActiveTab,
  enabledTab = true,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CourseValidationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ handleSubmit, values }) => (
        <Form>
          <TabsStyled
            defaultActiveKey={activeTab}
            onChange={setActiveTab}
            activeKey={activeTab}
          >
            <TabPaneStyled tab="Dados gerais" key="1">
              <Col>
                <FormInputText
                  placeholder="Nome"
                  name="nm_comarca"
                  value={values.nm_comarca}
                  required
                />
              </Col>
            </TabPaneStyled>
            <TabPaneStyled tab="Paróquias" key="2" disabled={!enabledTab}>
              <ParishesForm initialValues={values.paroquias || []} />
            </TabPaneStyled>
          </TabsStyled>
          <FormButtons
            bt2={{ label: "Salvar", onClick: handleSubmit }}
            bt1={{ label: "Cancelar", onClick: handleCancel }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CountyForm;
