import React from "react";
import Form from "../Form";
import useFetch from "hooks/useFetch";
import { createCardRequest } from "services/card";
import { useHistory } from "react-router-dom";

export default function PaymentMethodCreate() {
  const history = useHistory();
  const [_, createCard] = useFetch(
    createCardRequest,
    null,
    null,
    null,
    null,
    () => history.push("/payment-method")
  );

  return <Form handleSubmit={createCard} />;
}
