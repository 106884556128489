import styled from 'styled-components';
import { Form } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  min-height: 100%;
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Container = styled(Form)`
  padding: 50px 60px;
  background: #fff;
  width: 40% !important;
  border-radius: 6px;
  box-shadow: 0 1px 6px #0000001a;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1130px) {
    width: 80% !important;
  }
`;
