import React from "react";
import Form from "../Form";
import useFetch from "hooks/useFetch";
import { updateCardRequest, findCardRequest } from "services/card";
import { useParams, useHistory } from "react-router-dom";

export default function PaymentMethodEdit() {
  const history = useHistory();
  const { id } = useParams();
  const [{ isFetching, data: card }] = useFetch(findCardRequest, id, true);
  const [_, updateCard] = useFetch(
    updateCardRequest,
    null,
    null,
    null,
    null,
    () => history.push("/payment-method")
  );

  if (isFetching || !card) {
    return <></>;
  }

  return (
    <Form
      handleSubmit={updateCard}
      initialValues={{
        ...card,
        numero: `••••••••••••${card.numero_final}`,
        validade: card.data_validade,
        cvv: "",
      }}
    />
  );
}
