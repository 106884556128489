import React from "react";
import { Badge, Space, Dropdown, Table } from "antd";
import { DownOutlined } from "@ant-design/icons";

export const expandedRowRender = (values) => {
  const columns = [
    { title: "Tópico", dataIndex: "topico_ds_titulo", key: "topico_ds_titulo" },
    {
      title: "Treinamento",
      dataIndex: "treinamento_ds_titulo",
      key: "treinamento_ds_titulo",
    },
    {
      title: "Concluído",
      dataIndex: "treinamento_fg_concluido",
      key: "treinamento_fg_concluido",
      render: (text, record) => (
        <span>{record.treinamento_fg_concluido ? "Sim" : "Não"}</span>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={values.treinamentos}
      pagination={false}
    />
  );
};
