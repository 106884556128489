import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createUserRequest,
  deleteUserRequest,
  findUserRequest,
  findUsersRequest,
  registerUserRequest,
  updateUserRequest,
} from 'services/user';
import { Creators, Types } from 'store/reducers/user';
import { Creators as AuthCreators } from 'store/reducers/auth';
import { Toast } from 'utils';
import { push } from 'connected-react-router';

export function* findUser({ userLogin }) {
  const response = yield call(findUserRequest, userLogin);
  if (response.ok) {
    const { data: user } = response;
    yield put(Creators.userFindSuccess(user));
  } else {
    yield put(Creators.userError());
  }
}

export function* findUsers({ pageable }) {
  const response = yield call(findUsersRequest, {
    page: pageable ? pageable.page : undefined,
    size: pageable ? pageable.size : undefined,
  });
  if (response.ok) {
    const totalItems = +response.headers['x-total-count'];
    yield put(Creators.userListSuccess(response.data, totalItems));
  } else {
    yield put(Creators.userError());
  }
}

export function* deleteUser({ userLogin }) {
  const { user } = yield select(state => state.auth);
  const response = yield call(deleteUserRequest, userLogin);
  if (response.ok) {
    yield put(Creators.userDeleteSuccess());
    if (user.login === userLogin) {
      yield put(AuthCreators.logout());
    }
  } else {
    yield put(Creators.userError());
  }
}

export function* updateUser({ user }) {
  const userRequest = { ...user };
  const response = yield call(updateUserRequest, userRequest);
  if (response.ok) {
    yield put(Creators.userUpdateSuccess());
  } else {
    yield put(Creators.userError());
  }
}

export function* createUser({ user }) {
  const userRequest = { ...user };
  const { authenticated } = yield select(state => state.auth);
  const response = yield call(
    authenticated ? createUserRequest : registerUserRequest,
    userRequest
  );
  if (response.ok) {
    yield put(Creators.userCreateSuccess());
    if (!authenticated) {
      Toast.showSuccessMessage('Cadastro enviado! Verifique seu e-mail.');
      yield put(push('/login'));
    }
  } else {
    yield put(Creators.userError());
  }
}

export const userSagas = [
  takeLatest(Types.USER_FIND_REQUEST, findUser),
  takeLatest(Types.USER_LIST_REQUEST, findUsers),
  takeLatest(Types.USER_DELETE_REQUEST, deleteUser),
  takeLatest(Types.USER_UPDATE_REQUEST, updateUser),
  takeLatest(Types.USER_CREATE_REQUEST, createUser),
];

// [Types.USER_ACTIVATE_REQUEST]: userActivateRequest,
//     [Types.USER_ACTIVATE_SUCCESS]: userActivateSuccess,
