import { Spin } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTemplate from "template/PageTemplate";
import useFetch from "hooks/useFetch";
import { findUserRequest, updateUserRequest } from "services/user";
import UserForm from "../Form";
import moment from "moment";
import { Dates, Filters } from "utils";

const BackofficeUserEdit = () => {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching, data: user }] = useFetch(findUserRequest, id, true, null, null, (data) => !data && goBack());

  const [, updateUser] = useFetch(updateUserRequest, "", false, null, null, () => goBack());

  return (
    <PageTemplate title="Edição de Usuário" background>
      <Spin spinning={isFetching}>
        {user && (
          <>
            <UserForm
              initialValues={{
                ...user,
                dt_nascimento: user.dt_nascimento && moment(user.dt_nascimento, "YYYY-MM-DD").format("DD/MM/YYYY"),
              }}
              handleSubmitForm={(data) =>
                updateUser({
                  ...data,
                  dt_nascimento: data.dt_nascimento && Dates.transformDateToStringYYYYMMDDTrace(data.dt_nascimento),
                })
              }
              handleCancel={goBack}
            />
          </>
        )}
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeUserEdit;
