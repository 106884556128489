import api from "./api";

const findThoughtsRequest = (id) => {
  return api.get(`api/reflexoes/${id}`);
};

const findTrainingRequest = (id) => {
  return api.get(`api/treinamentos/${id}`);
};

const deleteTrainingRequest = id => {
  return api.delete(`api/treinamentos/${id}`);
};

const createThoughtRequest = (trainingId, body) => {
  const requestBody = {...body, treinamento_id: trainingId};
  return api.post('api/reflexoes', requestBody);
};

const inactivateThoughtRequest = (param, thoughtId) => {
  return api.delete(`api/reflexoes/${thoughtId}`);
};

const inactivateAnswerRequest = (param, answerId) => {
  return api.delete(`api/reflexaoResposta/${answerId}`);
};

const activateThoughtRequest = (param, thoughtId) => {
  return api.post(`api/reflexoes/active/${thoughtId}`);
};

const updateThoughtRequest = (param, body) => {
  const { thought_id: thoughtId } = body;
  return api.put(`api/reflexoes/${thoughtId}`, body);
};

const updateAnswerRequest = (param, body) => {
  const { answer_id: answerId } = body;
  return api.put(`api/reflexaoResposta/${answerId}`, body);
};

const createAnswerRequest = (param, body) => {
  return api.post('api/reflexaoResposta', body);
};

const updateTrainingRequest = topic => {
  return api.put('api/treinamentos', topic);
};

export {
  findThoughtsRequest,
  inactivateAnswerRequest,
  updateThoughtRequest,
  updateAnswerRequest,
  activateThoughtRequest,
  inactivateThoughtRequest,
  createAnswerRequest,
  createThoughtRequest,
};
