import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Spin, Table } from 'antd';
import PageTemplate from '../../../../template/PageTemplate';
import {Toast} from "../../../../utils";

import { createCourseModules, getUsersCourseModulesAndTopics } from 'services/courseModules';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: '100%'
  }
};

export const CourseModulesStudents = () => {
  const history = useHistory();
  const { idCourse, idTopic } = useParams();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loadingRegister, setLoadingRegister] = useState(false);

  // const dataTable = data.map((item, index) => ({key: index+'', ...item}))

  const columns = [
    {
      title: 'Aluno',
      dataIndex: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleRegister = async () => {
    setLoadingRegister(true);
    try {
      const users_id = data.filter(item => selectedRowKeys.includes(item.key)).map(item => item.id);

      const payload = {
        users_id,
        curso_id: idCourse,
        topico_id: idTopic,
      }

      await createCourseModules(payload);
      setSelectedRowKeys([])

      Toast.showSuccessMessage('Módulo liberado com sucesso!');
      getUsersByCourses();

    } catch (error) {
      Toast.showErrorMessage("Erro ao liberar o módulo do curso, por favor entre em contato imediatamente conosco!");
    } finally {
      setLoadingRegister(false);
    }
  };

  const getUsersByCourses = async () => {
    setLoadingData(true);
    try {
      const res = await getUsersCourseModulesAndTopics(idCourse, idTopic);

      const _data = res?.data?.users?.map((item, index) => ({key: index+'', ...item}))
      setData(_data)
    } catch (error) {

    } finally {
      setLoadingData(false);
    }
  }

  useEffect( () => {
    if (!idCourse || !idTopic) {
      history.goBack();
    }

    getUsersByCourses()
  }, []);

  return (
    <PageTemplate title={`Alunos`} backButton background>
      <div style={{ marginBottom: 15 }}>
        <Button type="primary" onClick={handleRegister} disabled={!hasSelected} loading={loadingRegister}>
          Liberar Módulo
        </Button>

        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selecionado ${selectedRowKeys.length} alunos` : ''}
        </span>
      </div>

      <Spin spinning={loadingData}>
        <Table style={styles.table} rowSelection={{ selectedRowKeys, onChange: onSelectChange }} columns={columns} dataSource={data} />
      </Spin>
    </PageTemplate>
  );
};
