import styled from 'styled-components';
import { Colors } from '../../configs';

export const BarContainer = styled.div`
  width: ${({ width }) => width || '270px'};
  position: relative;
`;

export const LabelContainer = styled.div`
  position: absolute;
  left: ${({ pct }) => `${pct > 93 ? 93 : pct}%`};
  top: ${({ heightSize }) => `${heightSize === 'md' ? 20 : 0}%`};
`;

export const PercentageBar = styled.div`
  margin-top: 7%;
  width: 100%;
  border-radius: 20px;
  background-color: #e1dede;
  height: 5px;
  ${({ heightSize }) => {
    if (heightSize === 'sm') {
      return 'height: 5px';
    }
    if (heightSize === 'md') {
      return 'height: 12px';
    }
    if (heightSize === 'lg') {
      return 'height: 25px';
    }
    return '5px';
  }};
`;
export const Fill = styled.div`
  background-color: ${Colors.greenCheck};
  border-radius: 20px;
  width: ${({ pct }) => `${pct}%`};
  height: 100%;
`;
