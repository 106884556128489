import styled from "styled-components";
import { Checkbox as CheckboxFmkAntd } from "formik-antd";
import { Checkbox as CheckboxAntd } from "antd";
import { Colors } from "configs";

export const Checkbox = styled(CheckboxFmkAntd)`
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${Colors.primary} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${Colors.primary};
    border: 1px solid ${Colors.primary};
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border: 1px solid ${Colors.primary};
    }
  }
`;

export const CheckboxWithStyle = styled(CheckboxAntd)`
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${Colors.primary} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: ${Colors.primary};
    border: 1px solid ${Colors.primary};
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border: 1px solid ${Colors.primary};
    }
  }
`;
