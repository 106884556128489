import * as Yup from "yup";
import Validations from "utils/Validations";

export const UserCredentialsValidationSchema = Yup.object().shape({
  nu_cpf: Yup.string()
    .trim()
    .nullable()
    .required("O campo CPF é obrigatório.")
    .test("nu_cpf", "O CPF informado não é válido", (val) => {
      if (val && val !== "") {
        const { isValid } = Validations.isCPF(val);
        return isValid;
      }
      return true;
    }),
  ds_sexo: Yup.string()
    .trim()
    .nullable()
    .required("O campo Sexo é obrigatório.")
    .test("ds_sexo", "O campo Sexo é obrigatório.", (val) => {
      return !(val && val === "NÃO INFORMADO");
    }),
  nu_celular: Yup.string()
    .trim()
    .nullable()
    .required("O campo Número celular é obrigatório."),
  nu_cep: Yup.string().trim().nullable().required("O campo CEP é obrigatório."),
  username: Yup.string().trim().nullable().required("O campo nome é obrigatório."),
  paroquia_id: Yup.number().nullable().required("O campo paróquia é obrigatório."),
  comarca_id: Yup.number().nullable().required("O campo comarca é obrigatório."),
  sg_uf: Yup.string().trim().nullable().required("O campo UF é obrigatório."),
  nm_cidade: Yup.string()
    .trim()
    .nullable()
    .required("O campo Cidade é obrigatório."),
  ds_endereco: Yup.string()
    .trim()
    .nullable()
    .required("O campo Endereço é obrigatório."),
});
