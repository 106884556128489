import React, { useEffect } from "react";
import { Colors, Metrics } from "configs";
import { useHistory } from "react-router-dom";
import { getRunningConcludedCoursesUserRequest } from "services/course";
import { Text, Whitespace } from "common";
import { CourseCardSm } from "components";
import useFetch from "../../hooks/useFetch";
import { Spin } from "antd";
import { useCommonSearch } from "store/hooks/common";

const styles = {
  outterContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: Metrics.spacing.md,
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
    color: Colors.secondary,
  },
  headerNavigation: {
    position: "absolute",
    width: "100%",
    top: 0,
    display: "flex",
    padding: Metrics.spacing.md,
    justifyContent: "space-between",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: Metrics.spacing.lg,
  },
  headerBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  seeAll: {
    boxShadow: "1px 1px 6px #cacaca",
    width: "260px",
    height: "118px",
    backgroundColor: Colors.white,
    margin: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    alignSelf: "center",
  },
};

const Home = () => {
  const history = useHistory();
  let searchValue = useCommonSearch();
  const [{ isFetching, data }, fetch] = useFetch(
    getRunningConcludedCoursesUserRequest,
    "",
    false,
    null,
    null
  );

  useEffect(() => {
    fetch({ search: searchValue });
  }, [searchValue]);

  if (
    data &&
    data.andamento.length === 0 &&
    data.concluido.length === 0 &&
    (!searchValue || searchValue.length === 0)
  ) {
    history.push("first-access");
    return <></>;
  }

  return (
    <div>
      {data ? (
        <div style={styles.outterContainer}>
          <Text bold size={Metrics.fontSize.md}>
            Meus cursos em andamento
          </Text>
          <Text
            size={Metrics.fontSize.xsm}
            color={Colors.secondary}
          >{`${data.andamento.length} cursos`}</Text>
          <div style={styles.container}>
            {data.andamento.slice(0, 4).map((item, i) => (
              <CourseCardSm
                item={item}
                key={i}
                onClick={() =>
                  history.push(`/course-progress/${item.curso_id}`)
                }
              />
            ))}
            {data.andamento.length > 4 && (
              <div
                style={styles.seeAll}
                onClick={() => history.push("my-courses-progress")}
              >
                <Text bold>Ver todos</Text>
              </div>
            )}
          </div>
          <Whitespace height={Metrics.spacing.lg} />
          <Text bold size={Metrics.fontSize.md}>
            Meus cursos concluídos
          </Text>
          <Text
            size={Metrics.fontSize.xsm}
            color={Colors.secondary}
          >{`${data.concluido.length} cursos`}</Text>
          <div style={styles.container}>
            {data.concluido.slice(0, 4).map((item, i) => (
              <CourseCardSm
                item={item}
                key={i}
                onClick={() =>
                  history.push(`/course-progress/${item.curso_id}`)
                }
              />
            ))}
            {data.concluido.length > 4 && (
              <div
                style={styles.seeAll}
                onClick={() => history.push("my-courses-completed")}
              >
                <Text bold>Ver todos</Text>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default Home;
