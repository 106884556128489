import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { Dropdown, Menu, Table } from "antd";
import { AiOutlineMore } from "react-icons/ai";
import PageTemplate from "template/PageTemplate";
import { Constants, Modal } from "utils";
import {
  findMaritalStatusListRequest,
  deleteMaritalStatusRequest,
  activateMaritalStatusRequest,
} from "services/maritalStatus";

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: "100%",
  },
};

export default function BackofficeMaritalStatus() {
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const [{ data }, fetch] = useFetch(
    findMaritalStatusListRequest,
    "",
    false,
    [],
    null
  );
  const [{}, inactivateRequest] = useFetch(
    deleteMaritalStatusRequest,
    "",
    false,
    null,
    null,
    fetch
  );
  const [{}, activateRequest] = useFetch(
    activateMaritalStatusRequest,
    "",
    false,
    null,
    null,
    fetch
  );
  const menu = useCallback(
    (record) => {
      return (
        <Menu>
          {!record.active && (
            <Menu.Item
              key="0"
              onClick={() => {
                Modal.openDeleteModal({
                  description: (
                    <span>
                      Deseja realmente ativar o estado civil
                      <strong> {record.ds_estado_civil}</strong>?
                    </span>
                  ),
                  onOk: () => activateRequest(record.id),
                  okText: "Sim",
                  cancelText: "Não",
                });
              }}
            >
              Ativar
            </Menu.Item>
          )}
          <Menu.Item
            key="0"
            onClick={() => {
              history.push(`/backoffice/marital-status/edit/${record.id}`);
            }}
          >
            Editar
          </Menu.Item>
          {record.active && (
            <Menu.Item
              key="1"
              danger
              onClick={() =>
                Modal.openDeleteModal({
                  description: (
                    <span>
                      Deseja realmente inativar o estado civil
                      <strong> {record.ds_estado_civil}</strong>?
                    </span>
                  ),
                  onOk: () => inactivateRequest(record.id),
                  okText: "Sim",
                  cancelText: "Não",
                })
              }
            >
              Inativar
            </Menu.Item>
          )}
        </Menu>
      );
    },
    [history]
  );
  const columns = [
    {
      title: "Descricao",
      dataIndex: "ds_estado_civil",
      key: "ds_estado_civil",
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  const handleFindUsers = useCallback(
    (page) => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect(() => handleFindUsers(1), []);

  return (
    <PageTemplate
      title="Estado Civil"
      backButton={false}
      newButton={{
        label: "Novo Estado Civil",
        onClick: () => history.push("/backoffice/marital-status/create"),
      }}
    >
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
}
