import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTemplate from "template/PageTemplate";
import { Spin } from "antd";
import useFetch from "hooks/useFetch";
import {
  findFrequentlyAskedQuestionRequest,
  updateFrequentlyAskedQuestionRequest,
} from "services/frequentlyAskedQuestion";
import FrequentlyAskedQuestionForm from "../Form";

export default function BackofficeFrequentlyAskedQuestionEdit() {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching: isFetchingFind, data: frequentlyAskedQuestion }] =
    useFetch(findFrequentlyAskedQuestionRequest, id, true, null);
  const [{ isFetching: isFetchingUpdate, data }, updateRequest] = useFetch(
    updateFrequentlyAskedQuestionRequest,
    "",
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Edição de Pergunta Frequente" background>
      <Spin spinning={isFetchingFind || isFetchingUpdate}>
        {frequentlyAskedQuestion && (
          <FrequentlyAskedQuestionForm
            initialValues={frequentlyAskedQuestion}
            handleSubmitForm={updateRequest}
            handleCancel={goBack}
          />
        )}
      </Spin>
    </PageTemplate>
  );
}
