import styled from "styled-components";
import { Select as Dropdown } from "formik-antd";
import { Colors } from "configs";

export const Select = styled(Dropdown)`
  box-shadow: none !important;

  :hover {
    .ant-select-selector {
      border-color: ${Colors.primary} !important;
      box-shadow: none !important;
    }
  }
`;
