import React from "react";
import { Formik } from "formik";
import { Form } from "antd";
import { FormButtons, FormInputText } from "components";
import { ValidationSchema } from "./ValidationSchema";

export default function MaritalStatusForm({
  handleSubmitForm,
  initialValues,
  handleCancel,
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmitForm}
    >
      {({ handleSubmit, values }) => (
        <Form>
          <FormInputText
            placeholder="Descrição"
            name="ds_estado_civil"
            value={values.ds_estado_civil}
            required
          />
          <FormButtons
            bt2={{ label: "Salvar", onClick: handleSubmit }}
            bt1={{ label: "Cancelar", onClick: handleCancel }}
          />
        </Form>
      )}
    </Formik>
  );
}
