import api from "./api";

const getCourseModules = (id) => {
  return api.get(`api/courseModules/user/${id}`);
};

const createCourseModules = (data) => {
  return api.post(`api/courseModules`, data);
};

const getUsersCourseModules = (id) => {
  return api.get(`api/usuarioCurso/buscaUsuarioCurso/${id}`);
};

const getUsersCourseModulesAndTopics = (id, idTopic) => {
  return api.get(`api/usuarioCurso/buscaUsuarioCurso/${id}/${idTopic}`);
};

export {
  getCourseModules,
  createCourseModules,
  getUsersCourseModules,
  getUsersCourseModulesAndTopics
};
