import { call, takeLatest } from "redux-saga/effects";
import { Creators, Types } from "store/reducers/course";
import { markTrainingCompletedRequest } from "services/course";

export function* markTrainingCompleted({ trainingId }) {
  const response = yield call(markTrainingCompletedRequest, trainingId);
  if (response.ok) {
    yield call(Creators.markTrainingCompletedFinished);
  } else {
    yield call(Creators.markTrainingCompletedFinished);
  }
}

export const courseSagas = [
  takeLatest(Types.MARK_TRAINING_COMPLETED_REQUEST, markTrainingCompleted),
];
