import api from "./api";
// import { Filters } from "utils";

const findCardRequest = (id) => api.get(`api/cartoes/${id}`);

const createCardRequest = (params, body) => {
  return api.post("api/cartoes", body);
};

const updateCardRequest = (params, body) => {
  return api.put(`api/cartoes/${body.id}`, body);
};

const findCardsRequest = () => {
  return api.get("api/cartoes");
};

const findCardDefaultRequest = () => {
  return api.get("api/cartoes/buscar/padrao");
};

export {
  findCardRequest,
  createCardRequest,
  updateCardRequest,
  findCardsRequest,
  findCardDefaultRequest,
};
