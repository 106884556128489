import React from "react";
import { AiFillStar, AiOutlineStar, AiFillLock, AiFillDollarCircle, AiFillUnlock } from "react-icons/ai";
import { Colors, Metrics } from "../../configs";
import { Text } from "../../common";

const styles = {
  accordion: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  starsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  maxWidthStarsContainerLarge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: 'wrap',
    maxWidth: '250px',
  },
  maxWidthStarsContainerSmall: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: 'wrap',
    maxWidth: '150px',
    marginLeft: Metrics.spacingSMMD
  }
};

const CourseSectionHeader = ({ ds, small, disabled, hasBuy }) => {
  return (
    <div style={styles.accordion}>
      <Text bold color={Colors.primary} size={small ? Metrics.fontSize.xxsm : Metrics.fontSize.xsm}>
        {ds.ds_titulo}
      </Text>

      {
        disabled ? (
          <AiFillLock color={Colors.softGray} size={small ? 15 : 25} />
        ) : hasBuy ? (
          <AiFillUnlock color={Colors.primaryStrong} size={small ? 15 : 25} />
        ) : (
          <div style={small ? styles.maxWidthStarsContainerSmall : styles.maxWidthStarsContainerLarge}>

            {ds.usuarioTreinamento.map((v, i) => (
              <span key={i} style={styles.starsContainer}>
                {v.fg_concluido ? (
                  <AiFillStar color={Colors.yellow} size={small ? 15 : 25} />
                ) : (
                  <AiOutlineStar color={Colors.yellow} size={small ? 15 : 25} />
                )}
              </span>
            ))}
          </div>
        )
      }
    </div>
  );
};

export default CourseSectionHeader;
