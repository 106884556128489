import { FormItem } from "formik-antd";
import {
  StyledInput,
  StyledInputPassword,
} from "common/FormInputCustom/styles";

const Input = ({
  placeholder,
  prefix,
  password,
  name,
  required,
  disabledNoColor = false,
  ...rest
}) => {
  return (
    <>
      {!password && (
        <FormItem name={name}>
          <StyledInput
            placeholder={required ? `${placeholder} *` : placeholder}
            prefix={prefix}
            name={name}
            disabledNoColor={disabledNoColor}
            {...rest}
          />
        </FormItem>
      )}
      {password && (
        <FormItem name={name}>
          <StyledInputPassword
            placeholder={required ? `${placeholder} *` : placeholder}
            prefix={prefix}
            name={name}
            disabledNoColor={disabledNoColor}
            {...rest}
          />
        </FormItem>
      )}
    </>
  );
};

export default Input;
