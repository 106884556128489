import { createGlobalStyle } from 'styled-components';
import { Colors } from 'configs';
// import "../../node_modules/slick-carousel/slick/slick.css";
// import "../../node_modules/slick-carousel/slick/slick-theme.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'antd/dist/antd.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }
  html, body, #root {
    min-height: 100%;
    background: ${Colors.appBg};
  }
  body {
    -webkit-font-smoothing: antialiased;
    color: #616161;
  }
  body, input, button {
    font: 14px 'Nunito', sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  .ant-layout {
    min-height: 100%;
  }
  .Toastify__toast {
    border-radius: 6px;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title, .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title, .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: small;
  }
  .ant-form {
    width: 100%;
  }
  .ant-input-affix-wrapper:hover {
    border-color: ${Colors.primary};
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: ${Colors.primary};
  }
  .ant-pagination-item-active {
    border-color: rgb(62, 179, 169);
  }
  .ant-pagination-item-active a {
    color: rgb(62, 179, 169);
  }
`;
