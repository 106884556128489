import React, { useCallback, useEffect, useState } from 'react';

import { Formik, useFormik } from 'formik';
import { Form } from 'formik-antd';
import styled from 'styled-components';
import { Space as SpaceAntd, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import Filters from 'utils/Filters';
import { Colors, Metrics } from 'configs';
import Input from 'common/FormInputCustom';
import useFetch from '../../hooks/useFetch';
import { estados, sexo } from 'services/data';
import PageTemplate from 'template/PageTemplate';
import { Button, FormDropdown, Whitespace } from 'common';
import { findCountiesRequest } from '../../services/county';
import { useAuthUpdateCredentialsHandler, useAuthUser } from 'store/hooks/auth';
import { UserCredentialsValidationSchema } from 'features/UserCredentials/ValidationSchema';

import { Toast } from '../../utils';
import DateContainer from './DateContainer';
import { findParishesRequest, validateParishRequest } from '../../services/parish';
import { findMaritalStatusRequest, updateCredentialsRequest } from '../../services/user';
import { getDiocesesByUf } from 'services/diocese';

const { Text } = Typography;

const Space = styled(SpaceAntd)`
  .ant-space-item {
    width: 100%;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UserCredentials() {
  let query = useQuery();
  const { push } = useHistory();

  const promoCode = query.get("code");
  const courseId = query.get("course");
  const pendent = query.get("pendent");
  const returnTo = query.get("returnTo");

  const user = useAuthUser();
  const handleUpdateCredetials = useAuthUpdateCredentialsHandler();

  const [dioceses, setDioceses] = useState([]);
  const [parishValid, setParishValid] = useState();
  const [isAnotherParish, setIsAnotherParish] = useState(false);

  const [{ isFetching, data: parishes }, fetchParishes] = useFetch(findParishesRequest, '', false, [], null, (res) => {
    res.data.length && verifyIsAnotherParish(res.data)
  });
  const [{ isFetching: isFetchingConties, data: counties }] = useFetch(findCountiesRequest, '', true, []);
  const [{ isFetching: isFetchingUpdate, data }, updateParish] = useFetch(updateCredentialsRequest, '', false, null);
  const [{ isFetching: isFetchingMaritalStatus, data: maritalStatus }, fetch] = useFetch(findMaritalStatusRequest, '', true, []);
  const [{ isFetching: isFetchingParishValidation, data: parishValidation }, fetchParishValidation] = useFetch(validateParishRequest, '', false, null);

  const handleSubmit = useCallback(
    (data) => {
      try {
        const newData = { ...data, email: user.email };
        newData.nu_cpf = Filters.removeMaskCPF(newData.nu_cpf);
        newData.nu_celular = Filters.removeMaskTEL(newData.nu_celular);
        newData.nu_cep = Filters.removeMaskCEP(newData.nu_cep);

        if (isAnotherParish && !data.another_parish) {
          Toast.showErrorMessage('Paróquia inválida!')
          return;
        }

        if (newData.teste) {
          newData.dt_nascimento = new Date(`${newData.teste.substring(0, 5)}/${newData.teste.substring(5, 11)}`);
        } else {
          newData.dt_nascimento = user.dt_nascimento;
        }
        handleUpdateCredetials(newData, courseId, pendent, returnTo);
        updateParish(data);

      } catch (error) {
        console.log(83, error)
      }
    },
    [handleUpdateCredetials, courseId, user, isAnotherParish]
  );

  const getDioceses = useCallback(async (uf = user.sg_uf) => {
    try {
      const res = await getDiocesesByUf(uf);
      setDioceses(res.data);
    } catch (error) {
      console.log(81, error);
    }
  }, [user])

  const verifyIsAnotherParish = useCallback((data) => {
    const comarca_id = data[0].comarca_id;
    const findCounties = counties.find(countie => countie.id === comarca_id);

    if (findCounties?.nm_comarca === 'Outra') {
      const findparishes = data?.find(parishe => parishe?.nm_paroquia === 'Outra')

      findparishes?.id && formik.setFieldValue('paroquia_id', findparishes.id)
    }

    setIsAnotherParish(findCounties?.nm_comarca === 'Outra')
  }, [counties, parishes]);

  const validateButton = useCallback(() => {
    if (isAnotherParish) return false;

    if (!parishValid && pendent) return true

    return false;
  }, [parishValid, pendent, isAnotherParish])

  useEffect(() => {
    if (parishValidation) {
      setParishValid(parishValidation.valid);
      if (!parishValidation.valid) {
        Toast.showErrorMessage(
          "Código promocional invalido para sua paróquia!"
        );
        push(`/course-buy/${courseId}`);
      }
    }
  }, [parishValidation]);

  useEffect(() => {
    if (user && user.comarca_id) {
      fetchParishes(user.comarca_id)
    }

    if (user && user.paroquia_id && pendent) {
      fetchParishValidation({
        ds_codigo_promocional: promoCode,
        paroquia_id: user.paroquia_id
      })
    }

    getDioceses()
  }, [user, counties, isAnotherParish]);

  const handleChange = (name, value) => {
    formik.setFieldValue(name, value);
  }

  const formik = useFormik({
    initialValues: user,
    validationSchema: UserCredentialsValidationSchema,
    onSubmit: handleSubmit
  })

  return (
    <PageTemplate contentStyle={styles.container}>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.handleSubmit}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <Form style={styles.form} onSubmit={formik.handleSubmit}>
            <div style={styles.wrapper}>
              <Text
                strong
                style={{
                  color: Colors.primary,
                  fontSize: Metrics.fontSize.sm,
                }}>
                Confirme/Informe seus dados para prosseguir
              </Text>
              <div style={styles.inputContainer}>
                <Space size="large">
                  <Input
                    required
                    name="username"
                    placeholder="Nome"
                    value={formik.values.username}
                    onChange={e => handleChange('username', e.target.value)}
                  />
                  <Input
                    disabled
                    name="email"
                    disabledNoColor
                    placeholder="E-mail"
                    value={formik.values.email}
                    onChange={e => handleChange('email', e.target.value)}
                  />
                </Space>
                <Space size="large" align="end">
                  <FormDropdown
                    required
                    list={sexo}
                    name="ds_sexo"
                    placeholder="Sexo"
                    value={formik.values.ds_sexo}
                    onChange={e => handleChange('ds_sexo', e)}
                  />
                  <Input
                    required
                    name="nu_cpf"
                    placeholder="CPF"
                    onChange={e => handleChange('nu_cpf', e.target.value)}
                    value={Filters.inputMaskCPF(formik.values.nu_cpf)}
                  />
                </Space>
                <Space size="large">
                  <Input
                    required
                    name="nu_celular"
                    placeholder="Número celular"
                    onChange={e => handleChange('nu_celular', e.target.value)}
                    value={Filters.inputMaskCellphone(formik.values.nu_celular)}
                  />
                  <Input
                    required
                    name="nu_cep"
                    placeholder="CEP"
                    onChange={e => handleChange('nu_cep', e.target.value)}
                    value={Filters.inputMaskCEP(formik.values.nu_cep)}
                  />
                </Space>
                <Space size="large" align="end">
                  <FormDropdown
                    placeholder="UF"
                    name="sg_uf"
                    value={formik.values.sg_uf}
                    list={estados}
                    required
                    onChange={e => {
                      handleChange('sg_uf', e)
                      getDioceses(e)
                      formik.setFieldValue('id_diocese', undefined)
                    }}
                  />
                  <Input
                    required
                    name="nm_cidade"
                    placeholder="Cidade"
                    value={formik.values.nm_cidade}
                    onChange={e => handleChange('nm_cidade', e.target.value)}
                  />
                </Space>
                <div>
                  <Input
                    required
                    name="ds_endereco"
                    value={formik.values.ds_endereco}
                    placeholder="Endereço completo ( Rua, Av, etc)"
                    onChange={e => handleChange('ds_endereco', e.target.value)}
                  />
                </div>
                <Space size="large" align="end">
                  <Input
                    required
                    name="nr_endereco"
                    placeholder="Número"
                    value={formik.values.nr_endereco}
                    onChange={e => handleChange('nr_endereco', e.target.value)}
                  />
                  <Input
                    placeholder="Complemento"
                    name="ds_complemento"
                    value={formik.values.ds_complemento}
                    onChange={e => handleChange('ds_complemento', e.target.value)}
                  />
                </Space>
                <div>
                  <Input
                    placeholder="Bairro"
                    name="nm_bairo"
                    value={formik.values.nm_bairo}
                    required
                    onChange={e => handleChange('nm_bairo', e.target.value)}
                  />
                </div>
                <Space size="large">
                  <FormDropdown
                    required
                    list={dioceses}
                    name="id_diocese"
                    propertyValue='id'
                    placeholder="Diocese"
                    propertyLabel='diocese'
                    value={formik.values.id_diocese}
                    disabled={!formik.values.sg_uf}
                    onChange={e => handleChange('id_diocese', e)}
                  />
                </Space>
                <Whitespace height={Metrics.spacing.md} />
                <Space size="large" align="end">
                  <FormDropdown
                    placeholder="Estado Civil"
                    name="estado_civil_id"
                    value={formik.values.estado_civil_id}
                    list={maritalStatus || []}
                    propertyValue='id'
                    propertyLabel='ds_estado_civil'
                    required
                    onChange={e => handleChange('estado_civil_id', e)}
                  />
                  <DateContainer />
                </Space>
                <Space size="large" align="end">
                  <FormDropdown
                    placeholder="Comarca"
                    name="comarca_id"
                    propertyValue='id'
                    value={formik.values.comarca_id}
                    list={counties || []}
                    propertyLabel='nm_comarca'
                    required
                    onChange={(e) => {
                      handleChange('comarca_id', e)
                      if (e) {
                        fetchParishes(e);
                        formik.setFieldValue('paroquia_id', null)
                      } else {
                        formik.setFieldValue('paroquia_id', null)
                      }
                    }}
                  />

                  {
                    (isAnotherParish) ? (
                      <Input
                        placeholder="Paróquia"
                        name="another_parish"
                        value={formik.values.another_parish}
                        onChange={e => handleChange('another_parish', e.target.value)}
                        required
                      />
                    ) : (
                      <FormDropdown
                        placeholder="Paróquia"
                        name="paroquia_id"
                        value={formik.values.paroquia_id}
                        list={parishes || []}
                        disabled={!formik.values.comarca_id}
                        propertyValue='id'
                        propertyLabel='nm_paroquia'
                        onChange={(e) => {
                          handleChange('paroquia_id', e)
                          if (e && pendent) {
                            fetchParishValidation({
                              ds_codigo_promocional: promoCode,
                              paroquia_id: e
                            })
                          }
                        }}
                        required
                      />
                    )
                  }

                </Space>
              </div>
              <Button
                width="200px"
                type="submit"
                onClick={handleSubmit}
                disabled={validateButton()}
              >
                Alterar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PageTemplate>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.white,
    padding: "40px 60px",
    borderRadius: "20px",
    boxShadow: "0px 1px 6px #0000001A",
    minWidth: "60%",
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: Metrics.spacing.xlg,
    marginBottom: Metrics.spacing.xlg,
    width: "100%",
  },
  row: {
    display: "flex",
  },
};
