import React from "react";
import { Colors, Metrics } from "configs";
import CoursePlaceholder from "assets/images/courseBg.png";
import Filters from "utils/Filters";
import {Tooltip, Typography} from "antd";
import Moment from 'moment';

const { Paragraph } = Typography;

const styles = {
  courseCard: {
    position: "relative",
    backgroundColor: "white",
    margin: "0 25px 25px 0",
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    width: "330px",
    height: "250px",
    boxShadow: "1px 1px 6px #cacaca",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  resume: {
    borderRadius: Metrics.defaultRadius,
    backgroundColor: "#F6F6F6",
    padding: Metrics.spacing.sm,
    marginTop: Metrics.spacing.md,
    flex: 1,
    width: "100%",
  },
  price: {
    position: "absolute",
    right: 12,
    top: 15,
  },
  img: {
    maxWidth: "65px",
    Width: "65px",
    maxHeight: "65px",
    height: "65px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  value: {
    color: Colors.greenCheck,
    fontSize: Metrics.fontSize.sm,
  },
  infos: {
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    flexDirection: "column",
  },
};

export default ({ item, onClick = () => {}, courseValue, style }) => {
  return (

    <div  style={{ ...styles.courseCard, ...style }}  onClick={() => {
      if (item.fg_ativo && Moment(new Date()).isBetween(item.dt_inicio, item.dt_fim)) {
        onClick();
      }}
    }>
      <div style={styles.price}>
        <span style={styles.value}>
          {Moment(new Date()).isBefore(item.dt_inicio) && item.fg_ativo &&
            <span style={{ fontSize: Metrics.fontSize.xsm, position: 'absolute', background: 'white', color: Colors.primary, fontWeight: 'bolder',
              boxShadow: '0px 0px 10px #aaa',
              borderRadius: '8px',
              padding: '5px',
              right: '1%',
              zIndex: 2,
              top: '5%'}}>Inscrições a partir de {Moment(item.dt_inicio).format("DD/MM/YYYY")}</span>
          }
          {item.vl_curso === 0
            ? "Curso livre"
            : Filters.convertMoneyTextMask(courseValue ?? item.vl_curso)}
        </span>
      </div>
      <div>
        <img src={item.ds_imagem} style={styles.img} alt="" />
      </div>
      <div style={styles.infos}>
        <Paragraph
          ellipsis={{ rows: 2 }}
          style={{
            fontWeight: "bold",
            margin: 0,
            fontSize: Metrics.fontSize.sm,
            color: Colors.blk,
          }}
        >
          {item.ds_titulo}
        </Paragraph>
        <div style={styles.resume}>
          <Paragraph
            ellipsis={{ rows: 4 }}
            style={{
              margin: 0,
              fontSize: Metrics.fontSize.xsm,
              color: Colors.blk,
            }}
          >
            {item.ds_resumo}
          </Paragraph>
        </div>
      </div>
    </div>
  );
};
