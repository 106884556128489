import api from "./api";

const findAllFrequentlyAskedQuestionsRequest = () => {
  return api.get(`api/perguntaFrequente`);
};

const findFrequentlyAskedQuestionRequest = (id) => {
  return api.get(`api/perguntaFrequente/${id}`);
};

const createFrequentlyAskedQuestionRequest = (param, data) => {
  return api.post(`api/perguntaFrequente`, data);
};

const updateFrequentlyAskedQuestionRequest = (param, data) => {
  return api.put(`api/perguntaFrequente/${data.id}`, data);
};

const activateFrequentlyAskedQuestionRequest = (param, id) => {
  return api.put(`api/perguntaFrequente/activate/${id}`);
};

const inactivateFrequentlyAskedQuestionRequest = (param, id) => {
  return api.put(`api/perguntaFrequente/inactivate/${id}`);
};

export {
  findAllFrequentlyAskedQuestionsRequest,
  findFrequentlyAskedQuestionRequest,
  createFrequentlyAskedQuestionRequest,
  updateFrequentlyAskedQuestionRequest,
  activateFrequentlyAskedQuestionRequest,
  inactivateFrequentlyAskedQuestionRequest,
};
