import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Text, Whitespace } from 'common';
import { Colors, Metrics } from "configs";
import { useHistory } from "react-router-dom";
import React from 'react';
import { Button } from 'antd';

const PageTemplate = ({
  children,
  containerStyle = {},
  contentStyle = {},
  title,
  newButton,
  backButton = true,
  backButtonStyle = {},
  beforeContent,
}) => {
  const history = useHistory();
  const backIconColor =
    backButtonStyle && backButtonStyle.color
      ? backButtonStyle.color
      : "#252B2A";

  return (
    <div style={{ ...styles.container, ...containerStyle }}>
      {backButton && (
        <div
          style={{ ...styles.headerBtnBlock, ...backButtonStyle }}
          onClick={() => history.goBack()}
        >
          <LeftOutlined style={{ ...styles.icon, color: backIconColor }} />
          <Whitespace width={Metrics.spacing.sm} />
          <Text
            bold
            color={
              backButtonStyle && backButtonStyle.color
                ? backButtonStyle.color
                : "#252B2A"
            }
          >
            VOLTAR
          </Text>
        </div>
      )}
      <Row style={{justifyContent: 'space-between'}}>
        {title && <div style={styles.title}>{title}</div>}
        {newButton && (
          <Button
            type="primary"
            style={{backgroundColor: Colors.primary, borderColor: Colors.primary, borderRadius: Metrics.defaultRadius}}
            icon={<PlusOutlined style={{ fontSize: 15 }} />}
            size="medium"
            onClick={newButton.onClick}>
            {newButton.label}
          </Button>
        )}
      </Row>
      {beforeContent && beforeContent}
      <div style={{ ...styles.content, ...contentStyle }}>{children}</div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    paddingBottom: Metrics.spacing.lg
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
  },
  headerBtnBlock: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#252B2A",
    marginBottom: Metrics.spacingXLG,
    width: "max-content",
  },
  title: {
    color: Colors.primary,
    fontSize: Metrics.fontSize.sm,
    fontWeight: 700,
    marginBottom: Metrics.spacingLG,
  },
};

export default PageTemplate;
