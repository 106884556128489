import { Dropdown, Layout, Menu, Typography } from 'antd';
import { useHistory } from "react-router-dom";
import { AnimatedButton } from "common";
import { About, Contact, Courses, App } from "features/Landing";
import logo from "assets/images/logo-line-novo.png";
import logoDiocese from "assets/images/Logotipo-brasao-diocese.png";
import { ScrollTo } from "react-scroll-to";

import { ContentStyled, HeaderStyled, HeaderText } from "./styles";
import { Metrics } from "configs";
import { useAuthAuthenticated, useAuthUser } from "store/hooks/auth";
import useBreakpoint from 'use-breakpoint';
import { Constants } from '../../utils';
import { BarsOutlined, DownOutlined, MenuOutlined } from '@ant-design/icons';

const scrollToComponent = require("react-scroll-to-component");

const Header = () => {
  const { breakpoint } = useBreakpoint(Constants.BREAKPOINTS, 'desktop');
  const authenticated = useAuthAuthenticated();
  const user = useAuthUser();
  const { push } = useHistory();
  const handleRedirect = (routeName, scroll = null) => {
    scroll && scroll({ x: 0, y: 0, smooth: true });
    push(routeName);
  };

  const handleScroll = (component, offset = 100) => {
    setTimeout(
      () =>
        scrollToComponent(component, {
          offset,
        }),
      100
    );
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => {
        handleRedirect("register", true);
      }} key="1">
        <AnimatedButton>
          Cadastrar
        </AnimatedButton>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" style={{textAlign: 'center'}}>Entrar</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" style={{textAlign: 'center'}}>Cursos</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" style={{textAlign: 'center'}}>Sobre</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" style={{textAlign: 'center'}}>Aplicativo</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" style={{textAlign: 'center'}}>Contato</Menu.Item>
      <Menu.Divider />
    </Menu>
  );

  return (
    <ScrollTo>
      {({ scroll }) => (
        <HeaderStyled responsive={breakpoint !== 'desktop'}>
          <div
            style={styles.logoContainer}
            onClick={() => handleRedirect("/")}
            className="logo-container"
          >
            <img
              onClick={() => scroll({ x: 0, y: 0, smooth: true })}
              src={logo}
              alt="logo"
              style={{
                cursor: "pointer",
                maxWidth: "150px",
                minWidth: "100px",
              }}
            />
          </div>
          <div
            style={styles.logoContainer}
            onClick={() => handleRedirect("/")}
            className="logo-container"
          />
          {breakpoint !== 'desktop' &&
          <Dropdown overlay={
            <Menu>
              <Menu.Item onClick={() => {
                handleRedirect("register", scroll);
              }} key="1">
                <AnimatedButton>
                  Cadastrar
                </AnimatedButton>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="2" onClick={() => {
                           handleRedirect("login", scroll);
                         }}
                         style={{textAlign: 'center'}}>Entrar</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="3" onClick={() => handleScroll(Courses, 0)} style={{textAlign: 'center' }}>Cursos</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="4" onClick={() => handleScroll(About, -50)} style={{textAlign: 'center'}}>Sobre</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="5" onClick={() => handleScroll(App, -50)} style={{textAlign: 'center'}}>Aplicativo</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="6" onClick={() => handleScroll(Contact)} style={{textAlign: 'center'}}>Contato</Menu.Item>
              <Menu.Divider />
            </Menu>
          } trigger={['click']}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              <MenuOutlined style={{color: '#333', fontSize: Metrics.fontSize.md}} />
            </a>
          </Dropdown>
          }
          {breakpoint === 'desktop' &&
          <div style={styles.itemsContainer}>
            <HeaderText onClick={() => handleScroll(Courses, 0)}>
              <span>Cursos</span>
            </HeaderText>
            <HeaderText onClick={() => handleScroll(About, -50)}>
              <span>Sobre</span>
            </HeaderText>
            <HeaderText onClick={() => handleScroll(App, -50)}>
              <span>Aplicativo</span>
            </HeaderText>
            {/*<HeaderText onClick={() => handleScroll(Users)}>
                <span>user</span>
              </HeaderText>*/}
            <HeaderText onClick={() => handleScroll(Contact)}>
              <span>Contato</span>
            </HeaderText>
          </div>
          }
          { breakpoint === 'desktop' &&
          <div style={styles.buttonsContainer}>
            {authenticated && (
              <HeaderText onClick={() => handleRedirect("my-courses")}>
                <Typography.Text ellipsis>
                  Olá, {user.username} <p>Acesse seus cursos!</p>{" "}
                </Typography.Text>
              </HeaderText>
            )}
            {!authenticated && (
              <>
                <HeaderText
                  onClick={() => {
                    handleRedirect("login", scroll);
                  }}
                >
                  <span>Entrar</span>
                </HeaderText>
                <AnimatedButton
                  onClick={() => {
                    handleRedirect("register", scroll);
                  }}
                >
                  Cadastrar
                </AnimatedButton>
              </>
            )}
          </div>
          }
        </HeaderStyled>
      )}
    </ScrollTo>
  );
};

const NotPrivateLayout = ({ children }) => {
  return (
    <Layout>
      <Header />
      <Layout style={{ marginTop: Metrics.headerSize }}>
        <ContentStyled>{children}</ContentStyled>
      </Layout>
    </Layout>
  );
};

const styles = {
  logoContainer: {
    flex: 0.5,
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 2,
    justifyContent: "center",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-end",
  },
};

export default NotPrivateLayout;
