import { Colors, Metrics } from "configs";
import { Button } from "common";
import styled from "styled-components";
import { Modal as ModalAntd, Spin, Divider, Select } from "antd";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { Row, Text } from "common";
import pgtCartao from "assets/images/pgmtoCartao.png";
import pgtCartaoBranco from "assets/images/pgmtoCartaoBranco.png";
import pgtCod from "assets/images/pgmtoCodigoBarras.png";
import pgtCodBranco from "assets/images/pgmtoCodigoBarrasBranco.png";
import * as lodash from "lodash";
import SelectOrCreateCard from "features/FinancialData/components/CardSelector";
import { CheckboxWithStyle as Checkbox } from "common/FormCheckbox/styles";
import useFetch from "hooks/useFetch";
import { cancelChargeRequest, generateChargeRequest } from "services/payment";
import { PaymentTypesKey } from "features/FinancialData/utils";

const {Option} = Select;

export const ModalStyled = styled(ModalAntd)`
  background-color: ${({backgroundColor}) => backgroundColor};
  border-radius: 20px;
  box-shadow: 0 4px 6px #0000001a;
  padding: 0;

  .ant-modal-content {
    box-shadow: none;
    background: none;
    border-radius: 20px;
  }

  .ant-modal-header,
  .ant-modal-body {
    background-color: ${({backgroundColor}) => backgroundColor};
    border-radius: 20px;
  }

  .ant-modal-close-x {
    color: ${({closeColor}) => closeColor};
  }
`;

const INITIAL_STATE = {
  visible: false,
  disabled: false,
  chargeCancel: false,
  newCharge: false,
  paymentOpt: null,
  data: {},
  installments: null,
  selectedCard: null,
  code: ""
};

const Modal = forwardRef(({
                            cards,
                            fetchCards,
                            disabledButton,
                            fetchFinancialData
                          }, ref) => {
  const [state, setState] = useState(INITIAL_STATE);

  const getOptStyle = useCallback((opt) => {
    if (opt === state.paymentOpt) {
      return styles.paymentOptBtnSelected;
    }
  }, [state.paymentOpt]);

  useImperativeHandle(ref, () => ({
    openModal: ({data: record, chargeCancel}) => {
      setState(() => ({...INITIAL_STATE, visible: true, data: record, chargeCancel}));
    },
  }), []);

  function handleCancel() {
    setState(INITIAL_STATE);
  }

  function handleChangeProp(prop, value) {
    setState(oldState => ({...oldState, [prop]: value}));
  }

  const paymentQtProp = useMemo(() => {
    if (state.paymentOpt === "b") {
      return "nu_parcelas_boleto";
    } else if (state.paymentOpt === "p") {
      return "nu_parcelas_pix";
    } else {
      return "nu_parcelas_cartao";
    }
  }, [state.paymentOpt]);

  const [{isFetching: isFetchingCharge}, generateChargeSlip] = useFetch(generateChargeRequest,
    "",
    false,
    [],
    null,
    () => {
      handleCancel();
      fetchFinancialData();
    }
  );

  const [{isFetching: isFetchingCancelCharge}, cancelCharge] = useFetch(
    cancelChargeRequest,
    "",
    false,
    [],
    null,
    () => {
      handleCancel();
      fetchFinancialData();
    }
  );

  const handleConfirm = useCallback(() => {
    function generetaChager(method, onlyCourse = false) {
      if (onlyCourse) {
        method({cursoId: state.data.curso_id});
      } else {
        if (state.paymentOpt === "c") {
          if (!state.code || !state.selectedCard) {
            return;
          }
          method({
            cursoId: state.data.curso_id,
            qtdParcela: state.installments,
            tipoCobranca: PaymentTypesKey[state.paymentOpt],
            ccv: state.code,
            cartaoId: state.selectedCard.id,
          });
        } else {
          method({
            cursoId: state.data.curso_id,
            qtdParcela: state.installments,
            tipoCobranca: PaymentTypesKey[state.paymentOpt],
          });
        }
      }
    }

    if (state.chargeCancel) {
      if (state.newCharge) {
        generetaChager(cancelCharge);
      } else {
        generetaChager(cancelCharge, true);
      }
    }
    if (!state.chargeCancel && state.installments) {
      generetaChager(generateChargeSlip);
    }
  }, [state]);


  return <ModalStyled
    visible={state.visible}
    onCancel={handleCancel}
    footer={null}
    backgroundColor={Colors.appBg}
    closeColor={Colors.primary}
  >
    <div style={styles.modalContent}>
      <span style={styles.modalText}>{state.chargeCancel ? 'Cancelamento de cobrança' : 'Geração de pagamento'}</span>
      {state.chargeCancel && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
            <span>
              <b>Atenção:</b>
            </span>
          <span>
              Ao clicar no botão de confirmar você irá cancelar todas as
              cobranças referente ao curso: <i>{state.data.ds_titulo}</i>.
            </span>
          <Checkbox
            style={{marginRight: 15}}
            checked={state.newCharge}
            onChange={(e) => handleChangeProp("newCharge", e.target.checked)}
          >
            Desejo realizar novas cobranças.
          </Checkbox>
          {state.newCharge && <Divider/>}
        </div>
      )}
      {(!state.chargeCancel || (state.chargeCancel && state.newCharge)) && (
        <>
          <div style={styles.paymentOptBtns}>
            {state.data.nu_parcelas_cartao && (
              <>
                <div
                  style={{...styles.paymentOptBtn, ...getOptStyle("c")}}
                  onClick={() => {
                    handleChangeProp("installments", null);
                    handleChangeProp("selectedCard", null);
                    handleChangeProp("code", null);
                    handleChangeProp("paymentOpt", "c");
                  }}
                >
                  <div style={styles.modalContent}>
                    <img
                      style={styles.paymentOptImg}
                      src={state.paymentOpt === "c" ? pgtCartaoBranco : pgtCartao}
                      alt=""
                    />
                    <Text
                      color={
                        state.paymentOpt === "c" ? Colors.white : Colors.primary
                      }
                      bold
                    >
                      Cartão
                    </Text>
                  </div>
                </div>
              </>
            )}
            {state.data.nu_parcelas_boleto && (
              <div
                style={{...styles.paymentOptBtn, ...getOptStyle("b")}}
                onClick={() => {
                  handleChangeProp("installments", null);
                  handleChangeProp("paymentOpt", "b");
                }}
              >
                <div style={styles.modalContent}>
                  <img
                    style={styles.paymentOptImg}
                    src={state.paymentOpt === "b" ? pgtCodBranco : pgtCod}
                    alt=""
                  />
                  <Text
                    bold
                    color={state.paymentOpt === "b" ? Colors.white : Colors.primary}
                  >
                    Boleto
                  </Text>
                </div>
              </div>
            )}
          </div>
          {state.paymentOpt && (
            <>
              <Select
                placeholder="Selecione o número de parcelas"
                style={{width: "100%", marginBottom: Metrics.spacing.md}}
                value={state.installments}
                onChange={v => handleChangeProp("installments", v)}
              >
                {lodash.times(state.data[paymentQtProp], (e) => (
                  <Option key={e} value={e + 1}>
                    {e === 0 ? "à vista" : e + 1}
                  </Option>
                ))}
              </Select>

              {state.paymentOpt === "c" && (
                <SelectOrCreateCard
                  cards={cards}
                  findCards={fetchCards}
                  selectedCard={state.selectedCard}
                  setSelectedCard={(v) => {
                    setState(oldValue => ({
                      ...oldValue,
                      selectedCard: oldValue.selectedCard?.id === v?.id ? false : v
                    }));
                    // handleChangeProp("selectedCard", v);
                  }}
                  code={state.code}
                  setCode={v => handleChangeProp("code", v)}
                />
              )}
            </>
          )}
        </>
      )}
      {(isFetchingCancelCharge || isFetchingCharge) &&
      <Row style={{justifyContent: "center", alignItems: "center", marginBlock: Metrics.spacingMD}}>
        <Spin/>
      </Row>
      }
      <Button
        onClick={handleConfirm}
        disabled={state.chargeCancel && !state.newCharge
          ? false
          : state.paymentOpt === "c"
            ? !state.installments || !state.code || !state.selectedCard
            : !state.installments}
      >
        Confirmar
      </Button>
    </div>
  </ModalStyled>
    ;
});

export default Modal;

const styles = {
  modalText: {
    fontSize: Metrics.fontSize.sm,
    color: Colors.primary,
    fontWeight: 600,
    margin: `${Metrics.spacingLG} 0`,
  },
  icons: {
    cursor: "pointer",
  },
  paymentOptBtns: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBlock: Metrics.spacing.md,
  },
  paymentOptBtn: {
    position: "relative",
    boxShadow: "1px 1px 7px #cacaca",
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `${Metrics.spacing.lg} ${Metrics.spacing.xlg}`,
    margin: Metrics.spacing.sm,
    width: "33%",
    cursor: "pointer",
    transition: "all 0.4s ease",
  },
  paymentOptBtnSelected: {
    backgroundColor: Colors.primary,
    color: Colors.white,
  },
  paymentOptImg: {
    width: "40px",
    marginBottom: Metrics.spacing.sm,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
