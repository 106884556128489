import { Tabs } from "antd";
import { Colors, Metrics } from "configs";
import styled from "styled-components";

export const TabsStyled = styled(Tabs)`
  background-color: ${Colors.white};
  padding: ${Metrics.spacing.md};
  border-radius: ${Metrics.defaultRadius};

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary};
  }

  .ant-tabs-ink-bar {
    background-color: ${Colors.primary};
  }

  .ant-tabs-tab {
    &:hover {
      color: ${Colors.primary};
    }
  }
`;
export const TabPaneStyled = styled(Tabs.TabPane)``;
