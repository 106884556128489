import { useRef } from "react";
import { Typography } from "antd";
import { Colors } from "configs";
import { Button, Row } from "common";
import {
  Container,
  FormFooterContainer,
  Input,
  TextArea,
  TitleContainer,
  TitleMark,
} from "./styles";
import { Form, FormItem } from "formik-antd";
import { Formik } from "formik";
import { ValidationSchema } from "./ValidationSchema";
import useFetch from "hooks/useFetch";
import { contactRequest } from "services/contact";
import { Toast } from "utils";
import { useAuthUser } from "store/hooks/auth";

const { Text } = Typography;

export default function ContactForm() {
  const authUser = useAuthUser();

  const formikRef = useRef();
  const [, fetch] = useFetch(contactRequest, null, false, null, null, () => {
    formikRef && formikRef.current && formikRef.current.handleReset();
    Toast.showSuccessMessage("Formulário de contato enviado com sucesso!");
  });

  const handleSubmit = (data) => {
    fetch(data);
  };

  return (
    <Container id="contact">
      <TitleContainer>
        <Text style={styles.title} strong>
          Dúvidas e sugestões? Entre em contato:
        </Text>
        <Text style={styles.subTitle} strong>
          Studium | Centro de Formação da Diocese de Joinville.
        </Text>
        <TitleMark />
      </TitleContainer>

      <Row responsive style={styles.row}>
        <Formik
          initialValues={{
            name: authUser.username || "",
            email: authUser.email || "",
            message: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
          validateOnMount
          innerRef={formikRef}
        >
          {({ values, handleSubmit }) => (
            <Form style={styles.form}>
              <div style={styles.inputsContainer}>
                <FormItem name="name" style={styles.nameInput}>
                  <Input
                    placeholder="Seu nome"
                    name="name"
                    value={values.name}
                    required
                    readOnly
                  />
                </FormItem>
                <FormItem name="email" style={styles.emailInput}>
                  <Input
                    placeholder="Seu e-mail"
                    name="email"
                    value={values.email}
                    type="email"
                    required
                    readOnly
                  />
                </FormItem>
              </div>
              <FormItem name="message" style={styles.messageInput}>
                <TextArea
                  placeholder="Sua mensagem"
                  name="message"
                  value={values.message}
                />
              </FormItem>
              <FormFooterContainer>
                <Button
                  backgroundColor={Colors.white}
                  color={Colors.primary}
                  onClick={handleSubmit}
                >
                  Enviar
                </Button>
              </FormFooterContainer>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
}

const styles = {
  title: { color: Colors.white, fontSize: "2rem" },
  subTitle: { color: Colors.white, fontSize: "1.2rem" },
  row: { justifyContent: "space-between", marginTop: "30px" },
  icons: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#fff",
  },
  nameInput: {
    marginRight: "10px",
    marginBottom: "0",
    flex: 1,
    textAlign: "center",
  },
  emailInput: {
    margin: 0,
    flex: 1,
    marginBottom: "0",
    textAlign: "center",
  },
  messageInput: {
    marginBottom: "0",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
    flex: 1,
  },
};
