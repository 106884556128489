import React from "react";
import { useHistory } from "react-router-dom";
import { Colors, Metrics } from "../../configs";
import { Text, Whitespace } from "../../common";

const styles = {
  container: {
    marginBottom: Metrics.spacing.md,
    width: "100%",
    cursor: "pointer",
  },
  touchable: { borderRadius: Metrics.defaultRadius },
  surface: {
    display: "flex",
    flexDirection: "row",
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 1px 7px #cacaca",
    height: 55,
    alignItems: "center",
    padding: Metrics.spacing.sm,
    paddingLeft: Metrics.spacing.md,
    backgroundColor: Colors.white,
  },
  image: { width: 25, resizeMode: "contain" },
};

const BankSlipOption = ({ bso }) => {
  const history = useHistory();
  return (
    <div style={styles.container} onClick={bso.onClick}>
      <div style={styles.touchable}>
        <div style={styles.surface}>
          {bso.img && <img src={bso.img} style={styles.image} alt="" />}
          <Whitespace width={Metrics.spacing.md} />
          <Text size={Metrics.fontSize.xxsm} color={Colors.secondary}>
            {bso.title}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BankSlipOption;
