import styled, { css } from "styled-components";
import { Input as InputAntd } from "formik-antd";
import { Colors } from "configs";

export const StyledInput = styled(InputAntd)`
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  box-shadow: none !important;
  padding: 15px;

  .ant-input-prefix {
    color: ${Colors.primary};
    font-size: 25px;
    padding-right: 20px;
  }

  :hover {
    border-color: ${Colors.primary};
  }

  ${({ disabledNoColor }) =>
    disabledNoColor &&
    css`
      :disabled {
        background-color: #fff;
      }
    `}
`;

export const StyledInputPassword = styled(InputAntd.Password)`
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  box-shadow: none !important;
  padding: 15px;

  .ant-input-prefix {
    color: ${Colors.primary};
    font-size: 25px;
    padding-right: 20px;
  }
  .ant-input-suffix {
    font-size: 25px;
    > span {
      color: ${Colors.primary};
    }
  }

  :hover {
    border-color: ${Colors.primary};
  }

  ${({ disabledNoColor }) =>
    disabledNoColor &&
    css`
      :disabled {
        background-color: #fff;
      }
    `}
`;
