import React, { useEffect } from 'react';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import CourseForm from '../Form';
import { createUserRequest } from '../../../../services/user';
import useFetch from '../../../../hooks/useFetch';
import { Metrics } from '../../../../configs';
import TopicForm from '../Form';
import BackofficeUsers from '../index';
import UserForm from '../Form';
import { createParishRequest } from '../../../../services/parish';
import ParishForm from '../Form';

const BackofficeParishesCreate = () => {
  const history = useHistory();
  const { goBack } = history;
  const initialValues = {
    nm_paroquia: '',
    comarca_id: ''
  };
  const [{ isFetching, data }, createUser] = useFetch(
    createParishRequest,
    '',
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Criação de Usuário">
      <Spin spinning={isFetching}>
        <ParishForm
          initialValues={initialValues}
          handleSubmitForm={createUser}
          handleCancel={goBack}
        />
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeParishesCreate;
