import styled, { css } from "styled-components";
import { Colors, Metrics } from "configs";
import { Collapse as CollapseAntd, Input as InputAntd, Tabs } from 'antd';
import { Scrollbars } from "react-custom-scrollbars-2";

const {TabPane} = Tabs;

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0;
  height: 100%;

  .last-topic {
    margin-bottom: 15px;
  }

  > div {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #dbdbdb;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${Colors.secondary};
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${Colors.secondary};
    }
  }
`;

export const Collapse = styled(CollapseAntd)`
  border: none;
  background-color: #fff;
  box-shadow: 1px 2px 2px #00000029;
  border-radius: 10px;

  .ant-collapse-header {
    border-radius: 10px !important;
    border: none;
    color: ${Colors.primary} !important;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
  }

  .ant-collapse-item-active .ant-collapse-header {
    border-radius: 12px !important;
    border: none;
    cursor: initial;
  }

  .ant-collapse-content-active {
    transition: 0.5s;
    border-top: 1px solid ${Colors.primary} !important;
  }

  .ant-collapse-item {
    margin-bottom: 20px;
    border: none;
  }

  .ant-collapse-content {
    transition: 0.5s;
    border-radius: 0 0 12px 12px !important;
    border: none;
  }

  .ant-collapse-content-box {
    padding: 20px 35px;
    color: #0c0c0c;
  }
`;

export const TextArea = styled(InputAntd.TextArea)`
  padding: 15px;
  border-radius: 10px;
  color: ${Colors.secondary};
  border: 1px solid ${Colors.primary} !important;
  min-height: 110px !important;
  resize: none;
  font-weight: 300;

  ::placeholder {
    font-size: 15px;
    color: ${Colors.secondary};
    font-weight: 300;
  }

  :focus {
    box-shadow: 0 0 0 2px rgb(74 179 169 / 22%);
  }

  :disabled {
    color: ${Colors.secondary};
    cursor: initial;
  }
`;

export const UserComment = styled.div`
  padding: 15px;
  background-color: #3eb3a914;
  border-radius: 10px;
  color: ${Colors.secondary};
  border: 1px solid ${Colors.primary};
  min-height: 75px;
  resize: none;
  font-weight: 300;
  margin-left: 9px;
  flex: 1;
`;

export const TrainingTitle = styled.div`
  display: flex;
  position: relative;
  margin-top: 15px;

  .circle {
    content: "";
    position: absolute;
    height: 15px;
    width: 15px;
    left: -17px;
    background-color: ${({ viewed, currentTraining }) =>
      viewed && !currentTraining ? Colors.primary : Colors.white};
    border-radius: 50%;
    top: 2px;
    bottom: 0;
    z-index: 1;
    ${({ viewed }) =>
      !viewed &&
      css`
        border: 1px solid ${Colors.secondary};
      `}
    ${({ currentTraining }) =>
      currentTraining &&
      css`
        border: 1px solid ${Colors.greenCheck};
        height: 18px;
        width: 18px;
        left: -18px;
        padding: 2px;

        > div {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: ${Colors.greenCheck};
        }
      `}
  }

  .circle-guide {
    width: 1px;
    height: ${({ last }) => (last ? "10px" : "40px")};
    border-left: 1px solid
      ${({ viewed }) => (viewed ? Colors.primary : Colors.secondary)};
    position: absolute;
    left: -10px;
    top: 2px;
  }

  > .ant-typography {
    margin: 0 0 0 10px;
  }
`;

export const TrainingTitleContainer = styled.div`
  position: relative;

  > div {
    margin-left: 20px;
    cursor: pointer;
  }
`;

export const PlayerContainer = styled.div`
  flex: 2;
  width: 100%;
  height: 100%;
  @media (max-width: 1024px) {
    flex: 2;
  }
`;

export const ScrollBars = styled(Scrollbars)`
  height: 100%;
  @media (max-width: 800px) {
    position: initial !important;
    > div {
      position: initial !important;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;

  @media (max-width: 800px) {
    flex-direction: column;

    > div {
      margin-left: 0 !important;
      margin-bottom: 2vw !important;
    }
  }
`;

export const TabsStyled = styled(Tabs)`
  background-color: ${Colors.white};
  padding: ${Metrics.spacing.md};
  border-radius: ${Metrics.defaultRadius};
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${Colors.primary};
  }
  .ant-tabs-ink-bar {
    background-color: ${Colors.primary};
  }
  .ant-tabs-tab {
    &:hover {
      color: ${Colors.primary};
    }
  }
`;

export const TabPaneStyled = styled(TabPane)`
`;
