import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteCourseRequest, findCoursesRequest } from 'services/course';
import useFetch from '../../../hooks/useFetch';
import NoData from 'common/NoData';
import { Dropdown, Menu, Table } from 'antd';
import { AiOutlineMore } from 'react-icons/ai';
import PageTemplate from '../../../template/PageTemplate';
import { Constants, Modal } from '../../../utils';
import { deleteUserRequest } from '../../../services/user';
import { findTopicsRequest } from '../../../services/topics';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: '100%'
  }
};

const BackofficeTopics = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const [{ data }, fetch] = useFetch(findTopicsRequest, "", false, [], null);
  const [
    { isFetching, data: deleteData },
    deleteUser,
  ] = useFetch(deleteCourseRequest, '', false);
  const menu = useCallback(
    record => {
      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              history.push(`/backoffice/topic/edit/${record.id}`);
            }}>
            Editar
          </Menu.Item>
          <Menu.Item
            key="1"
            danger
            onClick={() =>
              Modal.openDeleteModal({
                description: (
                  <span>
                    Deseja realmente deletar o tópico
                    <strong> {record.ds_titulo}</strong>?
                  </span>
                ),
                onOk: () => deleteUser(record.id),
                okText: 'Sim',
                cancelText: 'Não',
              })
            }>
            Excluir
          </Menu.Item>
        </Menu>
      );
    },
    [history]
  );
  const columns = [
    {
      title: 'Título',
      dataIndex: 'ds_titulo',
      key: 'ds_titulo',
    },
    {
      title: 'Descrição',
      dataIndex: 'ds_descricao',
      key: 'ds_descricao',
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => (
        <span>{record.active ? 'Sim' : 'Não'}</span>
      )
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
              <Dropdown
                overlay={menu(record)}
                placement="bottomRight"
                trigger={['click']}>
                <AiOutlineMore size={25} style={{ cursor: 'pointer' }} />
              </Dropdown>
            </span>
      )
    }
  ];

  const handleFindUsers = useCallback(
    page => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect( () => handleFindUsers(1), []);

  return (
    <PageTemplate title='Tópicos' backButton={false} newButton={{
      label: 'Novo Tópico',
      onClick: () => history.push('/backoffice/topic/create'),
    }}>
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
};

export default BackofficeTopics;
