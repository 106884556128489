import * as Excel from "exceljs";
import Moment from "moment";

export async function generateReportXLSX(data) {
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet("Relatório Cursos");

  const maxValue = Math.max(...data.map((el) => el["value"] && el["value"].length));
  const maxStatus = Math.max(...data.map((el) => el["Status"] && el["Status"].length));
  const maxCpf = Math.max(...data.map((el) => el["user_cpf"] && el["user_cpf"].length));
  const maxCity = Math.max(...data.map((el) => el["user_city"] && el["user_city"].length));
  const maxFone = Math.max(...data.map((el) => el["user_phone"] && el["user_phone"].length));
  const maxDate = Math.max(...data.map((el) => el["created_at"] && el["created_at"].length));
  const maxEmail = Math.max(...data.map((el) => el["user_email"] && el["user_email"].length));
  const maxUsername = Math.max(...data.map((el) => el["user_name"] && el["user_name"].length));
  const maxTopic = Math.max(...data.map((el) => el["topico_name"] && el["topico_name"].length));
  const maxComarca = Math.max(...data.map((el) => el["comarca_name"] && el["comarca_name"].length));
  const maxCourseName = Math.max(...data.map((el) => el["course_name"] && el["course_name"].length));
  const maxBillingType = Math.max(...data.map((el) => el["billingType"] && el["billingType"].length));
  const maxDescription = Math.max(...data.map((el) => el["description"] && el["description"].length));
  const maxParoquia = Math.max(...data.map((el) => el["paroquia_name"] && el["paroquia_name"].length));
  const maxProgress = Math.max(...data.map((el) => el["course_progress"] && el["course_progress"].length));
  const maxCode = Math.max(...data.map((el) => el["codigo_promocional"] && el["codigo_promocional"].length));
  const maxDiocese = Math.max(...data.map((el) => el["diocese_name"] && el["diocese_name"].length));

  worksheet.columns = [
    { header: "Nome ", key: "user_name", width: maxUsername || 12 },
    { header: "Email ", key: "user_email", width: maxEmail || 12 },
    { header: "Fone ", key: "user_phone", width: maxFone || 12 },
    { header: "Cidade ", key: "user_city", width: maxCity || 12 },
    { header: "CPF ", key: "user_cpf", width: maxCpf || 12 },
    { header: "Comarca ", key: "comarca_name", width: maxComarca || 12 },
    { header: "Paróquia ", key: "paroquia_name", width: maxParoquia || 12 },
    { header: "Diocese ", key: "diocese_name", width: maxDiocese || 12 },
    { header: "Data ", key: "created_at", width: maxDate || 12 },
    { header: "Curso ", key: "course_name", width: maxCourseName + 5 || 12 },
    { header: "Módulo ", key: "topico_name", width: maxTopic + 5 || 12 },
    { header: "Forma Pagamento ", key: "billingType", width: maxBillingType + 5 || 12 },
    { header: "Status ", key: "status", width: maxStatus + 5 || 12 },
    { header: "Descrição ", key: "description", width: maxDescription + 5 || 12 },
    { header: "Código Promocional ", key: "codigo_promocional", width: maxCode + 5 || 12 },
    { header: "Progresso ", key: "course_progress", width: maxProgress + 5 || 12 },
    { header: "Valor ", key: "value", width: maxValue + 5 || 12 },
  ];

  worksheet.getRow(1).font = { bold: true };

  data.forEach((e) => {
    worksheet.addRow({
      ...e,
      course_progress: `${e.course_progress ? e.course_progress.toFixed(2) : 0}%`,
      value: `R$ ${e.value}`,
      created_at: Moment(e.created_at).format("DD/MM/YYYY")
    });
  });

  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].forEach((i) => {
    worksheet.getColumn(i).alignment = { horizontal: "left" };
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blobData = new Blob([buffer], { type: "application/xlsx" });
  const url = window.URL.createObjectURL(blobData);
  const tempLink = document.createElement("a");
  tempLink.href = url;
  tempLink.setAttribute("download", `relatorio-cursos-${Moment().format("DD/MM/YYYY")}.xlsx`);
  tempLink.click();
}
