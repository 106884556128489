import React from 'react';
import { Colors, Metrics } from '../../configs';
import MobileFrames from '../../assets/images/mobileFrames.png';
import MobileStore from '../../assets/images/mobileStore.png';
import { Typography } from 'antd';
const { Text } = Typography;

export default () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', border: `1px solid ${Colors.primary}`, margin: Metrics.spacingMinimun, padding: Metrics.spacingLG, borderRadius: Metrics.defaultRadius, backgroundColor: Colors.white, boxShadow: '0px 0px 10px #cacaca'}}>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: Metrics.spacingLG}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <img style={{width: '220px'}} src={MobileFrames} />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <img style={{width: '110px'}} src={MobileStore} />
        </div>
      </div>
      <Text style={{fontSize: Metrics.fontSize.sm, textAlign: 'center'}}>A Studium está disponível na sua loja de aplicativos. </Text>
      <Text style={{cursor: 'pointer', fontWeight: 'bolder', fontSize: Metrics.fontSize.lg, textAlign: 'center'}}>Faça o download agora para a melhor experiência!</Text>
    </div>
  );
}
