import React, { createRef, useCallback, useMemo, useState } from "react";
import { Modal, Spin, Tooltip, Typography } from 'antd';
import Slider from "react-slick";
import {
  Container,
  SliderContainer,
  SliderContent,
  TextContainer,
  TitleContainer,
  TitleMark,
  TitleText,
  UserImageContainer,
} from "./styles";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Colors, Metrics } from "configs";
import useFetch from "hooks/useFetch";
import { getAllCoursesBuy } from "services/course";
import CoursePlaceholder from "assets/images/coursePlaceholder.png";
import { useHistory } from 'react-router-dom';
import { useAuthAuthenticated } from '../../../../store/hooks/auth';
import { AnimatedButton, Row, Text, Whitespace } from '../../../../common';
import Filters from '../../../../utils/Filters';
import Moment from 'moment';
import { CourseFeatures } from '../../../../components';
import CourseBgPlaceholder from '../../../../assets/images/courseBg.png';
import useBreakpoint from 'use-breakpoint';
import { Constants } from '../../../../utils';

const { Paragraph } = Typography;

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  variableWidth: true,
};

const colors = ["#b7e4e2", "#d5e4bf", "#f4deb9", "#b7b9ba"];

const Courses = () => {
  const [dataCourseSelected, setDataCourseSelected] = useState(null);
  const [visible, setVisible] = useState(false);
  const authenticated = useAuthAuthenticated();
  const history = useHistory();
  const sliderRef = createRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const { breakpoint } = useBreakpoint(Constants.BREAKPOINTS, 'desktop');
  const [{ data }] = useFetch(getAllCoursesBuy, null, true, []);
  const handleNext = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      const { centerMode, slidesToShow } = sliderRef.current.innerSlider.props;
      const { currentSlide, slideCount } = sliderRef.current.innerSlider.state;
      let canGoNext = true;
      if (centerMode && currentSlide >= slideCount - 1) {
        canGoNext = false;
      } else if (
        slideCount <= slidesToShow ||
        currentSlide >= slideCount - slidesToShow
      ) {
        canGoNext = false;
      }

      if (canGoNext) {
        sliderRef.current.slickNext();
        setCurrentSlide(currentSlide + 1);
      }
    }
  }, [sliderRef]);

  const handleBack = useCallback(() => {
    if (sliderRef && sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
      const { currentSlide } = sliderRef.current.innerSlider.state;
      setCurrentSlide(currentSlide === 0 ? 0 : currentSlide - 1);
    }
  }, [sliderRef, currentSlide]);

  const testimonialsMemo = useMemo(() => {
    return data.map((d, key) => (
      <div key={key}>
        <SliderContent
          style={{
            background: colors[Math.floor(Math.random() * colors.length)],
            position: 'relative'
          }}
        >
          {Moment(new Date()).isBefore(d.dt_inicio) && d.fg_ativo &&
          <span style={{ fontSize: Metrics.fontSize.xsm, position: 'absolute', background: 'white', color: Colors.primary, fontWeight: 'bolder',
            boxShadow: '0px 0px 10px #aaa',
            borderRadius: '8px',
            padding: '5px',
            right: '5%',
            zIndex: 2,
            top: '25%',
            width: '61%',
            textAlign: 'center'}}>Inscrições a partir de {Moment(d.dt_inicio).format("DD/MM/YYYY")}</span>
          }
          {Moment(new Date()).isAfter(d.dt_fim) && d.fg_ativo &&
          <span style={{ fontSize: Metrics.fontSize.xsm, position: 'absolute', background: 'white', color: Colors.error, fontWeight: 'bolder',
            boxShadow: '0px 0px 10px #aaa',
            borderRadius: '8px',
            padding: '5px',
            right: '5%',
            zIndex: 2,
            top: '2%',
            width: '61%',
            textAlign: 'center'}}>Inscrições encerradas</span>
          }
          <Tooltip placement="top" title={d.ds_titulo}>
            <Paragraph
              onClick={ () => {
                if (d.fg_ativo) {
                  setDataCourseSelected(d);
                  setVisible(true);
                }
              }}
              ellipsis={{ rows: 2 }}
              style={{ margin: 0, fontSize: "1.4rem", fontWeight: "bold", cursor: 'pointer' }}
            >
              {d.ds_titulo}
            </Paragraph>
          </Tooltip>
          <div className="white-container">
            <UserImageContainer>
              {/*{!d.image && <FaUserCircle style={{ fontSize: "42px" }} />}*/}
              {!d.ds_imagem && <img src={CoursePlaceholder} alt="img" />}
              {d.ds_imagem && <img style={{
                flex: 1,
                minHeight: "50px",
                minWidth:"50px",
              //  backgroundImage: `url(${d.ds_imagem})`,
                backgroundSize: "cover",
                borderRadius:"50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                marginRight: "1%",
                backgroundPosition: "center",
              }} src={d.ds_imagem} alt="img" />}
            </UserImageContainer>
            <Tooltip placement="top" title={d.ds_resumo}>
              <Paragraph
                ellipsis={{ rows: 5 }}
                style={{ margin: 0, fontSize: "1.1rem" }}
              >
                {d.ds_resumo}
              </Paragraph>
              <Whitespace height={Metrics.spacingMD} />
              {d.fg_ativo &&
                <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                  setDataCourseSelected(d);
                  setVisible(true);
                }}>Saiba mais</span>
              }
            </Tooltip>
          </div>
        </SliderContent>
      </div>
    ));
  }, [data]);

  return (
    <Container>
      <Modal
        title="Detalhes do Curso"
        centered
        visible={visible}
        footer={null}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width='95%'
        style={{borderRadius: Metrics.defaultRadius, overflow: 'hidden', backgroundColor: 'transparent'}}
        bodyStyle={{ ...(dataCourseSelected ? {backgroundImage: `url(${dataCourseSelected.ds_imagem})`} : {backgroundImage: CourseBgPlaceholder}), borderRadius: `0 0 ${Metrics.defaultRadius} ${Metrics.defaultRadius}`, overflow: 'hidden' }}
      >
        {dataCourseSelected ?
          <div>
            <div style={styles.headerBlock}>
              {
                Moment(new Date()).isBetween(dataCourseSelected.dt_inicio, dataCourseSelected.dt_fim) &&
                <div style={styles.priceLine}>
                  <div style={styles.priceBlock}>
                    <div style={styles.flexCol}>
                      <div style={styles.flexRow}>
                        <Text color={Colors.greenCheck} size={Metrics.fontSize.md}>
                          {dataCourseSelected.vl_curso === 0
                            ? "Curso livre"
                            : Filters.convertMoneyTextMask(dataCourseSelected.pr_desconto
                              ? parseInt(dataCourseSelected.vl_curso) -
                              (parseInt(dataCourseSelected.vl_curso) * parseInt(dataCourseSelected.pr_desconto)) / 100
                              : dataCourseSelected.vl_curso)}
                        </Text>
                        <Whitespace width={Metrics.spacing.sm} />
                        {dataCourseSelected.pr_desconto !== 0 && dataCourseSelected.pr_desconto && (
                          <Text
                            color={Colors.secondary}
                            size={Metrics.fontSize.xsm}
                            lineThrough
                          >
                            {dataCourseSelected.vl_curso !== 0 &&
                            Filters.convertMoneyTextMask(dataCourseSelected.vl_curso)}
                          </Text>
                        )}
                      </div>
                      {dataCourseSelected.pr_desconto !== 0 && dataCourseSelected.pr_desconto && (
                        <Text
                          color={Colors.secondary}
                          size={Metrics.fontSize.xsm}
                        >{`Desconto de ${dataCourseSelected.pr_desconto}% - válido até ${Moment(
                          dataCourseSelected.dt_validade_desconto
                        ).format("DD-MM-YYYY")}`}</Text>
                      )}
                    </div>
                    <div>
                      <AnimatedButton
                        onClick={() => {
                          if (authenticated) {
                            history.push(`course-buy/${dataCourseSelected.id}`);
                          } else {
                            window.scrollTo(0, 0);
                            history.push(`login?returnUrl=course-buy/${dataCourseSelected.id}`);
                          }
                        }}>
                        Comprar
                      </AnimatedButton>
                    </div>
                  </div>
                </div>
              }
              <div style={styles.title}>
                <Text
                  color={Colors.white}
                  bold
                  size={Metrics.fontSize.xxlg}
                  text-shadow={Colors.blk}
                  style={{
                    shadowColor: "#000000",
                    textShadow: "2px 1px 1px #000000",
                  }}
                >
                  {dataCourseSelected.ds_titulo}
                </Text>
                <Whitespace height={Metrics.spacing.sm} />
                <Text
                  color={Colors.white}
                  size={Metrics.fontSize.lg}
                  style={{
                    shadowColor: "#000000",
                    textShadow: "2px 1px 1px #000000",
                    // backgroundColor:"#000000",
                    // backgroundColor: "#00000061",
                  }}
                >
                  {dataCourseSelected.ds_resumo}
                </Text>
              </div>
            </div>
            <div style={styles.flexRow}>
              <div style={styles.features}>
                <CourseFeatures data={dataCourseSelected} />
              </div>
              <div style={styles.desc}>
                <Text size={Metrics.fontSize.lg} color={Colors.primary} bold>
                  Descrição
                </Text>
                <Whitespace height={Metrics.spacing.sm} />
                <Text size={Metrics.fontSize.xsm} color={Colors.secondary}>
                  {dataCourseSelected.ds_curso}
                </Text>
              </div>
            </div>
          </div>
        :
          <Spin />
        }
      </Modal>
      <Row responsive style={{width: '100%'}}>
        <TitleContainer>
          <TitleText>Nossos Cursos</TitleText>
          <TitleMark />
          { breakpoint !== 'desktop' &&
            <div style={{ marginTop: '120px', width: '1px' }} />
          }
          { breakpoint === 'desktop' &&
            <TextContainer>
              Expanda seus conhecimentos sobre a Igreja Católica, Teologia, Bíblia,
              Pastorais e Movimentos e muito mais! Com formações práticas que
              ajudarão no dia a dia da sua vida na comunidade.
            </TextContainer>
          }
        </TitleContainer>
        <SliderContainer>
          <div>
            <div style={{ marginBottom: "15px" }}>
              <AiOutlineLeft
                onClick={handleBack}
                style={{
                  ...styles.icon,
                  opacity: currentSlide === 0 ? 0.5 : 1,
                }}
              />
              <AiOutlineRight
                onClick={handleNext}
                style={{
                  ...styles.icon,
                  cursor: "pointer",
                }}
              />
            </div>
            <Slider {...sliderSettings} ref={sliderRef}>
              {testimonialsMemo}
            </Slider>
          </div>
        </SliderContainer>
      </Row>
    </Container>
  );
};

const styles = {
  icon: {
    fontSize: "25px",
    color: Colors.primary,
    cursor: "pointer",
    marginRight: Metrics.spacingSM,
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: Metrics.spacing.lg,
    marginTop: "6vw",
  },
  flexRow: {
    display: "flex",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  priceLine: {
    display: "flex",
    justifyContent: "flex-end",
  },
  priceBlock: {
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 1px 8px gray",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    minWidth: "320px",
    padding: `${Metrics.spacing.lg} ${Metrics.spacing.md}`,
  },
  features: {
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    display: "flex",
    minWidth: "350px",
    margin: Metrics.spacing.sm,
    marginLeft: 0,
  },
  headerBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    maxWidth: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    shadowColor: Colors.blk,
    textShadow: "1px 2px 2px #00000029",
  },
  desc: {
    display: "flex",
    padding: Metrics.spacing.md,
    flexDirection: "column",
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    margin: Metrics.spacing.sm,
    marginRight: 0,
    width: "100%",
  },
};

export default Courses;
