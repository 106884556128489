import React, { useEffect, useState } from "react";

import { Spin } from "antd";
import Moment from 'moment';
import { Text, Whitespace } from "common";
import { useParams, useHistory } from "react-router-dom";

import { getCourseUserProgressRequest } from "services/course";
import useFetch from "hooks/useFetch";
import CourseBgPlaceholder from "assets/images/courseBg.png";
import { Colors, Metrics } from "configs";

import ProgressBar from "../../../components/PercentageBar";
import { Accordion } from "../../../components";
import CourseSectionHeader from "../../../components/CourseSectionHeader";
import CourseSectionContent from "../../../components/CourseSectionContent";
import PageTemplate from "template/PageTemplate";
import { useAuthUser } from "store/hooks/auth";
import { Modal } from "utils";
import { getCourseModules } from "services/courseModules";

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // backgroundImage: `url(${CourseBgPlaceholder})`,
    height: "100%",
    width: "100%",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
    color: Colors.secondary,
  },
  headerNavigation: {
    position: "absolute",
    width: "100%",
    top: 0,
    display: "flex",
    padding: Metrics.spacing.md,
    justifyContent: "space-between",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: Metrics.spacing.lg,
  },
  flexRow: {
    display: "flex",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
  },
  desc: {
    display: "flex",
    padding: Metrics.spacing.md,
    flexDirection: "column",
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    margin: Metrics.spacing.sm,
  },
  priceLine: {
    display: "flex",
    justifyContent: "flex-end",
  },
  priceBlock: {
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    justifyContent: "space-between",
    display: "flex",
    width: "480px",
    padding: `${Metrics.spacing.lg} ${Metrics.spacing.md}`,
  },
  features: {
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    display: "flex",
    minWidth: "350px",
    margin: Metrics.spacing.sm,
  },
  headerBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const CourseProgress = () => {
  const { id } = useParams();

  const user = useAuthUser();
  const history = useHistory();

  const [{ data }] = useFetch(
    getCourseUserProgressRequest,
    id,
    true,
    null,
    null
  );

  const [idsCoursesModules, setIdsCoursesModules] = useState([]);

  const handleCertificate = () => {
    if (!user?.admin && data?.curso?.pr_concluido < 100) {
      Modal.showAlert({
        text: "Finalize o curso! ",
      })
      return;
    };

    history.push(`/course-certificate/${id}`)
  };

  const disabledTopic = (topic) => {
    if (!data?.curso?.has_module) {
      return false
    };

    if (data?.curso?.has_module && (!topic.dt_start || Moment(new Date()).isBefore(topic.dt_start))) {
      return true;
    }

    if (data?.curso?.has_module && idsCoursesModules.includes(topic.id)) {
      return false;
    }


    return false;
  }

  const hasBuy = (topic) => {
    if (data?.curso?.has_module && !idsCoursesModules.includes(topic.id) && Moment(new Date()).isAfter(topic.dt_start)) {
      return true;
    }

    return false;
  }

  const getCourseModuleByUser = async () => {
    try {
      const res = await getCourseModules(id);
      const _res = res.data.map(item => item.topico_id);

      setIdsCoursesModules(_res);
    } catch (error) {
      console.log(138, error);
    }
  }

  const handleBuyModule = (topic_id) => {
    history.push(`/course-buy/${id}/${topic_id}`);
  }

  useEffect(() => {
    if (data && data?.curso?.has_module) {
      getCourseModuleByUser();
    }
  }, [data]);


  return (
    <PageTemplate>
      {data ? (
        <div style={styles.container}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div
              style={{
                flex: 1,
                minHeight: "450px",
                //  backgroundImage: `url(${CourseBgPlaceholder})`,
                backgroundImage: data ? `url(${data.curso.ds_imagem})` : `url(${CourseBgPlaceholder})`,
                backgroundSize: "cover",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                borderRadius: Metrics.defaultRadius,
                marginRight: "1%",
                backgroundPosition: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: Metrics.spacing.md,
                }}
              >
                <Text
                  color={Colors.white}
                  size={Metrics.fontSize.lg}
                  bold
                  style={{
                    shadowColor: "#000000",
                    textShadow: "2px 1px 1px #000000",
                  }}
                >
                  {data.curso.ds_titulo}
                </Text>
                <Whitespace height={Metrics.spacing.sm} />
                <Text
                  color={Colors.white}
                  size={Metrics.fontSize.sm}
                  style={{
                    shadowColor: "#000000",
                    textShadow: "2px 1px 1px #000000",
                  }}
                >
                  {data.curso.ds_resumo}
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "white",
                  boxShadow: "1px 2px 2px #00000029",
                  borderRadius: 10,
                  padding: Metrics.spacing.md,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Text bold size={Metrics.fontSize.xsm}>
                    Meu progresso
                  </Text>

                  {
                    (user?.admin || data?.curso?.has_certificate) && (
                      <Text
                        color={Colors.secondary}
                        size={Metrics.fontSize.xsm}
                        style={{ cursor: "pointer" }}
                        onClick={handleCertificate}
                      >
                        baixar certificado
                      </Text>
                    )
                  }

                </div>
                <ProgressBar
                  width="90%"
                  heightSize="md"
                  pct={data.curso.pr_concluido}
                  fontSize={Metrics.fontSize.xxsm}
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                height: "100%",
                minHeight: "450px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                borderRadius: Metrics.defaultRadius,
                marginLeft: "1%",
              }}
            >
              {data && data.topico.map((ds, i) => (
                <Accordion
                  key={i}
                  hasBuy={hasBuy(ds.topicos)}
                  disabled={disabledTopic(ds.topicos)}
                  handleBuyModule={() => handleBuyModule(ds?.topico_id)}
                  content={<CourseSectionContent ds={ds} courseId={id} />}
                  header={
                    <CourseSectionHeader ds={ds} disabled={disabledTopic(ds.topicos)} hasBuy={hasBuy(ds.topicos)} />
                  }
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Spin />
      )}
      <div style={{ marginTop: Metrics.spacing.xlg, textAlign: "center" }}>
        <Text size={Metrics.fontSize.sm} color={Colors.secondary}>
          Escolha um conteúdo de uma seção para começar
        </Text>
      </div>
    </PageTemplate>
  );
};

export default CourseProgress;
