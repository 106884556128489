import * as Yup from "yup";

export const ContactValidationSchema = Yup.object().shape({
  name: Yup.string().trim().nullable().required("O campo nome é obrigatório."),
  email: Yup.string()
    .trim()
    .nullable()
    .required("O campo e-mail é obrigatório.")
    .email("O e-mail informado não é válido."),
  message: Yup.string()
    .trim()
    .nullable()
    .required("O campo mensagem é obrigatório."),
});
