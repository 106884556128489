import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from '../../../../hooks/useFetch';
import { Button, Spin, Table } from 'antd';
import PageTemplate from '../../../../template/PageTemplate';
import { Constants } from '../../../../utils';
import { completeUsersTraining, findTrainingByIdRequest, findUsersTrainingById } from 'services/training';

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: '100%'
  }
};

const PersonTrainingStudents = () => {
  const history = useHistory();
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  if (!id) {
    history.goBack();
  }

  const [{ isFetching: isFetchingTraining, data: training }] = useFetch(findTrainingByIdRequest, id, true, null);

  const [{ data }, fetch] = useFetch(findUsersTrainingById, id, false, [], null)

  const dataTable = data.map((item, index) => ({key: index+'', ...item}))

  const columns = [
    {
      title: 'Aluno',
      dataIndex: 'username'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleFindTranings = useCallback(
    page => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  const [{ isFetching: isFetchingComplete }, completeTranining] = useFetch(completeUsersTraining, "", false, null, () => history.goBack(), () => handleFindTranings(1));

  useEffect( () => handleFindTranings(1), []);

  const start = () => {
    completeTranining({
      "users_id": dataTable.filter(item => selectedRowKeys.includes(item.key)).map(item => item.id),
      "treinamento_id": training.id
    })
    setSelectedRowKeys([])
  };

  return (
    <PageTemplate title={`Alunos aguardando conclusão${training ? ' - '+training.ds_titulo : ''}`} backButton background>
      <div style={{ marginBottom: 15 }}>
        <Button type="primary" onClick={start} disabled={!hasSelected} loading={isFetchingComplete}>
          Marcar como concluido
        </Button>

        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selecionado ${selectedRowKeys.length} alunos` : ''}
        </span>
      </div>

      <Spin spinning={isFetchingTraining}>
        <Table style={styles.table} rowSelection={{ selectedRowKeys, onChange: onSelectChange }} columns={columns} dataSource={dataTable} />
      </Spin>
    </PageTemplate>
  );
};

export default PersonTrainingStudents;
