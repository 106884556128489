import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { Dropdown, Menu, Table } from "antd";
import { AiOutlineMore } from "react-icons/ai";
import PageTemplate from "template/PageTemplate";
import { Constants, Modal } from "utils";
import {
  findAllFrequentlyAskedQuestionsRequest,
  activateFrequentlyAskedQuestionRequest,
  inactivateFrequentlyAskedQuestionRequest,
} from "services/frequentlyAskedQuestion";

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: "100%",
  },
};

export default function BackofficeFrequentlyAskedQuestion() {
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const [{ data }, fetch] = useFetch(
    findAllFrequentlyAskedQuestionsRequest,
    "",
    false,
    [],
    null
  );
  const [, inactivateRequest] = useFetch(
    inactivateFrequentlyAskedQuestionRequest,
    "",
    false,
    null,
    null,
    fetch
  );
  const [, activateRequest] = useFetch(
    activateFrequentlyAskedQuestionRequest,
    "",
    false,
    null,
    null,
    fetch
  );
  const menu = useCallback(
    (record) => {
      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              history.push(
                `/backoffice/frequently-asked-question/edit/${record.id}`
              );
            }}
          >
            Editar
          </Menu.Item>
          {!record.fg_ativo && (
            <Menu.Item
              key="0"
              onClick={() => {
                Modal.openDeleteModal({
                  description: (
                    <span>
                      Deseja realmente ativar a pergunta frequente
                      <strong> {record.ds_titulo}</strong>?
                    </span>
                  ),
                  onOk: () => activateRequest(record.id),
                  okText: "Sim",
                  cancelText: "Não",
                });
              }}
            >
              Ativar
            </Menu.Item>
          )}
          {record.fg_ativo && (
            <Menu.Item
              key="1"
              danger
              onClick={() =>
                Modal.openDeleteModal({
                  description: (
                    <span>
                      Deseja realmente inativar a pergunta frequente
                      <strong> {record.ds_titulo}</strong>?
                    </span>
                  ),
                  onOk: () => inactivateRequest(record.id),
                  okText: "Sim",
                  cancelText: "Não",
                })
              }
            >
              Inativar
            </Menu.Item>
          )}
        </Menu>
      );
    },
    [history]
  );
  const columns = [
    {
      title: "Título",
      dataIndex: "ds_titulo",
      key: "ds_titulo",
    },
    {
      title: "Descricao",
      dataIndex: "ds_descricao",
      key: "ds_descricao",
    },
    {
      title: "Ativo",
      dataIndex: "fg_ativo",
      key: "fg_ativo",
      render: (text, record) => <span>{record.fg_ativo ? "Sim" : "Não"}</span>,
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  const handleFind = useCallback(
    (page) => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect(() => handleFind(1), []);

  return (
    <PageTemplate
      title="Perguntas Frequentes"
      backButton={false}
      newButton={{
        label: "Nova Pergunta Frequente",
        onClick: () =>
          history.push("/backoffice/frequently-asked-question/create"),
      }}
    >
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
}
