import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  markTrainingCompletedRequest: ["trainingId"],
  markTrainingCompletedFinished: [],
});

const INITIAL_STATE = {
  error: null,
  isFetching: false,
};

const defaultRequest = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: true,
});

const defaultFinished = (state = INITIAL_STATE) => ({
  ...state,
  isFetching: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.MARK_TRAINING_COMPLETED_REQUEST]: defaultRequest,
  [Types.MARK_TRAINING_COMPLETED_FINISHED]: defaultFinished,
});
