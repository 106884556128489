import { Table as ATable } from "antd";
import FormInputDate from "common/FormInputDate";
import Flex from "common/Flex";
import Row from "common/Row";
import Col from "common/Col";
import FormInputText from "common/FormInputText";
import FormTree from "common/FormTree";
import FormInputPassword from "common/FormInputPassword";
import FormDropdown from "common/FormDropdown";
import Whitespace from "common/Whitespace";
import FormTextArea from "common/FormTextArea";
import FormSlider from "common/FormSlider";
import FormRadioV2 from "common/FormRadioV2";
import Table from "common/Table";
import FormButtons from "common/FormButtons";
import AnimatedButton from "common/AnimatedButton";
import Text from "common/Text";
import Button from "common/Button";
import CourseCard from "components/CourseCard";
import CourseFeatures from "components/CourseFeatures";
import CourseCardSm from "components/CourseCardSm";
import CertificateCardSm from "components/CertificateCardSm";
import Accordion from "components/Accordion";
import CourseSectionContent from "components/CourseSectionContent";
import CourseSectionHeader from "components/CourseSectionHeader";
import BankSlipOption from "components/BankSlipOption";
import DownloadOnStore from "components/DownloadOnStore";
import AlternativeHandler from "components/AlternativeHandler";

const { Column } = ATable;

export {
  Col,
  Row,
  AlternativeHandler,
  DownloadOnStore,
  BankSlipOption,
  CourseSectionHeader,
  CourseSectionContent,
  Accordion,
  CourseCard,
  CourseCardSm,
  CourseFeatures,
  Flex,
  FormButtons,
  FormInputText,
  FormTree,
  FormInputPassword,
  Column,
  FormDropdown,
  Whitespace,
  FormTextArea,
  FormSlider,
  FormRadioV2,
  FormInputDate,
  Table,
  AnimatedButton,
  Text,
  Button,
  CertificateCardSm,
};
