import styled from "styled-components";
import { Colors, Metrics } from "configs";
import { Typography } from "antd";

const { Text } = Typography;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 360px;

  @media (max-width: 1024px) {
    min-height: 280px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;

  @media (max-width: 1024px) {
    flex: 3;
  }
`;

export const TitleText = styled(Text)`
  color: ${Colors.primary};
  font-size: ${Metrics.fontSize.lg};
  font-weight: 800;
`;

export const TextContainer = styled.div`
  margin-top: 50px;
  max-width: 90%;
  font-size: 1.1rem;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const TitleMark = styled.div`
  border: 2px solid #dca039;
  width: 70px;
  margin-top: 15px;
`;

export const SliderContainer = styled.div`
  flex: 4;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  > div {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-100px);
  }
`;

export const SliderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  min-height: 300px;
  border-radius: 30px;
  margin-right: 50px;
  padding: 40px 20px 20px 20px;

  .white-container {
    flex: 1;
    background-color: #fff;
    width: 100%;
    height: 100%;
    margin-top: 35px;
    border-radius: 12px;
    position: relative;
    padding: 40px 10px 10px;
  }

  @media (max-width: 1024px) {
    height: 250px;

    .white-container .ant-typography-ellipsis-multiple-line {
      -webkit-line-clamp: 4 !important;
    }
  }
`;

export const UserImageContainer = styled.div`
  width: 60px;
  height: 60px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: -15px;
  border-radius: 50%;
  padding: 4px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
