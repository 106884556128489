import React from "react";
import { Colors, Metrics } from "configs";
import { Text, Whitespace } from "common";
import {
  AiOutlineCalendar,
  AiOutlineFileText,
  AiOutlineTablet,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { FiMessageSquare } from "react-icons/fi";
import { BsPencil } from "react-icons/bs";

const styles = {
  surface: {
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
  },
  iconTextFeature: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: Metrics.spacing.md,
  },
};

const courseFeatures = [
  {
    key: "fg_exercicio",
    icon: <BsPencil color={Colors.primary} size={Metrics.fontSize.sm} />,
    title: "Exercícios",
  },
  {
    key: "fg_reflexoes",
    icon: <FiMessageSquare color={Colors.primary} size={Metrics.fontSize.sm} />,
    title: "Reflexões",
  },
  {
    key: "fg_mobile",
    icon: <AiOutlineTablet color={Colors.primary} size={Metrics.fontSize.sm} />,
    title: "Acesse pelo celular, tablet e computador",
  },
  {
    key: "fg_certificado",
    icon: (
      <AiOutlineFileText color={Colors.primary} size={Metrics.fontSize.sm} />
    ),
    title: "Certificado de conclusão",
  },
];

export default ({ data }) => {
  return (
    <div style={styles.surface}>
      <Text
        textAlign="left"
        bold
        size={Metrics.fontSize.lg}
        color={Colors.primary}
      >
        Este curso inclui
      </Text>
      <div style={styles.iconTextFeature}>
        <AiOutlineVideoCamera
          color={Colors.primary}
          size={Metrics.fontSize.sm}
        />
        <Whitespace width={Metrics.spacing.sm} />
        <Text
          textAlign="left"
          size={Metrics.fontSize.xsm}
          color={Colors.secondary}
        >
          {data.ds_hrs_video}
        </Text>
      </div>

      <div style={styles.iconTextFeature}>
        <AiOutlineCalendar color={Colors.primary} size={Metrics.fontSize.sm} />
        <Whitespace width={Metrics.spacing.sm} />
        <Text
          textAlign="left"
          size={Metrics.fontSize.xsm}
          color={Colors.secondary}
        >
          {data.ds_tempo_acesso}
        </Text>
      </div>
      {courseFeatures
        .filter((c) => data[c.key])
        .map((cf, i) => (
          <div key={i} style={styles.iconTextFeature}>
            {cf.icon}
            <Whitespace width={Metrics.spacing.sm} />
            <Text
              textAlign="left"
              size={Metrics.fontSize.xsm}
              color={Colors.secondary}
            >
              {cf.title}
            </Text>
          </div>
        ))}
    </div>
  );
};
