import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Creators } from "../reducers/course";

export const useCourseMarkTrainingCompleted = () => {
  const dispatch = useDispatch();
  return useCallback(
    (trainingId) => {
      dispatch(Creators.markTrainingCompletedRequest(trainingId));
    },
    [dispatch]
  );
};
