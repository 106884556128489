import { useFormikContext } from 'formik';
import { FormItem, Input } from 'formik-antd';

const { TextArea } = Input;

const FormTextArea = ({
  label,
  name,
  value,
  rows = 4,
  type,
  onChange: onChangeParam,
  required,
  showSpinner,
  button,
  icon,
  iconColor,
  disabled,
  maxLength,
  placeholder,
  showValidateSuccess,
  autoFocus,
  ...rest
}) => {
  const { getFieldMeta } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const onChange = newValue => {
    if (!disabled) {
      if (onChangeParam) {
        onChangeParam(newValue);
      }
    }
  };

  return (
    <FormItem
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}>
      <span style={{ color: error && touched && '#ff4d4f' }}>
        {label && (required ? `${label} *` : label)}
      </span>
      <TextArea
        rows={rows}
        mode="outlined"
        value={value}
        onChange={e => onChange(e)}
        autoFocus={autoFocus}
        placeholder={!label ? placeholder : ''}
        maxLength={maxLength}
        name={name}
        required={required}
        {...rest}
      />
    </FormItem>
  );
};

export default FormTextArea;
