import { UserOutlined, DiffOutlined } from "@ant-design/icons";
import {
  BsPeople,
} from "react-icons/bs";
import {
  FaChurch,
} from "react-icons/fa";
import {
  IoSchool,
} from "react-icons/io5";
import {
  MdPlace,
} from "react-icons/md";

const AdministrativeMenuStructure = [
  {
    title: "Cursos",
    route: "/backoffice/course",
    icon: <IoSchool />,
  },
  {
    title: "Usuários",
    route: "/backoffice/user",
    icon: <UserOutlined />,
  },
  {
    title: "Comarcas",
    route: "/backoffice/county",
    icon: <MdPlace />,
  },
  {
    title: "Paróquias",
    route: "/backoffice/parish",
    icon: <FaChurch />,
  },
  {
    title: "Estado Civil",
    route: "/backoffice/marital-status",
    icon: <BsPeople />,
  },
  {
    title: "Perguntas Frequentes",
    route: "/backoffice/frequently-asked-question",
    icon: <UserOutlined />,
  },
  {
    title: "Aulas Presenciais",
    route: "/backoffice/person-training",
    icon: <IoSchool />,
  },
  {
    title: "Aulas por Módulos",
    route: "/backoffice/course-modules",
    icon: <IoSchool />,
  },
  {
    title: "Relatório Progresso do Aluno",
    route: "/backoffice/student-report",
    icon: <DiffOutlined />,
  },
  {
    title: "Relatório Cursos",
    route: "/backoffice/course-report",
    icon: <DiffOutlined />,
  },
];

export default AdministrativeMenuStructure;
