import { ReactComponent as AmexIcon } from "assets/icons/bandeiras/amex.svg";
import { ReactComponent as DinersIcon } from "assets/icons/bandeiras/diners.svg";
import { ReactComponent as DiscoverIcon } from "assets/icons/bandeiras/discover.svg";
import { ReactComponent as EloIcon } from "assets/icons/bandeiras/elo.svg";
import { ReactComponent as HipercardIcon } from "assets/icons/bandeiras/hipercard.svg";
import { ReactComponent as JcbIcon } from "assets/icons/bandeiras/jcb.svg";
import { ReactComponent as MastercardIcon } from "assets/icons/bandeiras/master.svg";
import { ReactComponent as VisaIcon } from "assets/icons/bandeiras/visa.svg";

export function getCardFlag(cardnumber) {
  if (!cardnumber || cardnumber.length === 0) return false;
  cardnumber = cardnumber.replace(/[^0-9]+/g, "");

  let cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^5[1-5][0-9]{14}/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
    amex: /^3[47][0-9]{13}/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
    elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}/,
    aura: /^(5078\d{2})(\d{2})(\d{11})$/,
  };

  for (var flag in cards) {
    if (cards[flag].test(cardnumber)) {
      return flag;
    }
  }

  return false;
}

export function getIconByFlag(width, height, flag) {
  if (flag && flag === "mastercard") {
    return <MastercardIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "diners") {
    return <DinersIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "amex") {
    return <AmexIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "discover") {
    return <DiscoverIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "jcb") {
    return <JcbIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "elo") {
    return <EloIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "visa") {
    return <VisaIcon style={{ width: width, height: height }} />;
  }
  if (flag && flag === "hipercard") {
    return <HipercardIcon style={{ width: width, height: height }} />;
  }
  return null;
}

export function CardIcon({ numero, bandeira, width = 25, height = 25 }) {
  if (!numero || numero.length === 0) return null;
  const flag = getCardFlag(numero);
  return getIconByFlag(width, height, flag || bandeira);
}

const styles = {
  cardStyle: {
    fontSize: 25,
    width: 25,
    height: 25,
  },
};
