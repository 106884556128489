import { Collapse } from "common";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { getChargeByUser, validatePromotionCodeRequest } from "services/course";
import { Button, Modal, Select } from "antd";
import Input from "common/InputCustom";
import { Colors } from "configs";
import { generateChargeRequest } from "services/payment";
import { findTopicsActivedByCourseRequest } from "services/topics";
import { Toast } from "../../../../../../utils";

export const CreateChargeUser = ({ idUser }) => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [courseSelected, setCourseSelected] = useState();
  const [moduleSelected, setModuleSelected] = useState([]);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const [initialValues, setInitialValues] = useState({
    userId: idUser,
    codPromo: null,
    cursoId: undefined,
    topico_id: undefined,
    qtdParcela: undefined,
    tipoCobranca: "BOLETO",
  })

  const getCourses = async () => {
    try {
      setLoading(true);
      const { data } = await getChargeByUser(idUser)

      const newData = data.map(course => {
        return {
          value: course.id,
          label: course.ds_titulo,
          vl_curso: course.vl_curso,
          has_module: course.has_module,
          nu_parcelas_boleto: course.nu_parcelas_boleto,
        }
      });

      setCourses(newData);
    } catch (error) {
      console.log(10, error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateCharge = async () => {
    try {
      setLoadingGenerate(true);

      if (initialValues.codPromo) {
        const { data } = await validatePromotionCodeRequest(initialValues.codPromo);

        console.log(59, data);
        if (!data.valid) return Toast.showErrorMessage("Código promocional inválido.");
      }

      const res = await generateChargeRequest('', initialValues);

      if(res.ok) {
        Toast.showSuccessMessage('Boleto criado com sucesso!');
        setIsOpenModal(false);
      }
    } catch (error) {
      Toast.showErrorMessage("Erro ao gerar BOLETO");
      console.log(48, error);
    } finally {
      setLoadingGenerate(false);
    }
  };

  const handleCancel = () => {
    setIsOpenModal(false);
  };

  const onChange = async (value) => {
    const findCourse = courses.find(course => course.value === Number(value))

    setCourseSelected(findCourse)

    setInitialValues(prevState => {
      return {
        ...prevState,
        cursoId: value,
      }
    })

    if (findCourse.has_module) {
      const { data } = await findTopicsActivedByCourseRequest(findCourse.value);
      setModuleSelected(data)
    } else {
      setInitialValues(prevState => {
        return {
          ...prevState,
          topico_id: undefined,
        }
      })
      setModuleSelected([])
    }

    const _installments = [];

    for (let index = 1; index <= findCourse.nu_parcelas_boleto; index++) {
      _installments.push({
        value: index,
        label: index === 1 ? 'à vista' : `${index}x`,
      })
    }

    setInstallments(_installments);
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  const onChangeModule = (value) => {
    setInitialValues(prevState => {
      return {
        ...prevState,
        topico_id: value,
      }
    })
  };

  const onChangeInstallments = (value) => {
    setInitialValues(prevState => {
      return {
        ...prevState,
        qtdParcela: value,
      }
    })
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Container>
      <Button
        disabled={loading}
        onClick={() => setIsOpenModal(true)}
      >
        Gerar Boletos
      </Button>

      <Modal
        open={isOpenModal}
        title="Gerar Boleto"
        onCancel={handleCancel}
        onOk={handleGenerateCharge}
        okText='Gerar boleto'
        confirmLoading={loadingGenerate}
      >
        <Select
          showSearch
          options={courses}
          onChange={onChange}
          onSearch={onSearch}
          optionFilterProp="label"
          style={{ width: '100%' }}
          placeholder="Selecione o curso"
        />

        {
          courseSelected?.has_module && moduleSelected.length > 0 && (
            <Select
              showSearch
              options={moduleSelected}
              onChange={onChangeModule}
              onSearch={onSearch}
              style={{ width: '100%', marginTop: 16 }}
              optionFilterProp="label"
              placeholder="Selecione o módulo"
            />
          )
        }

        <Select
          showSearch
          options={installments}
          onChange={onChangeInstallments}
          onSearch={onSearch}
          style={{ width: '100%', marginTop: 16 }}
          optionFilterProp="label"
          placeholder="Selecione o número de parcelas"
        />

        <Input
          placeholder="Informe o seu cupom"
          onChange={(event) => setInitialValues({ ...initialValues, codPromo: event.target.value })}
          style={{ background: Colors.softGray, borderRadius: 4, width: '100%', minWidth: 100, height: 40, marginTop: 16 }}
        />
      </Modal>
    </Container>
  );
}
