import React from "react";
import { useHistory } from "react-router-dom";
import {
  AiFillCheckCircle,
  AiOutlinePlayCircle,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { Colors, Metrics } from "../../configs";
import { Text, Whitespace } from "../../common";

const styles = {
  videoContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: Metrics.spacing.md,
    paddingBottom: Metrics.spacing.sm,
    borderBottom: `0.5px ${Colors.softGray} solid`,
  },
  finished: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emptySpace: { width: 18 },
  touchableWatch: {
    width: "100%",
  },
  checkIcon: { flexDirection: "row", display: "flex", cursor: "pointer" },
};

const CourseSectionContent = ({ ds, courseId }) => {
  const history = useHistory();
  return (
    <div style={{ padding: Metrics.spacing.md }}>
      {ds.usuarioTreinamento.map((dsv, i) => (
        <div key={i} style={styles.videoContainer}>
          <div
            onClick={() =>
              history.push(`/course-video/${courseId}/${dsv.treinamento_id}`)
            }
            style={styles.touchableWatch}
          >
            <div style={styles.finished}>
              <div style={styles.checkIcon}>
                {dsv.fg_concluido ? (
                  <AiFillCheckCircle color={Colors.greenCheck} size={18} />
                ) : (
                  <div style={styles.emptySpace} />
                )}
                <Whitespace width={Metrics.spacing.sm} />
                <AiOutlineVideoCamera color={Colors.primary} size={22} />
                <Whitespace width={Metrics.spacing.sm} />
                <Text size={Metrics.fontSize.xsm} color={Colors.secondary}>
                  {dsv.ds_titulo}
                </Text>
              </div>
              <AiOutlinePlayCircle
                color={Colors.primary}
                size={22}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CourseSectionContent;
