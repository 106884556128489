import React from "react";
import { Badge, Dropdown } from "antd";
import { BiBell } from "react-icons/bi";
import { IconWithText } from "../styles";
import { Colors } from "configs";
import { Dates } from "utils";
import useFetch from "hooks/useFetch";
import { findAllRequest, markAllReadedRequest } from "services/notifications";

export default function Notification() {
  const [{ data }, fetchAll] = useFetch(findAllRequest, "", true, [], null);
  const [, fetch] = useFetch(markAllReadedRequest, "", false, null, null, () =>
    fetchAll()
  );

  const menu = () => {
    if (!data || data.length === 0) {
      return <></>;
    }
    return (
      <div style={styles.menu}>
        {data.map((d, k) => (
          <div style={styles.menuItem} key={k}>
            <span>{d.ds_description}</span>
            <div style={styles.menuItemTime}>
              {Dates.transformDateWithHours(d.created_at)}
            </div>
          </div>
        ))}
        <div style={styles.menuClear} onClick={() => fetch()}>
          Limpar notificações
        </div>
      </div>
    );
  };

  return (
    <Dropdown overlay={menu}>
      <IconWithText>
        <Badge dot={data && data.length > 0}>
          <BiBell style={styles.icon} />
        </Badge>
        <span>Notificações</span>
      </IconWithText>
    </Dropdown>
  );
}

const styles = {
  icon: {
    fontSize: "25px",
    color: Colors.secondary,
  },
  menu: {
    minWidth: 200,
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 12,
    boxShadow: "0 1px 6px #0000001a",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "2px solid #f3f3f3",
    padding: "5px 10px",
  },
  menuItemTime: {
    alignSelf: "flex-end",
    color: Colors.primaryStrong,
    fontSize: 12,
  },
  menuClear: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    padding: 5,
  },
};
