import { useFormikContext } from "formik";
import { DatePicker, FormItem } from "formik-antd";
import { Dates } from "utils";
import moment from "moment";
import { Colors, Metrics } from "configs";

const FormInputDate = ({
  value,
  required,
  editable = true,
  label,
  name,
  disabled,
  onChangeParam,
  placeholder,
  fullWidth,
  showValidateSuccess,
  format = "DD/MM/YYYY",
  noMargin = false,
  ...rest
}) => {
  const { getFieldMeta, setFieldValue } = useFormikContext();
  const { error, touched } = getFieldMeta(name);

  const onChange = (newValue) => {
    if (!disabled) {
      if (name) {
        setFieldValue(
          name,
          newValue ? Dates.transformInputRange(newValue) : null
        );
      }
      if (onChangeParam) {
        onChangeParam(newValue);
      }
    }
  };

  return (
    <FormItem
      style={fullWidth ? { width: "100%" } : {}}
      name={name}
      required={required}
      showValidateSuccess={showValidateSuccess}
    >
      <span style={{ color: error && touched && Colors.error }}>
        {label && (required ? `${label} *` : label)}
      </span>
      <DatePicker
        style={{
          width: "100%",
          marginBottom: !noMargin && Metrics.spacingSM,
          border: error ? `1px solid ${Colors.error}` : "1px solid #B1B1B7",
        }}
        placeholder={!label ? placeholder : ""}
        error={error}
        onChange={onChange}
        value={value ? moment(value) : null}
        format={format}
        required={required}
        {...rest}
      />
    </FormItem>
  );
};

export default FormInputDate;
