import styled from "styled-components";
import { Metrics } from "configs";

export const ButtonStyled = styled.button`
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor, disabled }) => !disabled ? backgroundColor || "#00b2a9" : '#82A49D'};
  text-align: center;
  font-size: ${({ size }) => size};
  padding: 8px 13px;
  border: none;
  border-radius: 50px;
  min-width: ${({ width }) => width};
  margin: ${Metrics.spacing.min} 0;
  max-height: ${({ maxHeight }) => maxHeight};
`;
