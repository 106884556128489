import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input } from "./styles";

const SearchInput = ({ onChange = () => {}, value = "" }) => {
  function clearValue() {
    onChange("");
  }

  return (
    <Input
      placeholder="Pesquise"
      prefix={value ? <CloseCircleOutlined onClick={clearValue} /> : <></>}
      suffix={<SearchOutlined />}
      onChange={(e) => onChange(e.target.value)}
      value={value}
    />
  );
};

export default SearchInput;
