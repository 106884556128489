import React from 'react';
import { Formik } from 'formik';
import { Form } from 'antd';
import { AlternativeHandler, FormButtons, FormDropdown, FormInputText } from 'components';
import { TabPaneStyled, TabsStyled } from './styles';
import { FormCheckbox, Row } from '../../../../common';

const QuestionForm = ({ handleSubmitForm, initialValues, handleCancel }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitForm}>
      {({ handleSubmit, resetForm, setFieldValue, values }) => (
        <Form>
          <TabsStyled defaultActiveKey="1" onChange={() => {}}>
            <TabPaneStyled tab="Dados gerais" key="1">
              <FormInputText
                name="ds_titulo"
                label="Título"
                required
                value={values.ds_titulo}
              />
              <FormInputText
                name="ds_resumo"
                label="Resumo"
                value={values.ds_resumo}
              />
              <FormDropdown label='Tipo questão' list={[{value: 'CHECKBOX', label: 'Checkbox'}, {value: 'RADIO', label: 'Radio button'}]} name='ds_tipo_questao' value={values.ds_tipo_questao} />
              <FormInputText
                type='number'
                label="N. ordem"
                name="nu_ordenacao"
                value={values.nu_ordenacao}
              />
              <FormInputText
                type='number'
                label="Experiência (xp)"
                name="vl_xp"
                value={values.vl_xp}
              />
              <FormCheckbox
                label='Obrigatória'
                name='fg_obrigatoria'
                value={values.fg_obrigatoria}
              />
            </TabPaneStyled>
            <TabPaneStyled tab="Alternativas" key="2">
              <AlternativeHandler />
            </TabPaneStyled>
          </TabsStyled>
          <FormButtons
            bt2={{ label: 'Salvar', onClick: (val) => {
                handleSubmit(val);
                setTimeout( () => {
                  setFieldValue('alternativas', []);
                  resetForm();
                }, 0);
              } }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default QuestionForm;
