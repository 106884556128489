import styled from "styled-components";
import { Input as InputAntd } from "formik-antd";

export const Container = styled.div`
  background-color: #00b2a9;
  width: 100%;
  min-height: 400px;
  padding: 2vw 4vw 4vw 4vw;
`;

export const Input = styled(InputAntd)`
  margin-right: 10px;
  border-radius: 30px;
  height: 60px;
  background-color: #75cec9 !important;
  color: #fff;
  border: none;
  padding-left: 20px;

  ::placeholder {
    color: #fff;
    font-weight: bold;
  }
`;

export const TextArea = styled(InputAntd.TextArea)`
  padding: 20px;
  background-color: #75cec9 !important;
  border-radius: 20px;
  color: #fff;
  border: none;
  min-height: 130px !important;
  resize: none;

  ::placeholder {
    color: #fff;
    font-weight: bold;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  a:not(:nth-child(1)) {
    margin-left: 15px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleMark = styled.div`
  border: 2px solid #fff;
  width: 70px;
  margin-top: 15px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  .logo {
    max-width: 120px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    > div {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      flex: 1;
    }
  }
`;

export const FormFooterContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  > div {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 1rem;
  }
`;
