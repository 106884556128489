import React from "react";
import { Colors, Metrics } from "configs";
import CoursePlaceholder from "assets/images/coursePlaceholder.png";
import { Text } from "common";
import ProgressBar from "../PercentageBar";
import { RiDownload2Fill } from "react-icons/ri";
import { Modal } from "utils";
import { Typography } from "antd";

const { Paragraph } = Typography;

const CertificateCardSm = ({ item, onClick }) => {
  return (
    <div style={styles.courseCard} onClick={onClick}>
      <div style={styles.flexRow}>
        <div>
          <img src={item.img || CoursePlaceholder} style={styles.img} alt="" />
        </div>
        <div>
          <Text bold size={Metrics.fontSize.xsm} color={Colors.secondary}>
            {item.ds_titulo}
          </Text>
          <div style={styles.resume}>
            <Paragraph
              ellipsis={{ rows: 2 }}
              style={{
                color: Colors.secondary,
                fontSize: 15,
                fontWeight: 300,
              }}
            >
              {item.ds_resumo}
            </Paragraph>
          </div>
        </div>
      </div>
      <div
        style={styles.download}
        onClick={() =>
          Modal.showAlert({ text: "Funcionalidade não implementada" })
        }
      >
        <RiDownload2Fill style={styles.download.icon} />
        <span style={styles.download.text}>Baixar certificado</span>
      </div>
      <ProgressBar pct={item.pr_concluido} width="100%" />
    </div>
  );
};

const styles = {
  courseCard: {
    position: "relative",
    backgroundColor: "white",
    margin: "10px 20px 10px 0",
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    width: "300px",
    height: "160px",
    boxShadow: "1px 1px 6px #cacaca",
    display: "flex",
    flexDirection: "column",
  },
  resume: {},
  flexRow: {
    display: "flex",
    flex: 1,
  },
  price: {
    position: "absolute",
    right: 12,
    top: 15,
  },
  img: {
    width: "70px",
  },
  value: {
    color: Colors.greenCheck,
  },
  download: {
    display: "flex",
    width: "max-content",
    cursor: "pointer",
    alignItems: "flex-end",
    icon: {
      color: Colors.primary,
      marginRight: "5px",
    },
    text: {
      fontSize: "14px",
      lineHeight: "14px",
      color: Colors.primary,
    },
  },
};

export default CertificateCardSm;
