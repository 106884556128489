import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "routes/history";
import auth from "store/reducers/auth";
import user from "store/reducers/user";
import course from "store/reducers/course";
import common from "store/reducers/common";

export default combineReducers({
  router: connectRouter(history),
  auth,
  user,
  course,
  common,
});
