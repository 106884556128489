import PageTemplate from "template/PageTemplate";
import { Colors } from "configs";
import { AddCard } from "./styles";
import useFetch from "../../hooks/useFetch";
import { findCardsRequest } from "services/card";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import { CardIcon } from "./util";

const PaymentMethod = () => {
  const history = useHistory();
  const [{ isFetching, data: cartoes }] = useFetch(
    findCardsRequest,
    null,
    true
  );

  return (
    <PageTemplate title="Cadastro de Cartões" contentStyle={styles.container}>
      <Spin spinning={isFetching} />
      {!isFetching && (
        <>
          {cartoes &&
            cartoes.map((cartao, i) => (
              <div style={styles.wrapper}>
                <div style={styles.cardContainer} key={i}>
                  <div style={styles.cardHeader}>
                    <span style={styles.cardNickname}>
                      {cartao.apelido || "Apelido cartão"}
                    </span>
                    <span>
                      <CardIcon
                        bandeira={cartao.bandeira}
                        numero={cartao.numero_final}
                        width={60}
                        height={60}
                      />
                    </span>
                  </div>
                  <div style={styles.cardNumber}>
                    <span style={styles.cardSpace}>••••</span>
                    <span style={styles.cardSpace}>••••</span>
                    <span style={styles.cardSpace}>••••</span>
                    <span>{cartao.numero_final}</span>
                  </div>
                  <div style={styles.cardData}>
                    <div style={styles.cardDataContent}>
                      <span style={styles.cardDataContent.title}>
                        NOME NO CARTÃO
                      </span>
                      <span style={styles.cardDataContent.data}>
                        {cartao.nome}
                      </span>
                    </div>
                    <div style={styles.cardDataContent}>
                      <span style={styles.cardDataContent.title}>VALIDADE</span>
                      <span style={styles.cardDataContent.data}>
                        {cartao.data_validade}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={styles.editCard}
                  onClick={() =>
                    history.push(`/payment-method/edit/${cartao.id}`)
                  }
                >
                  <span>Editar</span>
                </div>
              </div>
            ))}
          <AddCard onClick={() => history.push("/payment-method/create")}>
            <div>
              <span>Adicionar cartão</span>
            </div>
          </AddCard>
        </>
      )}
    </PageTemplate>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  wrapper: {
    backgroundColor: Colors.white,
    boxShadow: "1px 2px 3px #00000029",
    minWidth: "335px",
    minHeight: "200px",
    borderRadius: "20px",
    padding: "20px 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginRight: "20px",
    marginTop: "20px",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  cardNickname: {
    fontSize: 20,
    color: Colors.primary,
    fontWeight: 700,
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardData: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardDataContent: {
    display: "flex",
    flexDirection: "column",
    title: {
      fontSize: "10px",
      color: "#444444",
      fontFamily: "Arial",
      fontWeight: "bold",
    },
    data: {
      fontSize: "17px",
      lineHeight: "17px",
      marginTop: "5px",
      color: "#444444",
      fontFamily: "Arial",
      maxWidth: 220,
    },
  },
  editCard: {
    cursor: "pointer",
    width: "max-content",
    alignSelf: "center",
    color: Colors.primary,
    marginTop: "10px",
    textDecoration: "underline",
    fontSize: 15,
  },
  cardSpace: {
    marginRight: 15,
    fontSize: 30,
  },
  cardNumber: {
    fontSize: "20px",
    fontFamily: "Arial",
    color: "#444444",
    display: "flex",
    alignItems: "center",
  },
};
export default PaymentMethod;
