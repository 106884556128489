import api from "./api";

const findQuestionRequest = (a, params) => {
  return api.get(`api/questoes/${params}`);
};

const findQuestionsRequest = (params) => {
  return api.get(`api/questoes/`);
};

const deleteQuestionRequest = (a, id) => {
  return api.delete(`api/questoes/${id}`);
};

const createQuestionRequest = (a, question) => {
  return api.post('api/questoes', question);
};

const findQuestionsByTrainingsRequest = (a, params) => {
  return api.get(`api/questoes/findByTraining/${params}`);
};

const updateQuestionRequest = (param, question) => {
  return api.put(`api/questoes/${question.id}`, question);
};

const findQuestionValidationRequest = (a, answers) => {
  // mock
  return api.post(`api/questoes/validate/`, answers);
};

export {
  findQuestionValidationRequest,
  findQuestionsByTrainingsRequest,
  findQuestionRequest,
  findQuestionsRequest,
  deleteQuestionRequest,
  createQuestionRequest,
  updateQuestionRequest,
};
