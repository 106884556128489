import styled from "styled-components";
import { Input as InputAntd } from "formik-antd";
import { Metrics } from "configs";

export const Container = styled.div`
  margin-top: ${Metrics.spacingXLG};
  border-radius: ${Metrics.fontSize.sm};
  background-color: #00b2a9;
  width: 100%;
  min-height: 400px;
  padding: 2vw;
`;

export const Input = styled(InputAntd)`
  margin-right: 10px;
  border-radius: 10px;
  height: 60px;
  background-color: #75cec9 !important;
  color: #fff;
  border: none;
  padding-left: 20px;

  ::placeholder {
    color: #fff;
    font-weight: bold;
  }
`;

export const TextArea = styled(InputAntd.TextArea)`
  padding: 20px;
  background-color: #75cec9 !important;
  border-radius: 10px;
  color: #fff;
  border: none;
  min-height: 130px !important;
  resize: none;

  ::placeholder {
    color: #fff;
    font-weight: bold;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleMark = styled.div`
  border: 2px solid #fff;
  width: 70px;
  margin-top: 15px;
`;

export const FormFooterContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  > div {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 1rem;
  }
`;
