import { toast } from 'react-toastify';
import styled from 'styled-components';

let invalidTokenOpen = false;
let requestErrorOpen = false;

const showInfoMessage = message => {
  toast.info(<Msg text={message} />);
};

const showSuccessMessage = message => {
  toast.success(<Msg text={message} />);
};

const showWarnMessage = message => {
  toast.warn(<Msg text={message} />);
};

const showErrorMessage = message => {
  toast.error(<Msg text={message} />);
};

const showRequestErrorMessage = message => {
  if (!requestErrorOpen) {
    requestErrorOpen = true;
    toast.error(<Msg text={message} />, {
      onClose: () => {
        requestErrorOpen = false;
      },
    });
  }
};

const showInvalidTokenMessage = () => {
  if (!invalidTokenOpen) {
    invalidTokenOpen = true;
    toast.error(<Msg text="Sessão expirada, efetue login novamente." />, {
      onClose: () => {
        invalidTokenOpen = false;
      },
    });
  }
};

const Msg = ({ text }) => {
  return <MsgStyled>{text}</MsgStyled>;
};

const MsgStyled = styled.span`
  font-size: 16px;
`;

export default {
  showInfoMessage,
  showSuccessMessage,
  showWarnMessage,
  showErrorMessage,
  showInvalidTokenMessage,
  showRequestErrorMessage,
};
