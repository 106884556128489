import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Storage } from 'utils';
import { routerMiddleware } from 'connected-react-router';
import history from 'routes/history';
import sagas from './sagas';
import reducers from './reducers';

const getInitialState = () => {
  const token = Storage.getToken();
  const user = Storage.get('studium#account');
  return {
    auth: {
      authenticated: user && token,
      user,
      token,
    },
  };
};

const composeEnhancer =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: false,
      traceLimit: 25,
    })) ||
  compose;

const middleware = [];
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);
middleware.push(routerMiddleware(history));
const enhancers = [applyMiddleware(...middleware)];
const store = createStore(
  reducers,
  getInitialState(),
  composeEnhancer(...enhancers)
);

const configureStore = () => {
  return { store };
};

sagaMiddleware.run(sagas);

export default configureStore;
