export function FilterFunction(data, filterObj) {
  const filteredResults = data.filter((record) => {
    let totalValidResults = 0;
    let totalFilters = 0;

    for (const key of Object.keys(filterObj)) {
      if (
        filterObj[key] !== null &&
        filterObj[key] !== undefined &&
        filterObj[key] !== ""
      ) {
        totalFilters += 1;
        try {
          totalValidResults += record[key]
            .toString()
            .toLowerCase()
            .includes(filterObj[key].toLowerCase())
            ? 1
            : 0;
        } catch {
          totalValidResults += record[key] === filterObj[key] ? 1 : 0;
        }
      }
    }

    return totalValidResults === totalFilters ? record : false;
  });

  return filteredResults;
}
