import api from "./api";
import {
  createParishRequest,
  deleteParishRequest,
  updateParishRequest,
} from "services/parish";
import Filters from "utils/Filters";

const findCountiesRequest = (params) => {
  return api.get(`api/comarca/${params}`);
};

const saveAllParish = async (parishes, countyId) => {
  const promisses = [];
  for (const parish of parishes) {
    if (parish.id && parish.deleted) {
      promisses.push(await deleteParishRequest(parish.id));
    } else if (parish.id && !parish.deleted) {
      promisses.push(await updateParishRequest(null, parish));
    } else {
      promisses.push(
        await createParishRequest(null, {
          ...parish,
          comarca_id: countyId,
          nu_cep: Filters.removeMaskCEP(parish.nu_cep).toString(),
        })
      );
    }
  }
  return await Promise.all(promisses);
};

const createCountyRequest = async (params, body) => {
  if (body.id) {
    return updateCountyRequest(null, body);
  }
  try {
    const county = await api.post(`api/comarca`, body);
    if (county && county.ok && body.paroquias && body.paroquias.length > 0) {
      await saveAllParish(body.paroquias, county.data.id);
    } else if (!county || !county.ok) {
      return new Promise((resolve) => resolve({ ok: false }));
    }
    return findCountyRequest(county.data.id);
  } catch (e) {
    return new Promise((resolve) => resolve({ ok: false }));
  }
};
const updateCountyRequest = async (params, body) => {
  try {
    const county = await api.put(`api/comarca/${body.id}`, body);
    if (county && county.ok) {
      await saveAllParish(body.paroquias, county.data.id);
    } else if (!county || !county.ok) {
      return new Promise((resolve) => resolve({ ok: false }));
    }
    return findCountyRequest(county.data.id);
  } catch (e) {
    return new Promise((resolve) => resolve({ ok: false }));
  }
};

const deleteCountyRequest = (params) => {
  return api.delete(`api/comarca/${params}`);
};

const findCountyRequest = (params) => {
  return api.get(`api/comarca/${params}`);
};

export {
  createCountyRequest,
  updateCountyRequest,
  findCountiesRequest,
  findCountyRequest,
  deleteCountyRequest,
};
