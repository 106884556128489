import React, { createRef, useCallback, useEffect } from "react";
import PageTemplate from "template/PageTemplate";
import useFetch from "hooks/useFetch";
import { findFinancialData } from "services/financialData";
import { Spin } from "antd";
import { Collapse } from "common";
import { findCardsRequest } from "services/card";
import PanelContent from "features/FinancialData/components/PanelContent";
import HeaderContent from "features/FinancialData/components/HeaderContent";
import Modal from "features/FinancialData/Modal";

const { Panel } = Collapse;

export default function FinancialData() {
  const [{ data, isFetching }, fetch] = useFetch(
    findFinancialData,
    "",
    true,
    [],
    null
  );

  const chargeModalRef = createRef();

  const openModal = useCallback((record) => {
    chargeModalRef.current?.openModal(record);
  }, [chargeModalRef]);

  const [{ data: cards }, fetchCards] = useFetch(
    findCardsRequest,
    null,
    true,
    []
  );

  return (
    <PageTemplate title="Financeiro">
      <Spin spinning={isFetching} />
      <Collapse accordion>
        <Modal ref={chargeModalRef} fetchCards={fetchCards} cards={cards} fetchFinancialData={fetch}/>
        {data?.data?.map((d, i) => (
          <Panel
            key={d.curso_id}
            header={<HeaderContent data={d} openModal={openModal}/>}
            // showArrow={false}
            // collapsible={
            //   +d.vl_curso > 0 && d.pagamentos.length === 0 ? "disabled" : null
            // }
          >
            <PanelContent data={d} openModal={openModal} />
          </Panel>
        ))}
      </Collapse>
    </PageTemplate>
  );
}
