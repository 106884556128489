import Filters from '../../../utils/Filters';
import Moment from 'moment';
import Input from '../../../common/FormInputCustom';
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

const DateContainer = () => {
  const [dtNascimento, setDtNascimento] = useState();
  const {values, setFieldValue} = useFormikContext();

  useEffect( () => {
    if (values && values.dt_nascimento) {
      setDtNascimento(Moment(values.dt_nascimento).format('DD/MM/yyyy'))
    }
  }, [values.dt_nascimento]);

  return (
    <Input
      name='teste'
      placeholder="Data de Nascimento"
      value={dtNascimento}
      onChange={(e) => setDtNascimento(Filters.inputMaskDATE(e.target.value))}
      required
    />
  );
}

export default DateContainer;
