const spacing = {
  min: '4px',
  sm: '8px',
  md: '12px',
  lg: '28px',
  xlg: '36px',
  xxxlg: '100px',
};

const spacingMinimun = '4px';
const spacingSM = '8px';
const spacingSMMD = '10px';
const spacingMD = '12px';
const spacingXMD = '20px';
const spacingLG = '28px';
const spacingXLG = '36px';
const spacingXXLG = '40px';
const spacingXXXLG = '45px';
const spacingXXXXLG = '50px';
const spacingXXXXXLG = '60px';
const spacingMax = '100px';

const defaultRadius = '15px';
const headerSize = '100px';

const fontSize = {
  xxxsm: '10px',
  xxsm: '12px',
  xsm: '16px',
  sm: '19px',
  md: '22px',
  lg: '24px',
  xlg: '26px',
  xxlg: '30px',
};

export default {
  fontSize,
  spacing,
  defaultRadius,
  headerSize,
  spacingMinimun,
  spacingSM,
  spacingSMMD,
  spacingMD,
  spacingXMD,
  spacingLG,
  spacingXLG,
  spacingXXLG,
  spacingXXXLG,
  spacingXXXXLG,
  spacingXXXXXLG,
  spacingMax,
};
