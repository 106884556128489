import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, FormCheckbox, Row, Text } from '../../common';
import { useFormikContext } from 'formik';
import { FormInputText, Whitespace } from '../index';
import { Colors, Metrics } from '../../configs';
import { CheckCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { Toast } from '../../utils';

export default function AlternativeHandler() {
  const { setFieldValue, values } = useFormikContext();
  const alternIndex = ['a', 'b', 'c', 'd'];

  const handleAdd = useCallback( () => {
    if (values.alternativas && values.alternativas.length === 4) {
      return Toast.showErrorMessage('Já existem 4 alternativas para esta questão!');
    }
    if (values.alternativas) {
      setFieldValue('alternativas', [...values.alternativas, {id: values.id_alternativa ? values.id_alternativa : uuidv4(), ds_descricao: values.ds_descricao, fg_correta: values.fg_correta }]);
    } else {
      setFieldValue('alternativas', [{id: values.id_alternativa ? values.id_alternativa : uuidv4(), ds_descricao: values.ds_descricao, fg_correta: values.fg_correta }]);
    }
    setFieldValue('ds_descricao', '')
    setFieldValue('fg_correta', false);
  }, [setFieldValue, values]);

  const handleEdit = useCallback( (alternative) => {
    setFieldValue('id_alternativa', alternative.id)
    setFieldValue('ds_descricao', alternative.ds_descricao)
    setFieldValue('fg_correta', alternative.fg_correta)
    setFieldValue('alternativas', values.alternativas.filter(a => a.id !== alternative.id));
  }, [setFieldValue, values]);

  const handleRemove = useCallback( (alternative) => {
    setFieldValue('alternativas', values.alternativas.filter(a => a.id !== alternative.id));
  }, [setFieldValue, values]);

  return (
    <Col>
      <Row style={{alignItems: 'center'}}>
        <div style={{width: '90%'}}>
        <FormInputText
          label="Descrição"
          name="ds_descricao"
          value={values.ds_descricao}
        />
        </div>
        <Whitespace width={Metrics.spacing.xlg} />
        <div style={{maxWidth: '100px'}}>
          <FormCheckbox
            label='Correta'
            name='fg_correta'
            value={values.fg_correta}
          />
        </div>
      </Row>
      <div>
        <Button disabled={!values.ds_descricao} onClick={handleAdd} type='primary'>Adicionar</Button>
      </div>
      {values.alternativas && values.alternativas.length > 0 &&
      <div style={{minHeight: '206px'}}>
        <Whitespace height={Metrics.spacing.lg} />
        <Text bolder>Alternativas criadas:</Text>
        <Whitespace height={Metrics.spacing.sm} />
        {values.alternativas && values.alternativas.map( (a,i) =>
          <Col key={i} >
            <Row style={{alignItems: 'center'}}>
              <span>{`${alternIndex[i]}) ${a.ds_descricao}`}</span>
              <Whitespace width={Metrics.spacing.xlg} />
              {a.fg_correta ? <CheckCircleOutlined style={{color: Colors.greenCheck}} /> : ''}
            </Row>
            <Row>
              <span onClick={() => handleEdit(a)} style={{cursor: 'pointer', color: Colors.primary, fontSize: Metrics.fontSize.xxxsm, textDecoration: 'underline'}}>editar</span>
              <Whitespace width={Metrics.spacing.md} />
              <span onClick={() => handleRemove(a)} style={{cursor: 'pointer', color: Colors.primary, fontSize: Metrics.fontSize.xxxsm, textDecoration: 'underline'}}>remover</span>
            </Row>
          </Col>
        )}
      </div>
        }
    </Col>
  );
}
