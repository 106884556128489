import { Space, Typography } from "antd";
import CircleWithText from "common/CircleWithText";
import { Colors, Metrics } from "configs";
import { Container } from "./styles";
import medal from "assets/images/medal.png";
import certificate from "assets/images/certificate.png";
import laptop from "assets/images/laptop.png";
import { Col, Row } from '../../../../common';

const { Text } = Typography;

const blueGradient =
  "linear-gradient(to bottom, #beeae8, #9ddcd9, #7acfca, #51c1bb, #00b3ab)";

const greenGradient =
  "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(168,208,141,1) 70%)";

const yellowGradient =
  "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(255,231,179,1) 70%)";

const OnlyImportant = () => {
  return (
    <Col style={{
      alignItems: "center",
      width: '100%'
    }}>
      <Col style={{
        alignItems: "center",
      }}>
        <Text
          style={{ color: Colors.primary, fontSize: Metrics.fontSize.md }}
          strong
        >
          Digital, prático e com certificado!
        </Text>
        <span
          style={{
            border: "3px solid #dca039",
            width: "70px",
            marginTop: "15px",
          }}
        />
      </Col>
      <Row
        responsive
        style={{
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          marginTop: "80px",
        }}
      >
        <CircleWithText
          text="Formação Online"
          // fontColor="#89b64a"
          background={blueGradient}
          image={laptop}
        />
        <CircleWithText
          text="Aprenda com o melhor conteúdo"
          background={greenGradient}
          image={medal}
        />
        <CircleWithText
          text="Obtenha certificação"
          background={yellowGradient}
          image={certificate}
        />
      </Row>
    </Col>
  );
};

export default OnlyImportant;
