import { Colors, Metrics } from "configs";
import { Button } from "common";
import styled from "styled-components";
import { Modal as ModalAntd } from "antd";

export const ModalStyled = styled(ModalAntd)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 20px;
  box-shadow: 0 4px 6px #0000001a;
  padding: 0;

  .ant-modal-content {
    box-shadow: none;
    background: none;
    border-radius: 20px;
  }

  .ant-modal-header,
  .ant-modal-body {
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 20px;
  }

  .ant-modal-close-x {
    color: ${({ closeColor }) => closeColor};
  }
`;

export default function Modal({
  visible,
  onCancel,
  text,
  buttonText,
  onClick,
  backgroundColor = Colors.primary,
  closeColor = Colors.white,
  buttonBackground = Colors.white,
  icon: Icon,
}) {
  return (
    <ModalStyled
      visible={visible}
      onCancel={onCancel}
      footer={null}
      backgroundColor={backgroundColor}
      closeColor={closeColor}
    >
      <div style={styles.modalContent}>
        <Icon
          size={100}
          color={closeColor}
          style={{ marginTop: Metrics.spacingLG }}
        />
        <span style={styles.modalText}>{text}</span>
        <Button
          backgroundColor={buttonBackground}
          color={Colors.primary}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </div>
    </ModalStyled>
  );
}

const styles = {
  modalText: {
    fontSize: Metrics.fontSize.sm,
    color: Colors.white,
    fontWeight: 600,
    margin: `${Metrics.spacingLG} 0`,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
