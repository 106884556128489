import React, { useEffect } from "react";
import PageTemplate from "template/PageTemplate";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { createMaritalStatusRequest } from "services/maritalStatus";
import MaritalStatusForm from "../Form";

export default function BackofficeMaritalStatusCreate() {
  const history = useHistory();
  const { goBack } = history;
  const initialValues = {
    ds_estado_civil: "",
  };
  const [{ isFetching, data }, createRequest] = useFetch(
    createMaritalStatusRequest,
    "",
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Criação de Estado Civil">
      <Spin spinning={isFetching}>
        <MaritalStatusForm
          initialValues={initialValues}
          handleSubmitForm={createRequest}
          handleCancel={goBack}
        />
      </Spin>
    </PageTemplate>
  );
}
