import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
`;

export const Circle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-width: 80px;
  }
`;

export const Text = styled.span`
  margin-top: 20px;
  color: ${({ fontColor }) => fontColor};
  font-weight: bold;
  font-size: 18px;
  text-align: center;
`;
