import * as Yup from "yup";
import {getCardFlag} from "../../../../../PaymentMethod/util";

export const ValidationSchema = Yup.object().shape({
  nome: Yup.string().nullable().required("O campo nome é obrigatório."),
  cvv: Yup.string().required("O campo cvv é obrigatório."),
  validade: Yup.string()
    .nullable()
    .required("O campo validade é obrigatório.")
    .min(7, "O campo validade não é válido."),
  numero: Yup.string()
    .trim()
    .nullable()
    .required("O campo número é obrigatório.")
    .test("numero", "O número informado não é válido", (val, t) => {
      if (t.parent.id && val && val.includes("•")) {
        return true;
      }
      return getCardFlag(val);
    }),
});
