import styled from "styled-components";
import introbg from "assets/images/intro-bg.png";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  > section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 8vw 100px 8vw;
  }

  > section:nth-child(1) {
    background: transparent url(${introbg}) no-repeat bottom;
    padding-top: 100px;
  }

  > section:nth-child(2) {
    padding-right: 0;
    padding-top: 100px;
  }
`;
