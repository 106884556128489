import { Colors, Metrics } from "configs";
import { ButtonStyled } from "./styles";
import { Spin } from 'antd';

const Button = ({
  size = Metrics.fontSize.xsm,
  color = Colors.white,
  children,
  textAlign = "center",
  bolder = false,
  width = "130px",
  style,
  uppercase,
  disabled,
  type = "button",
  backgroundColor = null,
  maxHeight = "40px",
  loading,
  ...rest
}) => {
  return (
    <ButtonStyled
      disabled={disabled}
      uppercase={uppercase}
      width={width}
      textAlign={textAlign}
      color={color}
      size={size}
      bolder={bolder}
      style={style}
      type={type}
      backgroundColor={backgroundColor}
      maxHeight={maxHeight}
      {...rest}
    >
      {
        loading ? (
          <Spin />
        ) : children
      }
    </ButtonStyled>
  );
};

export default Button;
