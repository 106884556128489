import api from "./api";

const findTopicWithTrainingsRequest = (params, id) => {
  return api.get(`api/topicos/findAllById/${id}`);
};

const findTopicsByCourseRequest = (params) => {
  return api.get(`api/topicos/findByCourse/${params}`);
};

const findTopicsRequest = (params) => {
  return api.get(`api/topicos/`);
};

const deleteTopicRequest = (param, id) => {
  return api.delete(`api/topicos/${id}`);
};

const createTopicRequest = (param, topic) => {
  return api.post('api/topicos', topic);
};

const updateTopicRequest = (param, topic) => {
  return api.put(`api/topicos/${topic.id}`, topic);
};

const findTopicsActivedByCourseRequest = (params) => {
  return api.get(`api/topicos/findByCourseActive/${params}`);
};

export {
  findTopicsRequest,
  deleteTopicRequest,
  createTopicRequest,
  updateTopicRequest,
  findTopicsByCourseRequest,
  findTopicWithTrainingsRequest,
  findTopicsActivedByCourseRequest,
};
