import api from "./api";

const findParishesListRequest = () => {
  return api.get(`api/paroquia`);
};

const findParishesRequest = (params, comarca_id) => {
  return api.get(`api/paroquias/${comarca_id}`);
};

const deleteParishRequest = (param) => {
  return api.delete(`api/paroquia/${param}`);
};

const updateParishRequest = async (params, body) => {
  return api.put(`api/paroquia/${body.id}`, body);
};

const findParishRequest = (params) => {
  return api.get(`api/paroquia/${params}`);
};

const createParishRequest = (params, body) => {
  return api.post(`api/paroquia`, body);
};

const validateParishRequest = (params, body) => {
  return api.post(`api/codigoPromocional/validaParoquia`, body);
};

export {
  validateParishRequest,
  createParishRequest,
  updateParishRequest,
  findParishesRequest,
  findParishRequest,
  deleteParishRequest,
  findParishesListRequest,
};
