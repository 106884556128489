import { Space, Typography } from 'antd';
import { Colors, Metrics } from 'configs';
import { Button, Flex } from 'common';
import { MailOutlined, UnlockOutlined } from '@ant-design/icons';
import ContactFooter from 'components/ContactFooter';
import SocialButton from 'components/SocialButton';
import { Formik } from 'formik';
import Input from 'common/FormInputCustom';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Wrapper } from './styles';
import { useAuthLoginRequest } from 'store/hooks/auth';
import { LoginValidationSchema } from 'features/Login/ValidationSchema';
import { isMobile } from 'react-device-detect';
import { DownloadOnStore } from '../../components';

const { Text } = Typography;

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const loginRequest = useAuthLoginRequest();
  const { push } = useHistory();
  const query = new URLSearchParams(location.search);
  const handleRegister = () => {
    push(`register?returnUrl=${query.get('returnUrl')}`);
  };

  const handleChangePass = () => {
    push(`changePasswordEmailCpf?returnUrl=${query.get('returnUrl')}`);
  };

  const handleLogin = (values) => {
    loginRequest(values, query.get('returnUrl'));
  };

  return (
    <>
      <Wrapper>
        {(isMobile) ?
          <DownloadOnStore />
          :
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={handleLogin}
            validationSchema={LoginValidationSchema}
          >
            {({ values, handleSubmit }) => (
              <Container>
                <Text strong style={{ color: Colors.primary }}>
                  Entre com sua conta
                </Text>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: Metrics.spacing.xlg,
                    marginBottom: Metrics.spacing.xlg,
                  }}
                >
                  <Space direction="vertical" size="middle">
                    <Input
                      placeholder="E-mail"
                      prefix={<MailOutlined />}
                      name="email"
                      value={values.email}
                    />
                    <Input
                      placeholder="Senha"
                      name="password"
                      value={values.password}
                      prefix={<UnlockOutlined />}
                      password
                    />
                    <Text
                      underline
                      style={{ color: Colors.primary, cursor: "pointer",fontSize: "16px" }}
                      strong
                      onClick={handleChangePass}
                    >
                      Recuperar senha
                    </Text>

                  </Space>
                </div>
                <Space direction="vertical" align="center" size="large">
                  <Button type="submit" onClick={handleSubmit}>
                    Entrar
                  </Button>

                  <Flex dir="column" alignItems="center">

                    <Text style={{ marginBottom: Metrics.spacing.sm }}>
                      Ainda não possui uma conta?
                    </Text>
                    <Text
                      underline
                      style={{
                        color: Colors.primary,
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      strong
                      onClick={handleRegister}
                    >
                      Cadastre-se
                    </Text>
                  </Flex>

                  <Flex dir="column" alignItems="center">
                    <Text style={{ marginBottom: Metrics.spacing.sm }}>
                      Ou entre com
                    </Text>
                    <SocialButton facebook>Facebook</SocialButton>
                    <SocialButton>Google</SocialButton>
                  </Flex>
                </Space>
              </Container>
            )}
          </Formik>
        }
        <Flex dir="column" alignItems="center" marginTop={Metrics.spacing.lg}>
          <Text
            underline
            style={{
              color: Colors.primary,
              cursor: "pointer",
              fontSize: "16px",
            }}
            strong
            onClick={() => {
              window.scrollTo(0,0);
              push("/open-privacy-policy");
            }}
          >
            Leia nossa política de privacidade
          </Text>
        </Flex>
      </Wrapper>
      <ContactFooter />
    </>
  );
};

export default Login;
