import { Typography, Space as SpaceAntd } from "antd";
import { Colors, Metrics } from "configs";
import { Button, Flex } from "common";
import { UnlockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import ContactFooter from "components/ContactFooter";
import SocialButton from "components/SocialButton";
import Input from "common/FormInputCustom";
import { Formik } from "formik";
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Wrapper, Space, InputsContainer } from "./styles";
import { RegisterValidationSchema } from "features/Register/ValidationSchema";
import { useAuthRegisterRequest } from "store/hooks/auth";
import { isMobile } from 'react-device-detect';
import { DownloadOnStore } from '../../components';

const { Text } = Typography;

const Register = () => {
  const location = useLocation();
  const { push } = useHistory();
  const registerRequest = useAuthRegisterRequest();
  const query = new URLSearchParams(location.search);
  const handleLogin = () => {
    push(`login?returnUrl=${query.get('returnUrl')}`);
  };

  const handleSubmit = (values) => {
    registerRequest(values, query.get('returnUrl'));
  };

  return (
    <>
      <Wrapper>
        {(isMobile) ?
          <DownloadOnStore />
          :
          <Formik
            initialValues={{
              email: "",
              username: "",
              password: "",
              confirmPassword: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={RegisterValidationSchema}
          >
            {({ values, handleSubmit }) => (
              <Container>
                <Text strong style={{ color: Colors.primary }}>
                  Crie sua conta
                </Text>
                <InputsContainer>
                  <Space direction="horizontal" size="middle">
                    <Input
                      placeholder="Seu nome"
                      prefix={<UserOutlined />}
                      name="username"
                      value={values.username}
                    />
                    <Input
                      placeholder="Senha"
                      prefix={<UnlockOutlined />}
                      password
                      name="password"
                      value={values.password}
                    />
                  </Space>
                  <Space direction="horizontal" size="middle">
                    <Input
                      placeholder="E-mail"
                      prefix={<MailOutlined />}
                      name="email"
                      value={values.email}
                    />
                    <Input
                      placeholder="Confirme sua senha"
                      prefix={<UnlockOutlined />}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      password
                    />
                  </Space>
                </InputsContainer>
                <SpaceAntd direction="vertical" align="center" size="large">
                  <div>
                    <Text style={{ marginBottom: Metrics.spacing.sm }}>
                      Ao se cadastrar você concorda com os
                      <Text style={{ color: Colors.primary }}>
                        {" "}
                        Termos de uso
                      </Text>{" "}
                      e a
                      <Text style={{ color: Colors.primary }}>
                        {" "}
                        Política de Privacidade
                      </Text>
                    </Text>
                  </div>

                  <Button width="200px" type="submit" onClick={handleSubmit}>
                    Cadastrar
                  </Button>

                  <Flex dir="column" alignItems="center">
                    <Text style={{ cursor: "pointer" }} onClick={handleLogin}>
                      Voltar para login
                    </Text>
                  </Flex>

                  <Flex dir="column" alignItems="center">
                    <Text style={{ marginBottom: Metrics.spacing.sm }}>
                      Ou cadastre-se com
                    </Text>
                    <SocialButton facebook>Facebook</SocialButton>
                    <SocialButton>Google</SocialButton>
                  </Flex>
                </SpaceAntd>
              </Container>
            )}
          </Formik>
        }
        <Flex dir="column" alignItems="center" marginTop={Metrics.spacing.lg}>
          <Text
            underline
            style={{
              color: Colors.primary,
              cursor: "pointer",
              fontSize: "16px",
            }}
            strong
            onClick={() => {
              window.scrollTo(0,0);
              push("/open-privacy-policy");
            }}
          >
            Leia nossa política de privacidade
          </Text>
        </Flex>
      </Wrapper>
      <ContactFooter />
    </>
  );
};

export default Register;
