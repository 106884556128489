import { Colors } from 'configs';
import { Container, Circle, Text } from './styles';

const CircleWithText = ({
  fontColor = Colors.primary,
  text,
  background = 'linear-gradient(to bottom, #d4e6b8, #c2da9d, #afcd81, #9cc166, #89b54a);',
  image,
}) => {
  return (
    <Container fontColor={fontColor}>
      <Circle background={background}>
        <img alt="img" src={image} />
      </Circle>
      <Text fontColor={fontColor}>{text}</Text>
    </Container>
  );
};

export default CircleWithText;
