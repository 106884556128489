export function getLabelByStatus(status) {
  console.log(2, status)
  if (status === 'PENDING') {
    return {
      status: 'Aguardando pagamento',
      color: '#2db7f5'
    };
  }
  if (status === 'RECEIVED') {
    return {
      status: 'Pagamento recebido',
      color: '#87d068'
    };
  }
  if (status === 'OVERDUE') {
    return {
      status: 'Vencido',
      color: '#f50'
    };
  }
  if (status === 'REFUNDED') {
    return {
      status: 'Estornada',
      color: '#ffa500'
    };
  }
  if (status === 'CONFIRMED') {
    return {
      status: 'Pagamento confirmado',
      color: '#87d068'
    };
  }
  if (status === 'AWAITING_RISK_ANALYSIS') {
    return {
      status: 'Pagamento em análise',
      color: '#2db7f5'
    };
  }
  return {
    status: 'Em aberto',
    color: '#2db7f5'
  };
}

export const PaymentTypesKey = {
  c: 'CREDIT_CARD',
  p: 'PIX',
  b: 'BOLETO'
}
