import api from "./api";
import { Filters } from "utils";

const findUserRequest = (id) => api.get(`api/user/show/${id}`);

const updateCredentialsRequest = (params, body) => {
  return api.post("api/user/comarcaParoquia", body);
};

const validateUserPendentRequest = (params) => {
  return api.get("api/user/pendent", params);
};

const findUsersRequest = (params) => {
  return api.get("api/user", params);
};

const findMaritalStatusRequest = () => {
  return api.get("api/estadoCivil");
};

const deleteUserRequest = (params, id) => api.delete(`api/user/deletar/${id}`);

const createUserRequest = (params, user) => {
  return api.post("api/user/criar", {
    ...user,
    email: user.email.trim(),
    nu_cep: Filters.removeMaskCEP(user.nu_cep),
    nu_celular: Filters.removeMaskCEP(user.nu_celular),
    nu_cpf: Filters.removeMaskCPF(user.nu_cpf),
  });
};

const updateUserRequest = (param, user) =>
  api.put("api/user/atualizar", {
    ...user,
    nu_cep: Filters.removeMaskCEP(user.nu_cep),
    nu_celular: Filters.removeMaskTEL(user.nu_celular),
    nu_cpf: Filters.removeMaskCPF(user.nu_cpf),
  });

const registerUserRequest = (user) => {
  return api.post("api/users/registro", user);
};

export {
  validateUserPendentRequest,
  findMaritalStatusRequest,
  findUserRequest,
  findUsersRequest,
  deleteUserRequest,
  createUserRequest,
  updateUserRequest,
  registerUserRequest,
  updateCredentialsRequest,
};
