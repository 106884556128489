import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import QuestionForm from '../Form';
import useFetch from '../../../../hooks/useFetch';
import { updateCourseRequest } from '../../../../services/course';


const BackofficeQuestionEdit = () => {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching, data }, createUser] = useFetch(
    updateCourseRequest,
    '',
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Edição de Questão" background>
      <Spin spinning={isFetching}>
          <QuestionForm
            initialValues={{}}
            handleSubmitForm={createUser}
            handleCancel={goBack}
          />
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeQuestionEdit;
