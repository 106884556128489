import * as Yup from 'yup';

export const CourseValidationSchema = Yup.object().shape({
  email: Yup.string().trim().required('O campo E-mail é obrigatório.'),
  name: Yup.string().trim().required('O campo Nome Completo é obrigatório.'),
  document: Yup.string().trim().required('O campo Cpf é obrigatório.'),
  phone: Yup.string().trim().required('O campo Telefone é obrigatório.'),
  password: Yup.string()
    .trim()
    .min(4, 'A senha precisa ter no mínimo 4 caracteres.'),
  passwordConfirmation: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'As senhas precisam ser iguais.'),
});
