import React from "react";
import { BarContainer, Fill, LabelContainer, PercentageBar } from "./styles";
import { Text } from "../../common";
import { Colors, Metrics } from "../../configs";

export default function ProgressBar({ pct, width, heightSize, fontSize }) {
  return (
    <BarContainer width={width}>
      <LabelContainer heightSize={heightSize} pct={pct}>
        <Text
          size={fontSize || Metrics.fontSize.xxxsm}
          color={Colors.greenCheck}
        >
          {`${Math.round((pct * 10) / 10)}%`}
        </Text>
      </LabelContainer>
      <PercentageBar heightSize={heightSize}>
        <Fill pct={pct} />
      </PercentageBar>
    </BarContainer>
  );
}
