const store = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

const get = key => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return value;
  } catch (e) {
    // error
    return null;
  }
};

const clear = key => {
  localStorage.removeItem(key);
};

const getToken = () => {
  const token = get('studium#token');
  return token;
};

const clearToken = () => {
  clear('studium#token');
};

const setToken = token => {
  store('studium#token', token);
};

export default {
  get,
  getToken,
  clearToken,
  clear,
  setToken,
  store,
};
