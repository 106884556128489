import { Colors, Metrics } from 'configs';
import { Button, Col } from 'common';
import { Collapse, Input, Modal, Spin } from 'antd';
import useFetch from '../../../../../hooks/useFetch';
import { createCardRequest, findCardsRequest } from '../../../../../services/card';
import React, { useCallback, useState } from 'react';
import { getIconByFlag } from '../../../../PaymentMethod/util';
import { Row } from '../../../../../components';
import Form from './Form';
import { Toast } from '../../../../../utils';
import { CheckboxWithStyle as Checkbox } from 'common/FormCheckbox/styles';
import { findTopicsByCourseRequest } from 'services/topics';

const { Panel } = Collapse;

const SelectOrCreateCard = ({
  isFetching,
  visible,
  returnFn,
  closeFn,
  promotionalCode,
  installments,
  cursoId,
  has_module,
  topic_id
}) => {
  const [activeKey, setActiveKey] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);
  const [code, setCode] = useState('');
  const [{ isFetching: isFetchingCards, data: cards }, findCards] = useFetch(
    findCardsRequest,
    null,
    true,
    []
  );
  const [{ isFetching: isFetchingCreateCard }, createCard] = useFetch(
    createCardRequest,
    null,
    null,
    null,
    null,
    () => {
      findCards();
      setActiveKey('');
    }
  );

  function onSubmit(values) {
    const data = {
      ...values,
      numero: values.numero && values.numero.replaceAll(' ', ''),
      mes_validade: values.validade && values.validade.split('/')[0],
      ano_validade: values.validade && values.validade.split('/')[1],
    };
    createCard(data);
  }

  const handleProceed = useCallback(async () => {
    if (code) {
      let topico_id = undefined;

      if(has_module){
        const course = await findTopicsByCourseRequest(cursoId)


        const topicPayload = topic_id ?? course.data[0].id

        if(!topicPayload) {
          Toast.showErrorMessage("Erro ao gerar pagamento, tente novamente, se o problema persistir entre em contato conosco!");

          return;
        }

        topico_id = topicPayload;
      }
      returnFn({
        cursoId,
        qtdParcela: installments,
        codPromo: promotionalCode,
        tipoCobranca: 'CREDIT_CARD',
        cartaoId: selectedCard.id,
        ccv: code,
        topico_id
      });
    } else {
      Toast.showErrorMessage('Por favor, informe o CVV para prosseguir.')
    }
  }, [returnFn, code, promotionalCode, selectedCard, installments, cursoId, has_module]);

  return (
    <Modal
      onCancel={closeFn}
      visible={visible}
      footer={null}>
      <Col>
        <Col>
          <span style={{ color: Colors.primary, fontSize: Metrics.fontSize.sm, marginBottom: Metrics.spacingSM }}>Cartões cadastrados</span>
          {!cards || cards.length === 0 &&
            <>
              <span><b>Atenção:</b></span>
              <span>Você não possui nenhum cartão cadastrado.</span>
            </>
          }
          {cards && cards.length > 0 && <>
            <span><b>Selecione o cartão desejado e informe o cvv:</b></span>
            <ul>
              {cards.map((c) => (
                <li key={c.id}
                  onClick={() => {
                    setCode(null);
                    setSelectedCard(sc => sc && sc.id === c.id ? false : c);
                  }}
                  style={{
                    opacity: selectedCard && selectedCard.id !== c.id ? 0.5 : 1,
                    marginLeft: Metrics.spacingMD,
                    marginTop: Metrics.spacingMD,
                    width: '90%',
                    cursor: 'pointer',
                    transition: 'all 0.4 ease'
                  }}>
                  <Row style={{ alignItems: 'center' }}>
                    <Checkbox style={{ marginRight: 15 }} checked={selectedCard && selectedCard.id === c.id} />
                    {getIconByFlag(30, 30, c.bandeira)}
                    <span
                      style={{
                        color: Colors.primary,
                        marginLeft: Metrics.spacingSM
                      }}>
                      {c.apelido || `•••• •••• •••• ${c.numero_final}`}
                    </span>
                    {c.padrao &&
                      <span style={{ marginLeft: Metrics.spacingSM }}>{` (padrão)`}</span>
                    }
                    {/*{selectedCard && selectedCard.id === c.id &&
                    <CheckOutlined
                      style={{
                        color: Colors.primary,
                        fontSize: Metrics.fontSize.sm,
                        marginLeft: Metrics.spacingSM
                      }}/>
                    }*/}
                  </Row>
                </li>
              ))}
            </ul>
          </>}
        </Col>
        {cards && cards.length > 0 && selectedCard && <Input.Password
          style={{
            width: '50%',
            marginBottom: Metrics.spacingMD,
            marginLeft: Metrics.spacingSM
          }}
          maxLength={4}
          placeholder="Informe o CVV"
          value={code}
          disabled={!selectedCard}
          onChange={(e) => setCode(e.target.value)}
          required />}
        <Col style={{ marginTop: Metrics.spacingSM }}>
          <Collapse accordion
            activeKey={activeKey}
            onChange={(e) => setActiveKey(e)}>
            <Panel
              header="Cadastrar novo cartão"
              key="1">
              {
                !isFetchingCreateCard &&
                <Form initialValues={{}} onSubmit={onSubmit} />
              }
            </Panel>
          </Collapse>
        </Col>
        <Button
          disabled={!code}
          onClick={handleProceed}
          style={{ marginTop: Metrics.spacingSM }}>Efetuar Pagamento</Button>
      </Col>
      {isFetching &&
        <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </Row>
      }
    </Modal>
  );
}

export default SelectOrCreateCard;
