import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';
import { createCardRequest } from 'services/card';
import { Col, Row } from 'common';
import { Colors, Metrics } from 'configs';
import { getIconByFlag } from 'features/PaymentMethod/util';
import { Collapse, Input } from 'antd';
import CardSelectorForm from 'features/FinancialData/components/CardSelectorForm';
import { CheckboxWithStyle as Checkbox } from 'common/FormCheckbox/styles';

const {Panel} = Collapse;


export default function SelectOrCreateCard({cards, findCards, code, setCode, selectedCard, setSelectedCard}) {
  const [activeKey, setActiveKey] = useState('');

  const [{isFetching: isFetchingCreateCard}, createCard] = useFetch(
    createCardRequest,
    null,
    null,
    null,
    null,
    () => {
      findCards();
      setActiveKey('');
    }
  );

  function onSubmit(values) {
    const data = {
      ...values,
      numero: values.numero && values.numero.replaceAll(' ', ''),
      mes_validade: values.validade && values.validade.split('/')[0],
      ano_validade: values.validade && values.validade.split('/')[1],
    };
    createCard(data);
  }

  return (
    <Col style={{width: '100%'}}>
      <Col>
        <span style={{color: Colors.primary, fontSize: Metrics.fontSize.sm, marginBottom: Metrics.spacingSM}}>Cartões cadastrados</span>
        {!cards || cards.length === 0 &&
        <>
          <span><b>Atenção:</b></span>
          <span>Você não possui nenhum cartão cadastrado.</span>
        </>
        }
        {cards && cards.length > 0 && <>
          <span><b>Selecione o cartão desejado e informe o cvv:</b></span>
          <ul>
            {cards.map((c) => (
              <li key={c.id}
                  onClick={() => {
                    setCode(null);
                    setSelectedCard(c);
                  }}
                  style={{
                    opacity: selectedCard && selectedCard.id !== c.id ? 0.5 : 1,
                    marginLeft: Metrics.spacingMD,
                    marginTop: Metrics.spacingMD,
                    width: '90%',
                    cursor: 'pointer',
                    transition: 'all 0.4 ease'
                  }}>
                <Row style={{alignItems: 'center'}}>
                  <Checkbox style={{marginRight: 15}} checked={selectedCard && selectedCard.id === c.id}/>
                  {getIconByFlag(30, 30, c.bandeira)}
                  <span
                    style={{
                      color: Colors.primary,
                      marginLeft: Metrics.spacingSM
                    }}>
                      {c.apelido || `•••• •••• •••• ${c.numero_final}`}
                    </span>
                  {c.padrao &&
                  <span style={{marginLeft: Metrics.spacingSM}}>{` (padrão)`}</span>
                  }
                  {/*{selectedCard && selectedCard.id === c.id &&
                  <CheckOutlined
                    style={{
                      color: Colors.primary,
                      fontSize: Metrics.fontSize.sm,
                      marginLeft: Metrics.spacingSM
                    }}/>
                  }*/}
                </Row>
              </li>
            ))}
          </ul>
        </>}
      </Col>
      {cards && cards.length > 0 && selectedCard && <Input.Password
        style={{
          width: '50%',
          marginBottom: Metrics.spacingMD,
          marginLeft: Metrics.spacingSM
        }}
        placeholder="Informe o CVV"
        value={code}
        maxLength={4}
        disabled={!selectedCard}
        onChange={(e) => setCode(e.target.value)}
        required/>}
      <Col style={{marginTop: Metrics.spacingSM}}>
        <Collapse accordion
                  activeKey={activeKey}
                  onChange={(e) => setActiveKey(e)}>
          <Panel
            header="Cadastrar novo cartão"
            key="1">
            {
              !isFetchingCreateCard &&
              <CardSelectorForm initialValues={{}} onSubmit={onSubmit}/>
            }
          </Panel>
        </Collapse>
      </Col>
    </Col>
  );
}
