import api from "./api";

const findAllRequest = () => {
  return api.get(`api/notificacoes`);
};

const markAllReadedRequest = () => {
  return api.post(`api/notificacoes/markallreaded`);
};

export { findAllRequest, markAllReadedRequest };
