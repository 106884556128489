import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import TrainingForm from '../Form';
import useFetch from '../../../../hooks/useFetch';
import { findTrainingRequest, updateTrainingRequest } from '../../../../services/training';


const BackofficeTrainingEdit = () => {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching: isFetchingUser, data: user }] = useFetch(findTrainingRequest, id, true, null);
  const [{ isFetching, data }, createUser] = useFetch(
    updateTrainingRequest,
    '',
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Edição de Treinamento" background>
      <Spin spinning={isFetching}>
        {user && (
          <TrainingForm
            initialValues={user}
            handleSubmitForm={createUser}
            handleCancel={goBack}
          />
        )}
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeTrainingEdit;
