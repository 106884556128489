import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { findCourseByIdRequest } from "services/course";
import { AnimatedButton, Text, Whitespace } from "common";
import { CourseFeatures } from "components";
import useFetch from "hooks/useFetch";
import CourseBgPlaceholder from "assets/images/courseBg.png";
import { Colors, Metrics } from "configs";
import Filters from "utils/Filters";
import Moment from "moment";
import { ExclamationCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import PageTemplate from "template/PageTemplate";
import { validateUserPendentRequest } from "services/user";
import { Modal } from "utils";

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    marginTop: "-25px",
  },
  icon: {
    fontSize: Metrics.fontSize.lg,
    color: Colors.white,
  },
  headerNavigation: {
    position: "absolute",
    width: "100%",
    top: 0,
    display: "flex",
    padding: Metrics.spacing.md,
    justifyContent: "flex-end",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: Metrics.spacing.lg,
    marginTop: "6vw",
  },
  flexRow: {
    display: "flex",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    maxWidth: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    shadowColor: Colors.blk,
    textShadow: "1px 2px 2px #00000029",
  },
  desc: {
    display: "flex",
    padding: Metrics.spacing.md,
    flexDirection: "column",
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    margin: Metrics.spacing.sm,
    marginRight: 0,
    width: "100%",
  },
  priceLine: {
    display: "flex",
    justifyContent: "flex-end",
  },
  priceBlock: {
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 1px 8px gray",
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    minWidth: "320px",
    padding: `${Metrics.spacing.lg} ${Metrics.spacing.md}`,
  },
  features: {
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    boxShadow: "1px 2px 2px #00000029",
    display: "flex",
    minWidth: "350px",
    margin: Metrics.spacing.sm,
    marginLeft: 0,
  },
  headerBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 3,
    color: Colors.white,
  },
};

const CourseDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [{ data }] = useFetch(findCourseByIdRequest, id, true, [], null);

  const calculateDiscount = useMemo(() => {
    return data.pr_desconto
      ? parseInt(data.vl_curso) -
          (parseInt(data.vl_curso) * parseInt(data.pr_desconto)) / 100
      : data.vl_curso;
  }, [data]);

  const [
    { isFetching: isFetchingValidateUserPendent, data: userPendentData },
    validateUserPendent,
  ] = useFetch(validateUserPendentRequest, null, false, null, null, (e) => {
    const jaPossuiCurso = data["__meta__"].billings_count > 0;
    if (e.data.pendent) {
      Modal.openModal({
        title: "Atenção",
        icon: ExclamationCircleOutlined,
        description:
          "Para realizar a compra, você precisa completar o seu cadastro de usuário. Clique em “Atualizar meu perfil” para continuar.",
        okText: "Atualizar meu perfil",
        cancelText: "Fechar",
        okType: "",
        onOk: () =>
          history.push(`/user-credentials?returnTo=/course-buy/${id}`),
      });
    // } else if (jaPossuiCurso) {
    //   Modal.openCountdownModal({
    //     time: 10,
    //     title: "Atenção",
    //     content:
    //       "Você já possui cobrança gerada para este curso. Vamos direcioná-lo automaticamente para página de informações financeiras. Lá você pode gerar a segunda-via de boletos para pagamento. Aguarde [time] segundos.",
    //     okText: "Ir agora",
    //     replace: true,
    //     onTimeOver: () => history.push("/financial-data"),
    //   });
    } else {
      history.push(`/course-buy/${id}`);
    }
  });

  function handleBuy() {
    validateUserPendent();
  }

  return (
    <PageTemplate
      containerStyle={styles.container}
      contentStyle={{ zIndex: 2 }}
      backButtonStyle={styles.backButton}
      beforeContent={
        <div
          style={{
            width: "100%",
            height: "70%",
            position: "absolute",
            top: 0,
            backgroundImage: data
              ? `url(${data.ds_imagem})`
              : `url(${CourseBgPlaceholder})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginTop: "-25px",
          }}
        />
      }
    >
      <div style={styles.headerNavigation}>
        <div style={styles.headerBtnBlock}>
          <Text color={Colors.white} bold>
            Compartilhar
          </Text>
          <Whitespace width={Metrics.spacing.sm} />
          <ShareAltOutlined style={styles.icon} />
        </div>
      </div>
      <div style={styles.headerBlock}>
        <div style={styles.priceLine}>
          <div style={styles.priceBlock}>
            <div style={styles.flexCol}>
              <div style={styles.flexRow}>
                <Text color={Colors.greenCheck} size={Metrics.fontSize.md}>
                  {data.vl_curso === 0
                    ? "Curso livre"
                    : Filters.convertMoneyTextMask(calculateDiscount)}
                </Text>
                <Whitespace width={Metrics.spacing.sm} />
                {data.pr_desconto !== 0 && data.pr_desconto && (
                  <Text
                    color={Colors.secondary}
                    size={Metrics.fontSize.xsm}
                    lineThrough
                  >
                    {data.vl_curso !== 0 &&
                      Filters.convertMoneyTextMask(data.vl_curso)}
                  </Text>
                )}
              </div>
              {data.pr_desconto !== 0 && data.pr_desconto && (
                <Text
                  color={Colors.secondary}
                  size={Metrics.fontSize.xsm}
                >{`Desconto de ${data.pr_desconto}% - válido até ${Moment(
                  data.dt_validade_desconto
                ).format("DD-MM-YYYY")}`}</Text>
              )}
            </div>
            <div>
              <AnimatedButton onClick={handleBuy}>Comprar</AnimatedButton>
            </div>
          </div>
        </div>
        <div style={styles.title}>
          <Text
            color={Colors.white}
            bold
            size={Metrics.fontSize.xxlg}
            text-shadow={Colors.blk}
            style={{
              shadowColor: "#000000",
              textShadow: "2px 1px 1px #000000",
            }}
          >
            {data.ds_titulo}
          </Text>
          <Whitespace height={Metrics.spacing.sm} />
          <Text
            color={Colors.white}
            size={Metrics.fontSize.lg}
            style={{
              shadowColor: "#000000",
              textShadow: "2px 1px 1px #000000",
              // backgroundColor:"#000000",
              // backgroundColor: "#00000061",
            }}
          >
            {data.ds_resumo}
          </Text>
        </div>
      </div>
      <div style={styles.flexRow}>
        <div style={styles.features}>
          <CourseFeatures data={data} />
        </div>
        <div style={styles.desc}>
          <Text size={Metrics.fontSize.lg} color={Colors.primary} bold>
            Descrição
          </Text>
          <Whitespace height={Metrics.spacing.sm} />
          <Text size={Metrics.fontSize.xsm} color={Colors.secondary}>
            {data.ds_curso}
          </Text>
        </div>
      </div>
    </PageTemplate>
  );
};

export default CourseDetail;
