import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;

  strong {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 22px;
  }
`;

export const CertificateContainer = styled.div`
  row-gap: 20px;
  display: flex;
  margin: 0 auto;
  max-width: 800px;
  align-items: center;
  flex-direction: column;

  .content {
    max-width: 800px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 500px;
  padding: 60px;
  display: flex;
  max-width: 800px;
  align-items: center;
  background-size: cover;
  flex-direction: column;
  background-repeat: no-repeat;

  .title{
    color: #000;
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }

  .subTitle{
    color: #000;
    width: 100%;
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }

  .textInfo {
    color: #000;
    width: 400px;
    display: block;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    font-family: 'Montserrat', sans-serif;

    span {
      font-size: 10px;
      font-weight: 900;
      width: max-content;
    }
  }

  .textValue {
    color: #000;
    font-size: 14px;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;
  }

  .topic {
    color: #000;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    max-width: 250px;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  margin-top: 30px;
  column-gap: 20px;
  align-items: center;
  justify-content: center;

  .logo {
    width: 50px;
  }

  span {
    color: #000;
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const SignatureContainer = styled.div`
  display: flex;
  margin-top: 90px;
  padding-left: 60px;
  column-gap: 20px;

  .signature {
    display: flex;
    flex-direction: column;

    img {
      width: 160px;
      height: 45px;
    }
  }

  .signatureName {
    color: #000;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
