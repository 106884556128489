import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTemplate from "template/PageTemplate";
import { Spin } from "antd";
import useFetch from "hooks/useFetch";
import {
  findMaritalStatusRequest,
  updateMaritalStatusRequest,
} from "services/maritalStatus";
import MaritalStatusForm from "../Form";

export default function BackofficeMaritalStatusEdit() {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching: isFetchingFind, data: maritalStatus }] = useFetch(
    findMaritalStatusRequest,
    id,
    true,
    null
  );
  const [{ isFetching: isFetchingUpdate, data }, updateRequest] = useFetch(
    updateMaritalStatusRequest,
    "",
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Edição de Estado Civil" background>
      <Spin spinning={isFetchingFind || isFetchingUpdate}>
        {maritalStatus && (
          <MaritalStatusForm
            initialValues={maritalStatus}
            handleSubmitForm={updateRequest}
            handleCancel={goBack}
          />
        )}
      </Spin>
    </PageTemplate>
  );
}
