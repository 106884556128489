import { Colors, Metrics } from "configs";
import { Text } from "common";
import { CertificateCardSm } from "components";
import useFetch from "hooks/useFetch";
import { Spin } from "antd";
import PageTemplate from "template/PageTemplate";
import { getAllConcludedCoursesUserRequest } from "services/course";

const Certificates = () => {
  const [{ isFetching, data }] = useFetch(
    getAllConcludedCoursesUserRequest,
    null,
    true,
    []
  );

  if (isFetching) {
    return (
      <div style={styles.spinContainer}>
        <Spin />
      </div>
    );
  }

  return (
    <PageTemplate contentStyle={styles.outterContainer}>
      <Text bold size={Metrics.fontSize.md}>
        Meus certificados
      </Text>
      <Text
        size={Metrics.fontSize.xsm}
        color={Colors.secondary}
      >{`${data.length} certificados`}</Text>
      <div style={styles.container}>
        {data.map((item, i) => (
          <CertificateCardSm item={item} key={i} />
        ))}
      </div>
    </PageTemplate>
  );
};

const styles = {
  outterContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: Metrics.spacing.md,
  },
  spinContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

export default Certificates;
