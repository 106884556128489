import { useAuthLogout, useAuthUser } from "store/hooks/auth";
import { Colors, Metrics } from "configs";
import { BiUser, BiMoney } from "react-icons/bi";
import { CardContainer } from "./styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as HelpIcon } from "assets/icons/help.svg";
import { ReactComponent as AlertIcon } from "assets/icons/alert.svg";
import { ReactComponent as CertificateIcon } from "assets/icons/certificate.svg";
import { ReactComponent as PadLockIcon } from "assets/icons/padlock.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import { ReactComponent as CreditCardIcon } from "assets/icons/credit-card.svg";
import { Modal } from "utils";

const Profile = () => {
  const { push } = useHistory();
  const user = useAuthUser();
  const logout = useAuthLogout();

  const handleNavigate = (route) => {
    push(route);
  };

  return (
    <div style={styles.container}>
      <span style={styles.userName}>{user.username}</span>
      <div style={styles.userImageContainer}>
        <BiUser style={styles.userIcon} />
        {/*<img style={styles.userImage} alt="img" src={user.image} />*/}
      </div>
      <CardContainer>
        <div onClick={() => handleNavigate("certificates")}>
          <CertificateIcon className="icon" />
          <span className="text">Meus certificados</span>
        </div>
        <div onClick={() => handleNavigate("payment-method")}>
          <CreditCardIcon className="icon" />
          <span className="text">Cadastro De Cartões</span>
        </div>
        <div onClick={() => handleNavigate("financial-data")}>
          <BiMoney style={styles.moneyIcon} />
          <span className="text">Financeiro</span>
        </div>
        <div onClick={() => handleNavigate("change-password")}>
          <PadLockIcon className="icon" />
          <span className="text">Alterar senha</span>
        </div>
        <div onClick={() => handleNavigate("user-credentials")}>
          <BiUser className="icon" />
          <span className="text">Alterar perfil</span>
        </div>
        <div onClick={() => handleNavigate("help")}>
          <HelpIcon className="icon" />
          <span className="text">Ajuda</span>
        </div>
        <div onClick={() => handleNavigate("privacy-policy")}>
          <AlertIcon className="icon" />
          <span className="text">Políticas de privacidade</span>
        </div>
        <div onClick={() => logout()}>
          <LogoutIcon className="icon" />
          <span className="text">Sair</span>
        </div>
      </CardContainer>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "3vw",
  },
  userName: {
    fontSize: Metrics.fontSize.lg,
    fontWeight: 700,
    color: "#415CA7",
    marginBottom: Metrics.spacingXMD,
  },
  userImageContainer: {
    height: "95px",
    minHeight: "95px",
    width: "95px",
    minWidth: "95px",
    borderRadius: "50%",
    padding: Metrics.spacingMinimun,
    backgroundColor: Colors.white,
    boxShadow: "1px 2px 3px #00000029",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    marginBottom: Metrics.spacingXXLG,
  },
  userIcon: {
    fontSize: "70px",
    color: Colors.secondary,
  },
  moneyIcon: {
    fontSize: "70px",
    color: Colors.primary,
  },
  userImage: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
};

export default Profile;
