import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { findCoursesRequest, findCoursesSearchRequest } from 'services/course';
import { CourseCard } from "components";
import useFetch from "../../hooks/useFetch";
import NoData from "common/NoData";
import { Colors, Metrics } from "../../configs";
import { SliderContent } from "../Landing/components/Courses/styles";
import { useCommonSearch } from "store/hooks/common";

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
};

const Courses = () => {
  const history = useHistory();
  let searchValue = useCommonSearch();
  const [{ data }, fetch] = useFetch(findCoursesSearchRequest, "", false, [], null);

  useEffect(() => {
    fetch({ search: searchValue });
  }, [searchValue]);

  return (
    <div style={styles.container}>
      {data.map((item, i) => (
        <CourseCard
          item={item}
          key={i}
          onClick={() => history.push(`/course-detail/${item.id}`)}
        />
      ))}
      {(!data || data.length === 0) && <NoData />}
    </div>
  );
};

export default Courses;
