import Flex from "common/Flex";
import { Button, Space, Spin } from "antd";
import { Colors, Metrics } from "configs";
import {LoadingOutlined} from '@ant-design/icons'

const FormButtons = ({
  bt1,
  bt2,
  justifyContent = "flex-end",
  alignItems = "center",
}) => {
  return (
    <Flex
      justifyContent={justifyContent}
      alignItems={alignItems}
      marginTop={Metrics.spacingLG}
    >
      <Space>
        {bt1 && (
          <Button
            type="text"
            onClick={bt1.onClick}
            disabled={bt1.disabled}
            style={{ borderRadius: Metrics.defaultRadius }}
          >
            {bt1.loading ? (<Spin indicator={<LoadingOutlined style={{ fontSize: 18, color: '#fff' }} spin />} />) : bt1.label}
          </Button>
        )}
        {bt2 && (
          <Button
            type="primary"
            onClick={bt2.onClick}
            disabled={bt2.disabled}
            style={{
              backgroundColor: Colors.primary,
              borderColor: Colors.primary,
              borderRadius: Metrics.defaultRadius,
              ...(bt1 && bt1.style),
            }}
          >
            {bt2.loading ? (<Spin indicator={<LoadingOutlined style={{ fontSize: 18, color: '#fff' }} spin />} />) : bt2.label}
          </Button>
        )}
      </Space>
    </Flex>
  );
};

export default FormButtons;
