import { useMemo, useState } from "react";
import { Layout as LayoutAntd, Menu } from "antd";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import logo from "assets/images/logo-line-novo.png";
import {
  ContentStyled,
  HeaderStyled,
  IconWithText,
  ItemsMenu,
  Layout,
  LogoContainer,
  MenuItem,
  SearchMenu,
  SiderStyled,
} from "./styles";
import { BiBook, BiUser, BiMoney } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import SearchInput from "features/Layout/components/SearchInput";
import { Colors } from "configs";
import { Modal } from "utils";
import { useAuthUser } from "store/hooks/auth";
import AdministrativeMenuStructure from "./components/MenuStructure";
import { useCommonUpdateSearchHandler } from "store/hooks/common";
import useDebounce from "hooks/useDebounce";
import Notification from "./components/Notification";

const Header = () => {
  const updateSearchHandler = useCommonUpdateSearchHandler();
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const [current, setCurrent] = useState(path === "*" ? "/my-courses" : path);
  const [searchState, setSearchState] = useState("");

  const handleClick = (e) => {
    if (e.key) {
      setCurrent(e.key);
      push(e.key);
    }
  };

  const handleChangeSearchInput = (newValue) => {
    setSearchState(newValue);
  };

  const handleValidateDiscount = useDebounce(
    searchState,
    updateSearchHandler,
    400,
    true
  );

  const menuItems = useMemo(() => {
    return (
      <ItemsMenu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
      >
        <MenuItem key="/course">
          <IconWithText>
            <BiBook style={styles.icon} />
            <span>Cursos</span>
          </IconWithText>
        </MenuItem>

        <MenuItem key="/my-courses">
          <IconWithText>
            <AiOutlineHome style={styles.icon} />
            <span>Meus Estudos</span>
          </IconWithText>
        </MenuItem>

        <MenuItem key="/profile">
          <IconWithText>
            <BiUser style={styles.icon} />
            <span>Perfil</span>
          </IconWithText>
        </MenuItem>

        <MenuItem key="/financial-data">
          <IconWithText>
            <BiMoney style={styles.icon} />
            <span>Financeiro</span>
          </IconWithText>
        </MenuItem>

        <MenuItem key="">
          <Notification />
        </MenuItem>
      </ItemsMenu>
    );
  }, []);

  return (
    <HeaderStyled>
      <LogoContainer>
        <img
          src={logo}
          alt="logo"
          style={styles.logo}
          onClick={() => push("/")}
        />
      </LogoContainer>
      {menuItems}
      <SearchMenu>
        <SearchInput value={searchState} onChange={handleChangeSearchInput} />
      </SearchMenu>
    </HeaderStyled>
  );
};

const SystemLayout = ({ children }) => {
  const { admin } = useAuthUser();
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <Layout>
      <Header />
      <LayoutAntd>
        {admin && (
          <SiderStyled breakpoint="lg" collapsedWidth="80px">
            <Menu theme="dark" mode="inline" defaultSelectedKeys={["4"]}>
              {AdministrativeMenuStructure.map((menu, i) => (
                <Menu.Item
                  style={
                    pathname.indexOf(menu.route) != -1
                      ? styles.selectedRoute
                      : styles.notSelectedRoute
                  }
                  key={i}
                  icon={menu.icon}
                  onClick={() => history.push(menu.route)}
                >
                  {menu.title}
                </Menu.Item>
              ))}
            </Menu>
          </SiderStyled>
        )}
        <ContentStyled>{children}</ContentStyled>
      </LayoutAntd>
    </Layout>
  );
};

const styles = {
  icon: {
    fontSize: "25px",
    color: Colors.secondary,
  },
  logo: {
    cursor: "pointer",
    maxWidth: "130px",
  },
  logoDiocese: {
    cursor: "pointer",
    maxWidth: "100px",
  },
  moneyIcon: {
    fontSize: "25px",
    color: Colors.primary,
  },
  selectedRoute: { backgroundColor: Colors.primary, color: Colors.white },
  notSelectedRoute: { backgroundColor: Colors.white, color: Colors.primary },
};

export default SystemLayout;
