import React from "react";
import { Colors } from "configs";
import { ButtonStyled, Container } from "./styles";

const AnimatedButton = ({
  color = Colors.primary,
  children,
  textAlign = "center",
  bolder = false,
  width,
  style,
  uppercase,
  onClick,
}) => {
  return (
    <Container>
      <ButtonStyled
        uppercase={uppercase}
        width={width}
        textAlign={textAlign}
        color={color}
        bolder={bolder}
        style={style}
        onClick={onClick}
      >
        {children}
      </ButtonStyled>
    </Container>
  );
};

export default AnimatedButton;
