import { Layout, Menu } from "antd";
import { Metrics, Colors } from "configs";
import styled from "styled-components";

export const HeaderStyled = styled(Layout.Header)`
  padding: ${Metrics.spacing.sm} ${Metrics.spacing.xxxlg};
  background-color: ${Colors.white};
  min-height: 100px;
  max-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: inherit;
  position: fixed;
  width: 100%;
  z-index: 1000;
  ${({responsive}) => responsive ? `
    border-bottom: 1px solid #ddd;
  ` : ``};
  @media (max-width: 800px) {
    padding: 0 3%;

    //.logo-container {
    //  display: none;
    // }
  }
`;

export const HeaderText = styled.div`
  padding: 5px 10px;
  transition: 0.4s;

  ::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #41c0ba;
    transition: 0.4s;
    margin: 0 auto;
  }

  :hover {
    color: #41c0ba;
    cursor: pointer;
  }

  :hover::after {
    width: 100%;
  }

  span {
    padding: 0 20px;
    line-height: 25px;
    color: ${Colors.secondary};
  }
`;

export const MenuStyled = styled(Menu).attrs({
  mode: "inline",
  theme: "light",
})``;

export const ContentStyled = styled(Layout.Content)``;

export const Divider = styled.div`
  margin: 0 20px;
  height: 25px;
  align-self: center;
  border-left: 2px solid gray;
  opacity: 0.3;
`;

export const SiderStyled = styled(Layout.Sider)`
  padding-top: ${Metrics.spacing.lg};
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    //background-color: rgb(204, 204, 204);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${Colors.primary};
  }

  .ant-layout-sider-trigger {
    background-color: ${Colors.primary};
    color: ${Colors.secondary};
    border-top: 1px solid ${Colors.secondary};
  }
`;

export const Logo = styled.div`
  display: flex;
  width: 110px;
  height: 35px;

  svg {
    width: auto;
    height: 100%;
  }

  .rectangle {
    height: 28px;
    width: 28px;
    border: 4px solid #0073ff;
    border-radius: 10px;
    margin: 0 10px 0 5px;
  }

  .text-logo {
    height: 28px;
    width: 65px;
    color: #44444f;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;
