import styled from 'styled-components';
import { Metrics } from 'configs';

export const ButtonStyled = styled.button`
  color: #fff;
  text-align: center;
  font-size: 16px;
  background: #00b2a9;
  border: none;
  border-radius: 50px;
  width: ${({ width }) => width};
  margin: ${Metrics.spacing.min} 0;
  display: flex;
  align-items: center;
  height: 30px;

  span {
    margin: 0 auto;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 2px;
`;
