import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTemplate from "template/PageTemplate";
import { Spin } from "antd";
import CountyForm from "../Form";
import useFetch from "hooks/useFetch";
import { findCountyRequest, updateCountyRequest } from "services/county";
import { v4 as uuidv4 } from "uuid";

const BackofficeCountiesEdit = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [countyData, setCountyData] = useState();
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }

  const [{ isFetching: isFetchingCounty, data: county }] = useFetch(
    findCountyRequest,
    id,
    true,
    null
  );
  const [{ isFetching, data }, update] = useFetch(
    updateCountyRequest,
    "",
    false,
    null,
    null,
    () => {
      if (activeTab === "1" || activeTab === 1) {
        setActiveTab("2");
      } else if (activeTab === "2" || activeTab === 2) {
        history.goBack();
      }
    }
  );

  useEffect(() => {
    if (county) {
      const parishesMap = county.paroquias
        ? county.paroquias.map((p) => ({ ...p, _id: uuidv4() }))
        : [];
      setCountyData({ ...county, paroquias: parishesMap });
    }
  }, [county]);

  return (
    <PageTemplate title="Edição de Comarca" background>
      <Spin spinning={isFetching}>
        {countyData && (
          <CountyForm
            initialValues={countyData}
            handleSubmitForm={update}
            handleCancel={goBack}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeCountiesEdit;
