import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { Dropdown, Menu, Table } from "antd";
import { AiOutlineMore } from "react-icons/ai";
import PageTemplate from "../../../template/PageTemplate";
import { Constants, Modal } from "utils";
import { deleteCountyRequest, findCountiesRequest } from "services/county";

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  table: {
    width: "100%",
  },
};

const BackofficeCounties = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const history = useHistory();
  const [{ data }, fetch] = useFetch(findCountiesRequest, "", true, [], null);
  const [{ isFetching, data: deleteData }, deleteUser] = useFetch(
    deleteCountyRequest,
    "",
    false
  );
  const menu = useCallback(
    (record) => {
      return (
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => {
              history.push(`/backoffice/county/edit/${record.id}`);
            }}
          >
            Editar
          </Menu.Item>
          <Menu.Item
            key="1"
            danger
            onClick={() =>
              Modal.openDeleteModal({
                description: (
                  <span>
                    Deseja realmente deletar o usuário
                    <strong> {record.ds_titulo}</strong>?
                  </span>
                ),
                onOk: () => deleteUser(record.id),
                okText: "Sim",
                cancelText: "Não",
              })
            }
          >
            Excluir
          </Menu.Item>
        </Menu>
      );
    },
    [history]
  );
  const columns = [
    {
      title: "Nome",
      dataIndex: "nm_comarca",
      key: "nm_comarca",
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            trigger={["click"]}
          >
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  const handleFindUsers = useCallback(
    (page) => {
      setCurrentPage(page);
      fetch({ size: Constants.pageSize, page: page - 1 });
    },
    [fetch]
  );

  useEffect(() => handleFindUsers(1), []);

  return (
    <PageTemplate
      title="Comarca"
      backButton={false}
      newButton={{
        label: "Novo Comarca",
        onClick: () => history.push("/backoffice/county/create"),
      }}
    >
      <Table style={styles.table} columns={columns} dataSource={data} />
    </PageTemplate>
  );
};

export default BackofficeCounties;
