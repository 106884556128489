import { subMinutes, differenceInMinutes } from "date-fns";
import { useAuthUser } from "store/hooks/auth";
import { useHistory } from "react-router-dom";
import { Spin, Typography } from "antd";
import { Colors, Metrics } from "configs";
import handImg from "assets/images/hands.png";
import useFetch from "hooks/useFetch";
import { getRunningConcludedCoursesUserRequest } from "services/course";
import { Button } from "common";
const { Paragraph } = Typography;

export default function FirstAccess() {
  const { push } = useHistory();
  const user = useAuthUser();
  const created_at = new Date(user.created_at);
  const range = subMinutes(new Date(), 200);
  const difference = differenceInMinutes(created_at, range);
  const [{ isFetching, data }] = useFetch(
    getRunningConcludedCoursesUserRequest,
    "",
    true,
    null,
    null
  );

  if (isFetching || !data) {
    return <Spin style={{ display: "block" }} />;
  } else if (
    (data && data.andamento.length === 0 && data.concluido.length === 0) ||
    difference > 0
  ) {
    return (
      <>
        {texts.map((t, i) => (
          <Paragraph
            style={{
              fontSize: i === 0 ? 25 : 20,
              color: i === 0 ? Colors.primary : Colors.secondary,
              ...styles.texts,
            }}
            key={i}
          >
            {t}
          </Paragraph>
        ))}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            size={Metrics.fontSize.xlg}
            maxHeight="initial"
            onClick={() => push("course")}
          >
            Clique aqui para conferir todos os cursos da Studium
          </Button>
          <Paragraph
            style={{
              fontSize: 20, // Metrics.fontSize.lg,
              color: Colors.secondary,
              ...styles.texts,
            }}
          >
            Nessa área ficará registrado todo o seu progresso, histórico de
            cursos feitos, cursos em andamento e certificados disponíveis.
          </Paragraph>
        </div>
      </>
    );
  } else {
    push("/my-courses");
    return <></>;
  }
}

const styles = {
  texts: {
    fontWeight: "bold",
    textAlign: "center",
    margin: Metrics.spacingSM,
  },
  imageContainer: {
    textAlign: "center",
    marginBottom: Metrics.spacingXLG,
  },
  image: {
    width: "20vw",
    maxWidth: "250px",
    borderRadius: "50%",
    objectFit: "cover",
  },
};

const texts = [
  "Seja bem-vindo!",
  "Agradecemos o seu cadastro.",
  "A Studium quer promover aos católicos uma expansão de conhecimento sobre a Igreja Católica, Teologia, Bíblia, Pastorais e Movimentos e muito mais!",
  "Com formações práticas que ajudarão no dia a dia da sua vida na comunidade.",
];
