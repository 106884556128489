import api from "./api";

const findMaritalStatusListRequest = (params) => {
  return api.get(`api/estadoCivil`);
};

const findMaritalStatusRequest = (id) => {
  return api.get(`api/estadoCivil/${id}`);
};

const deleteMaritalStatusRequest = (param, id) => {
  return api.delete(`api/estadoCivil/${id}`);
};

const createMaritalStatusRequest = (param, maritalStatus) => {
  return api.post("api/estadoCivil", maritalStatus);
};

const updateMaritalStatusRequest = (param, maritalStatus) => {
  return api.put(`api/estadoCivil/${maritalStatus.id}`, maritalStatus);
};

const activateMaritalStatusRequest = (param, id) => {
  return api.post(`api/estadoCivil/active/${id}`);
};

export {
  findMaritalStatusListRequest,
  findMaritalStatusRequest,
  deleteMaritalStatusRequest,
  createMaritalStatusRequest,
  updateMaritalStatusRequest,
  activateMaritalStatusRequest,
};
