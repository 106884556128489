import { hot } from 'react-hot-loader/root';
import ReactDOM from 'react-dom';
import GlobalStyle from 'styles/global';
import Providers from 'Providers';
import Routes from 'routes';

function App() {
  return (
    <Providers>
      <GlobalStyle />
      <Routes />
    </Providers>
  );
}
const HotApp = process.env.NODE_ENV === 'development' ? hot(App) : App;
ReactDOM.render(<HotApp />, document.getElementById('root'));

