import { Route as ReactDOMRoute, Redirect } from "react-router-dom";
import persistedStore from "store";
import { useAuthAuthenticated } from "store/hooks/auth";

export const hasAnyAuthority = (roles) => {
  const authUser = persistedStore().store.getState().auth.user;
  return true;
  // if (!authUser) {
  //   return false;
  // }
  // if (!roles || roles.length === 0) {
  //   return true;
  // }
  // todo remove this

  // if (roles) {
  //   const authorities = [];
  //   authUser &&
  //     authUser.grupos &&
  //     authUser.grupos.forEach(g => {
  //       authorities.push(...g.permissoes?.map(p => p.nome));
  //     });
  //   const hasRole = authorities.filter(item => roles.indexOf(item) >= 0);
  //   return hasRole.length > 0;
  // }
  // return false;
};

const Route = ({
  isPrivate,
  roles,
  isLoginOrRegister,
  component: Component,
  ...rest
}) => {
  const authenticated = useAuthAuthenticated();
  const authorized = hasAnyAuthority(roles);
  const haveAccess = isPrivate && authenticated && authorized;
  const renderMethod = ({ location }) => {
    const redirect = (to) => {
      return (
        <Redirect
          to={{
            pathname: to,
            state: { from: location },
          }}
        />
      );
    };
    if (isLoginOrRegister && authenticated) {
      return redirect("/my-courses");
    }
    if (isPrivate && !authenticated) {
      return redirect("/login");
    }
    // if (!haveAccess && isPrivate) {
    //   return redirect('/403');
    // }
    return <Component />;
  };
  return <ReactDOMRoute {...rest} render={(props) => renderMethod(props)} />;
};

export default Route;
