import React from 'react';
import PageTemplate from 'template/PageTemplate';
import CourseWizard from '../CourseWizard';

const BackofficeCourseCreate = () => {
  return (
    <PageTemplate title="Criação de Curso">
        <CourseWizard />
    </PageTemplate>
  );
};

export default BackofficeCourseCreate;
