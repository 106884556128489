import PageTemplate from "template/PageTemplate";
import { Metrics } from "configs";
import { Typography } from "antd";
import texts from "./texts";
const { Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <PageTemplate
      title="Políticas de privacidade"
      contentStyle={styles.container}
    >
      {texts.map((t, i) => {
        if (typeof t === "string") {
          return (
            <Paragraph key={i} style={styles.text}>
              {t}
            </Paragraph>
          );
        }
        if (t.texts) {
          return t.texts.map((tx, i2) => (
            <Paragraph key={i2} style={styles.marginText}>
              {tx}
            </Paragraph>
          ));
        } else {
          return (
            <Paragraph
              key={i}
              style={{
                ...styles.text,
                fontWeight: t.bold ? "bold" : "300",
                fontStyle: t.subTitle && "italic",
                marginLeft: t.marginText && Metrics.spacingLG,
              }}
            >
              {t.text}
            </Paragraph>
          );
        }
      })}
    </PageTemplate>
  );
};

const styles = {
  container: {
    padding: "0 5vw",
  },
  text: {
    color: "#464646",
    fontSize: Metrics.fontSize.sm,
    fontWeight: 300,
  },
  marginText: {
    color: "#464646",
    fontSize: Metrics.fontSize.sm,
    fontWeight: 300,
    marginLeft: Metrics.spacingLG,
  },
};

export default PrivacyPolicy;
