import React, { useEffect, useState } from "react";
import PageTemplate from "template/PageTemplate";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import CountyForm from "../Form";
import useFetch from "../../../../hooks/useFetch";
import { createCountyRequest } from "services/county";

const BackofficeCountiesCreate = () => {
  const [title, setTitle] = useState("Criação de Comarca");
  const [activeTab, setActiveTab] = useState("1");
  const history = useHistory();
  const { goBack } = history;
  const initialValues = {
    nm_comarca: "",
  };
  const [{ isFetching, data }, create] = useFetch(
    createCountyRequest,
    "",
    false,
    null,
    null,
    () => {
      if (activeTab === "1" || activeTab === 1) {
        setActiveTab("2");
        setTitle("Edição de Comarca");
      } else if (activeTab === "2" || activeTab === 2) {
        history.goBack();
      }
    }
  );

  return (
    <PageTemplate title={title}>
      <Spin spinning={isFetching}>
        <CountyForm
          initialValues={data || initialValues}
          handleSubmitForm={create}
          handleCancel={goBack}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          enabledTab={data && data.id}
        />
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeCountiesCreate;
