import React from 'react';
import { Colors, Metrics } from 'configs';
import Filters from 'utils/Filters';
import { Typography } from 'antd';
import Moment from 'moment';

const {Paragraph} = Typography;

const styles = {
  courseCard: {
    position: 'relative',
    backgroundColor: 'white',
    margin: '0 25px 25px 0',
    padding: 20,
    borderRadius: Metrics.defaultRadius,
    width: '100%',
    minWidth: '400px',
    height: '220px',
    boxShadow: '1px 1px 6px #cacaca',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resume: {
    borderRadius: Metrics.defaultRadius,
    width: '100%',
  },
  price: {
    right: 12,
    top: 15,
  },
  img: {
    maxWidth: '150px',
    Width: '150px',
    maxHeight: '150px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '50%',
  },
  value: {
    color: Colors.primary,
    fontSize: Metrics.fontSize.sm,
  },
  infos: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: 30,
    height: '100%',
    justifyContent: 'space-around',
    paddingBlock: 10,
    flex: 1,
  },
};

export default function CourseCard({
                                     item, onClick = () => {
  }, courseValue, style
                                   }) {
  function handleClick() {
    if (item.fg_ativo && !(Moment(item.dt_fim).isBefore(new Date()) && item.fg_ativo)) {
      onClick();
    }
  }

  return (
    <div style={{...styles.courseCard, ...style}} onClick={() => handleClick()}>
      <div>
        <img src={item.ds_imagem} style={styles.img} alt=""/>
      </div>
      <div style={styles.infos}>
        <Paragraph
          ellipsis={{rows: 2}}
          style={{
            fontWeight: 'bold',
            margin: 0,
            fontSize: Metrics.fontSize.sm,
            color: Colors.blk,
          }}
        >
          {item.ds_titulo}
        </Paragraph>
        <div style={styles.resume}>
          <Paragraph
            ellipsis={{rows: 3}}
            style={{
              margin: 0,
              fontSize: Metrics.fontSize.xsm,
              color: Colors.blk,
            }}
          >
            {item.ds_resumo}
          </Paragraph>
        </div>
        <div style={styles.price}>
        <span style={styles.value}>
          {item.vl_curso === 0 && 'Curso livre'}
          {item.vl_curso !== 0 && <><span style={{color: Colors.blk, marginRight: 10}}>Valor:</span>{Filters.convertMoneyTextMask(courseValue ?? item.vl_curso)}</>}
        </span>
      </div>
      </div>
    </div>
  );
};
