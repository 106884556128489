import React from "react";
import { Space, Tooltip } from "antd";
import { Colors, Metrics } from "configs";
import { FiRefreshCw, FiXCircle } from "react-icons/fi";
import styled from "styled-components";

const CustomSpace = styled(Space)`
  .ant-space-item {
    display: flex;
  }
`;

const styles = {
  icons: {
    cursor: "pointer",
  },
  paymentOptBtns: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBlock: Metrics.spacing.md,
  },
  paymentOptBtn: {
    position: "relative",
    boxShadow: "1px 1px 7px #cacaca",
    backgroundColor: Colors.white,
    borderRadius: Metrics.defaultRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `${Metrics.spacing.lg} ${Metrics.spacing.xlg}`,
    margin: Metrics.spacing.sm,
    width: "33%",
    cursor: "pointer",
    transition: "all 0.4s ease",
  },
  paymentOptBtnSelected: {
    backgroundColor: Colors.primary,
    color: Colors.white,
  },
  paymentOptImg: {
    width: "40px",
    marginBottom: Metrics.spacing.sm,
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function HeaderContent({ data, openModal }) {
  function getTitleByPaymentType(type) {
    if (type === "CREDIT_CARD") {
      return "CARTÃO DE CRÉDITO";
    }
    if (type === "BOLETO") {
      return "BOLETO";
    }
    return "OUTRO";
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <CustomSpace>
        <span>{data.name_course}</span>
        {/* <span style={{
          fontWeight: "bold",
          color: Colors.blk
        }}>{data.tipoPagamento && `(${getTitleByPaymentType(data.tipoPagamento)})`}</span> */}
      </CustomSpace>
      {/* {+data.vl_curso > 0 && data.pagamentos.length === 0 ? (
        <>
          <Tooltip title="Gerar pagamento">
            <FiRefreshCw
              style={styles.icons}
              onClick={() => {
                openModal({ data, chargeCancel: false });
              }}
            />
          </Tooltip>
        </>
      ) : (
        <CustomSpace>
          {data.podeCancelar && (
            <FiXCircle
              style={styles.icons}
              onClick={(e) => {
                e.stopPropagation();
                openModal({ data, chargeCancel: true });
              }}
            >
              CANCELAR
            </FiXCircle>
          )}
          <span>
            {+data.totalPago}/{+data.totalPago + +data.totalAberto}
          </span>
        </CustomSpace>
      )} */}
    </div>
  );
}
