import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../reducers/common";

export const useCommonUpdateSearchHandler = () => {
  const dispatch = useDispatch();
  return useCallback(
    (newValue) => {
      dispatch(Creators.updateSearch(newValue));
    },
    [dispatch]
  );
};

export const useCommonSearch = () => {
  return useSelector((state) => state.common.search);
};
