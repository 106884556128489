import { SearchOutlined } from "@ant-design/icons";
import { SiFacebook } from "react-icons/si";
import { FcGoogle } from "react-icons/fc";
import { ButtonStyled, IconContainer } from "./styles";
import { Colors, Metrics } from "configs";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Constants } from "utils";
import {
  useAuthLoginFacebookRequest,
  useAuthLoginGoogleRequest,
} from "store/hooks/auth";

const SocialButton = ({ children, facebook = false }) => {
  const loginFacebookHandlers = useAuthLoginFacebookRequest();
  const loginGoogleHandlers = useAuthLoginGoogleRequest();

  const authFacebookHandlers = (token) => {
    loginFacebookHandlers(token);
  };
  const authGoogleHandlers = (token) => {
    loginGoogleHandlers(token);
  };

  const responseFacebook = (response) => {
    if (response["accessToken"]) {
      authFacebookHandlers(response["accessToken"]);
    }
  };

  const responseGoogle = (response) => {
    if (response["accessToken"]) {
      authGoogleHandlers(response["accessToken"]);
    }
  };

  if (facebook) {
    return (
      <FacebookLogin
        appId={Constants.FACEBOOK_CLIENT_ID}
        callback={responseFacebook}
        render={({ onClick }) => (
          <ButtonStyled
            width="200px"
            icon={<SearchOutlined />}
            onClick={onClick}
          >
            <SiFacebook style={styles.facebookIcon} />
            <span>{children}</span>
          </ButtonStyled>
        )}
      />
    );
  } else {
    return (
      <GoogleLogin
        clientId={Constants.GOOGLE_CLIENT_ID}
        render={({ onClick }) => (
          <ButtonStyled
            width="200px"
            icon={<SearchOutlined />}
            onClick={onClick}
          >
            <IconContainer>
              <FcGoogle style={styles.googleIcon} />
            </IconContainer>
            <span>{children}</span>
          </ButtonStyled>
        )}
        onSuccess={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    );
  }
};

const styles = {
  facebookIcon: {
    fontSize: Metrics.fontSize.xxlg,
    color: Colors.white,
    padding: "2px",
  },
  googleIcon: {
    fontSize: Metrics.fontSize.xlg,
    color: Colors.white,
    padding: "2px",
    backgroundColor: Colors.white,
    borderRadius: "50%",
  },
};

export default SocialButton;
