import React from 'react';
import { Select } from 'antd';
import Flex from '../Flex';
import { Colors } from 'configs';

const { Option } = Select;

const Dropdown = ({
  label,
  value,
  multiple,
  onChange,
  propertyLabel = 'label',
  propertyValue = 'value',
  placeholder = null,
  list = [],
  required,
  error,
  minWidth,
  width,
  ...rest
}) => {
  const handleChange = newValue => {
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Flex dir="column">
      {label && (
        <label
          style={{ marginRight: 30, color: error ? Colors.error : 'inherit' }}>
          {label + (required ? '*' : '')}
        </label>
      )}
      <Select
        style={{
          minWidth,
          width,
          border: error ? `1px solid ${Colors.error}` : 'none',
        }}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        {...rest}
        {...(multiple ? { mode: 'multiple' } : [])}>
        {list.map((item, index) => (
          <Option key={index} value={item[propertyValue]}>
            {item[propertyLabel]}
          </Option>
        ))}
      </Select>
    </Flex>
  );
};

export default Dropdown;
