import { useRef } from "react";
import { Typography } from "antd";
import { Colors } from "configs";
import { Button, Row } from "common";
import logo from "assets/images/logo-column.png";
import logoDiocese from "assets/images/Logotipo-brasao-diocese-branca.png";
import {
  FacebookFilled,
  InstagramOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
import {
  Container,
  FormFooterContainer,
  IconContainer,
  Input,
  LogoContainer,
  TextArea,
  TitleContainer,
  TitleMark,
} from "./styles";
import { Form, FormItem } from "formik-antd";
import { Formik } from "formik";
import { ContactValidationSchema } from "./ValidationSchema";
import useFetch from "hooks/useFetch";
import { contactRequest } from "services/contact";
import { Toast } from "utils";

const { Text } = Typography;

const ContactFooter = () => {
  const formikRef = useRef();
  const [{ isFetching }, fetch] = useFetch(
    contactRequest,
    null,
    false,
    null,
    null,
    () => {
      formikRef && formikRef.current && formikRef.current.handleReset();
      Toast.showSuccessMessage("Formulário de contato enviado com sucesso!");
    }
  );

  const handleSubmit = (data) => {
    fetch(data);
  };

  return (
    <Container id="contact">
      <TitleContainer>
        <Text style={{ color: Colors.white, fontSize: "2rem" }} strong>
          Dúvidas e sugestões? Entre em contato:
        </Text>
        <Text style={{ color: Colors.white, fontSize: "1.2rem" }} strong>
          Studium | Centro de Formação da Diocese de Joinville.
        </Text>
        <TitleMark />
      </TitleContainer>

      <Row
        responsive
        style={{ justifyContent: "space-between", marginTop: "30px" }}
      >
        <Formik
          initialValues={{ name: "", email: "", message: "" }}
          onSubmit={handleSubmit}
          validationSchema={ContactValidationSchema}
          validateOnMount
          innerRef={formikRef}
        >
          {({ values, handleSubmit }) => (
            <Form
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "15px",
                  flex: 1,
                }}
              >
                <FormItem name="name" style={styles.nameInput}>
                  <Input
                    placeholder="Seu nome"
                    name="name"
                    value={values.name}
                    required
                  />
                </FormItem>
                <FormItem name="email" style={styles.emailInput}>
                  <Input
                    placeholder="Seu e-mail"
                    name="email"
                    value={values.email}
                    type="email"
                    required
                  />
                </FormItem>
              </div>
              <FormItem name="message" style={styles.messageInput}>
                <TextArea
                  placeholder="Sua mensagem"
                  name="message"
                  value={values.message}
                />
              </FormItem>
              <FormFooterContainer>
                <Button
                  backgroundColor={Colors.white}
                  color={Colors.primary}
                  onClick={handleSubmit}
                >
                  Enviar
                </Button>
              </FormFooterContainer>
            </Form>
          )}
        </Formik>
        <LogoContainer>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <img src={logo} alt="logo" className="logo" />

              <img
                src={logoDiocese}
                alt="logo"
                className="logo"
                style={{
                  cursor: "pointer",
                  maxWidth: "100px",
                  minWidth: "100px",
                }}
              />
            </div>
            <IconContainer>
              <a
                href="https://www.facebook.com/diocesejoinville/"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookFilled style={styles.icons} />
              </a>
              <a
                href="https://www.instagram.com/diocesejoinville/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramOutlined style={styles.icons} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCTYTcGE2vHTq5mQbcllwtgA"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeFilled style={styles.icons} />
              </a>
            </IconContainer>
          </div>
        </LogoContainer>
      </Row>
    </Container>
  );
};

const styles = {
  icons: {
    cursor: "pointer",
    fontSize: "30px",
    color: "#fff",
  },
  nameInput: {
    marginRight: "10px",
    marginBottom: "0",
    flex: 1,
    textAlign: "center",

  },
  emailInput: {
    margin: 0,
    flex: 1,
    marginBottom: "0",
    textAlign: "center",
  },
  messageInput: {
    marginBottom: "0",
    textAlign: "center",
  },
};

export default ContactFooter;
