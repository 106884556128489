import { call, delay, put, takeLatest, select } from "redux-saga/effects";
import { Creators, Types } from "store/reducers/auth";
import {
  changePasswordRequest,
  changePasswordEmailCpfRequest,
  facebookRequest,
  forgotPasswordFinishRequest,
  forgotPasswordRequest,
  googleRequest,
  loginRequest,
  registerRequest,
  updateCredentialsRequest,
} from "services/auth";
import { Storage, Toast } from "utils";
import { push } from "connected-react-router";
import course from "store/reducers/course";

function* loginFailed() {
  yield call(Storage.clearToken);
  yield call(Storage.clear, "studium#account");
  yield put(Creators.loginFailed(null));
}

export function* authenticate({ credentials, returnUrl }) {
  const response = yield call(loginRequest, credentials);
  if (response.ok) {
    try {
      const { token, user } = response.data;
      yield call(Storage.setToken, token.token);

      yield call(Storage.store, "studium#account", user);
      yield put(Creators.loginSuccess(token.token, user));
      if (returnUrl) {
        yield put(push(returnUrl));
      } else {
        yield put(push("my-courses"));
      }
    } catch {
      yield call(loginFailed);
    }
  } else {
    yield call(loginFailed);
  }
}

export function* forgotPassword({ email }) {
  const response = yield call(forgotPasswordRequest, email);
  if (response.ok) {
    Toast.showSuccessMessage(
      "Processo de recuperação de senha iniciado com sucesso. Verifique sua caixa de entrada para mais informações."
    );
    yield put(Creators.forgotPasswordFinished());
    yield delay(1000);
    yield put(push("/login"));
  } else {
    yield put(Creators.forgotPasswordFinished());
  }
}

export function* forgotPasswordFinish({ password, key }) {
  const response = yield call(forgotPasswordFinishRequest, password, key);
  if (response.ok) {
    Toast.showSuccessMessage("Senha alterada com sucesso.");
    yield put(Creators.forgotPasswordFinishFinished());
    yield delay(1000);
    yield put(push("/login"));
  } else {
    yield put(Creators.forgotPasswordFinished());
  }
}

export function* changePassword({ data }) {
  const response = yield call(changePasswordRequest, data);
  if (response.ok) {
    Toast.showSuccessMessage("Sua senha foi alterada com sucesso.");
    yield put(Creators.changePasswordFinished());
    yield put(push("/profile"));
  } else {
    yield put(Creators.changePasswordFinished());
  }
}

export function* changePasswordEmailCpf({ data }) {
  const response = yield call(changePasswordEmailCpfRequest, data);
  if (response.ok) {
    Toast.showSuccessMessage("Sua senha foi alterada com sucesso.");
    yield put(Creators.changePasswordEmailCpfFinished());
    yield put(push("/profile"));
  } else {
    yield put(Creators.changePasswordEmailCpfFinished());
  }
}


export function* register({ credentials, returnUrl }) {
  const response = yield call(registerRequest, credentials);
  if (response.ok) {
    try {
      const { token, user } = response.data;
      yield call(Storage.setToken, token.token);

      yield call(Storage.store, "studium#account", user);
      yield put(Creators.loginSuccess(token.token, user));
      if (returnUrl) {
        yield put(push(returnUrl));
      } else {
        yield put(push("first-access"));
      }
    } catch {
      yield call(loginFailed);
    }
  } else {
    yield call(loginFailed);
  }
}

export function* googleAuthenticate({ accessToken: access_token }) {
  const response = yield call(googleRequest, { access_token });
  if (response.ok) {
    try {
      const { token, user } = response.data;
      yield call(Storage.setToken, token.token);

      yield call(Storage.store, "studium#account", user);
      yield put(Creators.loginSuccess(token.token, user));
      yield put(push("first-access"));
    } catch {
      yield call(loginFailed);
    }
  } else {
    yield call(loginFailed);
  }
}

export function* facebookAuthenticate({ accessToken: access_token }) {
  const response = yield call(facebookRequest, { access_token });
  if (response.ok) {
    try {
      const { token, user } = response.data;
      yield call(Storage.setToken, token.token);

      yield call(Storage.store, "studium#account", user);
      yield put(Creators.loginSuccess(token.token, user));
      yield put(push("first-access"));
    } catch {
      yield call(loginFailed);
    }
  } else {
    yield call(loginFailed);
  }
}

export function* updateCredentials({ credentials, courseId, pendent, returnTo }) {
  const { user } = yield select((state) => state.auth);
  const response = yield call(updateCredentialsRequest, credentials, user.id);
  if (response.ok) {
    const newUser = response.data;
    Toast.showSuccessMessage("Credenciais atualizadas com sucesso!");
    yield call(Storage.store, "studium#account", newUser);
    yield put(Creators.updateCredentialsFinished(newUser));
    if (returnTo) {
      yield put(push(returnTo));
    } else {
      if (courseId && pendent) {
        yield put(push(`course-buy/${courseId}${pendent ? '?pendent=true' : ''}`));
      } else if (courseId) {
        yield put(push(`course-buy/${courseId}?complete=true`));
      } else {
        yield put(push("profile"));
      }
    }
  } else {
    yield put(Creators.defaultFinished());
  }
}

export const authSagas = [
  takeLatest(Types.REGISTER_REQUEST, register),
  takeLatest(Types.LOGIN_REQUEST, authenticate),
  takeLatest(Types.LOGIN_FACEBOOK_REQUEST, facebookAuthenticate),
  takeLatest(Types.LOGIN_GOOGLE_REQUEST, googleAuthenticate),
  takeLatest(Types.FORGOT_PASSWORD_REQUEST, forgotPassword),
  takeLatest(Types.FORGOT_PASSWORD_FINISH_REQUEST, forgotPasswordFinish),
  takeLatest(Types.CHANGE_PASSWORD_REQUEST, changePassword),
  takeLatest(Types.CHANGE_PASSWORD_EMAIL_CPF_REQUEST, changePasswordEmailCpf),
  takeLatest(Types.UPDATE_CREDENTIALS_REQUEST, updateCredentials),
];
