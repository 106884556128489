import styled from 'styled-components';
import { Table as ATable } from 'antd';
import { Colors } from 'configs';

const theadBorderRadius = 10;
const spaceBorderColor = Colors.primaryStrong;

export const Table = styled(ATable)`
  &.ant-table-wrapper {
    // background: ${Colors.primaryStrong};
    .ant-table-column-title {
      color: ${Colors.white};
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.8px;
    }
    .ant-table-thead {
      > tr {
        th {
          background: ${Colors.primary};
          color: ${Colors.white};
          padding: 11px 13px;
          // border-top: 3px solid ${spaceBorderColor};
          // border-bottom: 3px solid ${spaceBorderColor};

          &:first-child {
            border-top-left-radius: ${theadBorderRadius}px;
            border-bottom-left-radius: ${theadBorderRadius}px;
          }
          &:last-child {
            border-top-right-radius: ${theadBorderRadius}px;
            border-bottom-right-radius: ${theadBorderRadius}px;
          }
          &.ant-table-column-has-actions {
            &.ant-table-column-has-sorters {
              &:hover {
                // background: ${Colors.primaryStrong};
              }
            }
          }
        }
      }
    }
    .ant-table-tbody >
      tr.ant-table-row {
        // background: ${Colors.primaryStrong};
      }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      // background: ${Colors.primary};
    }
  }
  .ant-table-content {
    // background: ${Colors.primaryStrong};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${Colors.primary};
  }
`;
