import './styles.css';

export default ({
  children,
  responsive,
  reverse,
  style,
  ...rest
}) => {
  return (
    <div className={`${responsive ? 'row-responsive' : ''} ${reverse ? 'row-reverse-common' : 'row-common'}`} style={{...style}} {...rest}>{children}</div>
  );
};
