import React from "react";
import PageTemplate from "template/PageTemplate";
import { Formik } from "formik";
import { Form as FormAntd, Typography, Space } from "antd";
import { Colors, Metrics } from "configs";
import Input from "common/FormInputCustom";
import { Button, FormCheckbox } from "common";
import { FiCreditCard, FiCalendar, FiUnlock, FiInfo } from "react-icons/fi";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { CardIcon } from "../util";
import { Filters } from "utils";
import { ValidationSchema } from "./ValidationSchema";

const { Text } = Typography;

export default function Form({
  initialValues = { numero: "", validade: "", cvv: "", nome: "" },
  handleSubmit,
}) {
  function onSubmit(values) {
    const data = {
      ...values,
      numero: values.numero && values.numero.replaceAll(" ", ""),
      mes_validade: values.validade && values.validade.split("/")[0],
      ano_validade: values.validade && values.validade.split("/")[1],
    };
    handleSubmit && handleSubmit(data);
  }

  return (
    <PageTemplate contentStyle={styles.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {({ values, handleSubmit }) => (
          <FormAntd style={styles.form}>
            <div style={styles.wrapper}>
              <Text
                style={{
                  color: Colors.primary,
                  fontSize: Metrics.fontSize.sm,
                  fontWeight: 700,
                }}
              >
                {initialValues && initialValues.id
                  ? "Edição de cartão"
                  : "Adicionar cartão"}
              </Text>
              <div style={styles.inputContainer}>
                <Input
                  placeholder="Apelido"
                  prefix={<FiInfo />}
                  name="apelido"
                  value={values.apelido}
                  maxLength={255}
                />
                <Input
                  placeholder="0000 0000 0000 0000"
                  prefix={<FiCreditCard />}
                  suffix={
                    <CardIcon
                      numero={values.numero}
                      bandeira={initialValues.bandeira}
                    />
                  }
                  name="numero"
                  value={Filters.formatCardNumber(values.numero)}
                  maxLength={19}
                />
                <Input
                  placeholder="Nome no cartão"
                  name="nome"
                  prefix={<UserIcon />}
                  value={values.nome}
                  maxLength={50}
                />
                <Space align="flex-start">
                  <Input
                    placeholder="DD/AAAA"
                    prefix={<FiCalendar style={{ fontSize: 20 }} />}
                    name="validade"
                    value={Filters.maskCreditCardValidity(values.validade)}
                    maxLength={7}
                  />
                  <Input
                    placeholder="CVV"
                    prefix={<FiUnlock style={{ fontSize: 20 }} />}
                    name="cvv"
                    value={values.cvv}
                    maxLength={4}
                    password
                    iconRender={() => <></>}
                  />
                </Space>
                <FormCheckbox
                  onChange={console.log}
                  name="padrao"
                  value={values.padrao}
                  label="Cartão padrão"
                  style={{ marginLeft: 16 }}
                />
              </div>
              <Button width="200px" type="submit" onClick={handleSubmit}>
                {initialValues && initialValues.id ? "Editar" : "Adicionar"}
              </Button>
            </div>
          </FormAntd>
        )}
      </Formik>
    </PageTemplate>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: Colors.white,
    padding: "40px 60px",
    borderRadius: "20px",
    boxShadow: "0px 1px 6px #0000001A",
    minWidth: "30%",
    maxWidth: "400px",
  },
  form: {
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginTop: Metrics.spacing.xlg,
    marginBottom: Metrics.spacing.xlg,
    width: "100%",
  },
};
