import api from "./api";
import { Base64 } from "../utils";
import copyToClipboard from "copy-to-clipboard";
import { message } from "antd";
// import qrCode from "assets/img/qrCode.png";

const downloadBankSlipById = async (param, { id, download, paste }) => {
  const responseGenerate = await api.get(`api/boleto/buscaPorId/${id}`);
  if (responseGenerate.data.nossoNumero) {
    const { nossoNumero } = responseGenerate.data;
    const responseDownload = await api.get(
      `api/boleto/imprimir/${nossoNumero}`
    );
    const view = Base64.converBase64Pdf(responseDownload.data.arquivo);
    const data = new Blob([view], { type: "application/pdf" });
    const url = window.URL.createObjectURL(data);
    if (paste) {
      copyToClipboard(responseDownload.data.linhaDigitavel);
      message.success("Linha digitável copiada com sucesso.");
    }
    if (download) {
      const filename = "Boleto-Studium.pdf";
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", filename);
      tempLink.click();
    }
    if (!download && !paste) {
      const win = window.open(url);
      win.onload = function () {
        setTimeout(() => {
          this.document.title = "Impressão de boleto";
        }, 300);
      };
    }
    return new Promise((resolve) =>
      resolve({
        ok: true,
        data: { ...responseGenerate.data, ...responseDownload.data },
      })
    );
  }
};

const generateBankSlipAndPickPdfRequest = async (
  cursoID,
  { qtdParcela, codPromo }
) => {
  try {
    const responseGenerate = await api.post("api/boleto/emissaoBoletoSicredi", {
      cursoID,
      qtdParcela,
      codPromo,
    });
    if (responseGenerate && responseGenerate.ok) {
      if (responseGenerate.data.cadastro_incompleto) {
        return new Promise((resolve) => {
          resolve({ ok: true, data: { cadastro_incompleto: true } });
        });
      }
      if (responseGenerate.data.nossoNumero) {
        const { nossoNumero } = responseGenerate.data;
        const responseDownload = await api.get(
          `api/boleto/imprimir/${nossoNumero}`
        );
        return new Promise((resolve) =>
          resolve({
            ok: true,
            data: { ...responseGenerate.data, ...responseDownload.data },
          })
        );
      }
      if (responseGenerate.data && responseGenerate.data.length) {
        const { data } = responseGenerate;

        return new Promise((resolve) =>
          resolve({
            ok: true,
            data,
          })
        );
      }
    }
  } catch (e) {
    return new Promise((resolve) => resolve({ ok: false }));
  }
};

const generateBankSlipRequest = (params, data) => {
  return api.post("api/boleto/emissaoBoletoSicredi", {
    ...data,
  });
};


const printBankSlipRequest = (params, nossoNumero) => {
  return api.get(`api/boleto/imprimir/${nossoNumero}`);
};

const generateChargeRequest = (params, data) => {
  return api.post("api/asaas/Cobranca", {
    ...data,
  });
};

const cancelChargeRequest = (params, data) => {
  return api.post("api/asaas/CancelamentoCobranca", {
    ...data,
  });
};

const generatePix = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data: {
          value: 59.0,
          // qrCode,
        },
      });
    }, 1500);
  });
};

export {
  generateChargeRequest,
  downloadBankSlipById,
  generateBankSlipRequest,
  printBankSlipRequest,
  generatePix,
  generateBankSlipAndPickPdfRequest,
  cancelChargeRequest,
};
