import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

function openCountdownModal({
  time = 5,
  title,
  content,
  replace = false,
  okText = "Ok",
  onTimeOver = () => {},
}) {
  let secondsToGo = time;
  let okClicked = false;

  const modal = Modal.warning({
    title,
    content: replace ? content.replaceAll("[time]", secondsToGo) : content,
    okText,
    onOk: () => {
      okClicked = true;
      modal.destroy();
      clearInterval(timer);
      onTimeOver();
    },
  });

  function handleClose() {
    modal.destroy();
    clearInterval(timer);
  }

  const timer = setInterval(() => {
    secondsToGo -= 1;
    modal.update({
      content: replace ? content.replaceAll("[time]", secondsToGo) : content,
    });
  }, 1000);

  setTimeout(() => {
    if (!okClicked) {
      onTimeOver();
    }
    handleClose();
  }, secondsToGo * 1000);
}

const openDeleteModal = ({
  title,
  description,
  onOk,
  onCancel,
  okText = "Sim",
  cancelText = "Não",
  icon: Icon,
  okType = "danger",
}) => {
  confirm({
    title,
    icon: Icon ? <Icon /> : <CloseCircleOutlined />,
    content: description,
    okText,
    okType,
    cancelText,
    onOk() {
      onOk && onOk();
    },
    onCancel() {
      onCancel && onCancel();
    },
  });
};

const showAlert = ({ title = null, text }) => {
  Modal.warning({
    title: title || "Alerta",
    content: text,
  });
};

export default {
  openDeleteModal,
  showAlert,
  openModal: openDeleteModal,
  openCountdownModal,
};
