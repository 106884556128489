import styled, { css } from "styled-components";
import { Colors, Metrics } from "configs";

export const SummaryItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft && "20%"};
  color: ${({ color }) => color};
  font-size: 14px;
  font-weight: 600;

  :before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    margin-right: 5px;
  }
`;

export const AlternativeItem = styled.input`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft && "20%"};
  color: ${Colors.secondary};
  font-size: 16px;
  margin-bottom: ${Metrics.spacingMD};

  :before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid ${Colors.primary};
  }

  ${({ correct }) =>
    correct &&
    css`
      :before {
        border: none;
        background-color: ${Colors.greenCheck};
      }
    `}

  ${({ userAnswer }) =>
    userAnswer &&
    css`
      :before {
        border: none;
        background-color: ${Colors.primary};
      }
    `}
`;
