import React from "react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import "moment/locale/pt-br";
import configureStore from "store";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { store } = configureStore();
const ptbr = {
  ...ptBR,
  DatePicker: {
    ...ptBR.DatePicker,
    dateFormat: "DD/MM/YYYY",
    dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
    weekFormat: "YYYY-wo",
    monthFormat: "YYYY-MM",
  },
};

const Providers = ({ children }) => {
  toast.configure();

  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
      <ConfigProvider locale={ptbr}>{children}</ConfigProvider>
    </Provider>
  );
};

export default Providers;
