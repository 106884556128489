import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import UserForm from '../Form';
import { findUserRequest, updateUserRequest } from '../../../../services/user';
import useFetch from '../../../../hooks/useFetch';
import CourseForm from '../Form';
import { findCourseByIdRequest, updateCourseRequest } from '../../../../services/course';
import TopicForm from '../Form';
import { findTopicRequest } from '../../../../services/topics';
import BackofficeUsers from '../index';
import { findParishRequest, updateParishRequest } from '../../../../services/parish';
import ParishForm from '../Form';


const BackofficeParishesEdit = () => {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching: isFetchingUser, data: user }] = useFetch(findParishRequest, id, true, null);
  const [{ isFetching, data }, createUser] = useFetch(
    updateParishRequest,
    '',
    false,
    null
  );

  useEffect(() => {
    if (data) {
      history.goBack();
    }
  }, [history, data]);

  return (
    <PageTemplate title="Edição de Usuário" background>
      <Spin spinning={isFetching}>
        {user && (
          <ParishForm
            initialValues={user}
            handleSubmitForm={createUser}
            handleCancel={goBack}
          />
        )}
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeParishesEdit;
