import React from 'react';
import { Formik } from 'formik';
import { Form } from 'antd';
import { FormButtons, FormInputDate, FormInputText, FormTextArea } from 'components';

const TopicForm = ({ handleSubmitForm, initialValues, handleCancel, course }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmitForm}>
      {({ handleSubmit, values, resetForm }) => (
        <Form>
          <FormInputText
            name="ds_titulo"
            label="Título"
            required
            value={values.ds_titulo}
          />

          {
            course?.has_module && (
              <FormInputDate
                label="Início do módulo"
                name="dt_start"
                required
                value={values.dt_start}
              />
            )
          }

          <FormTextArea
            label="Descrição"
            name="ds_descricao"
            required
            value={values.ds_descricao}
          />


          <FormButtons
            bt2={{
              label: 'Adicionar',
              onClick: () => {
                handleSubmit(values);
                setTimeout(() => resetForm(), 0)
              }
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TopicForm;
