import { Colors } from 'configs';
import { Text as TextStyled } from './styles';

export default ({
  size = 12,
  color = Colors.primary,
  lineThrough,
  children,
  bold = false,
  ...rest
}) => {
  return (
    <TextStyled
      lineThrough={lineThrough}
      size={size}
      color={color}
      bold={bold ? 'bolder' : 'initial'}
      {...rest}>
      {children}
    </TextStyled>
  );
};
