import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Metrics } from '../../../../../configs';
import { CourseWizardContext } from '../index';
import TrainingForm from '../../../Trainings/Form';
import useFetch from '../../../../../hooks/useFetch';
import {
  createTopicRequest,
  deleteTopicRequest,
  findTopicRequest,
  findTopicsByCourseRequest,
} from '../../../../../services/topics';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Dropdown, Spin, Table, Collapse } from 'antd';
import TableMenu from '../../components/TableMenu';
import { AiOutlineMore } from 'react-icons/ai';
import TopicForm from '../../../Topics/Form';
import { Select } from 'antd';
import {
  createTrainingRequest,
  deleteTrainingRequest, findTrainingRequest,
  findTrainingsByTopicRequest, updateTrainingRequest,
} from '../../../../../services/training';
import { Col } from '../../../../../common';
import { Toast } from '../../../../../utils';
const { Panel } = Collapse;

const { Option } = Select;
const styles = {
  formContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    marginTop: Metrics.spacing.md}
}

const TrainingData = () => {
  const { currentStep, setCurrentStep, course, setCourse } = useContext(CourseWizardContext);
  const [activeKey, setActiveKey] = useState();
  const [currentTopic, setCurrentTopic] = useState();
  const [initialValues, setInitialValues] = useState({
    topico_id: currentTopic,
  });
  const [{ isFetching, data }, createTraining] = useFetch(
    createTrainingRequest,
    '',
    false,
    null,
    () => {},
    () => {
      if (currentTopic) {
        fetchTrainings(currentTopic);
      }
      setInitialValues({
        topico_id: currentTopic,
      })
    });
  const [{ isFetching: isFetchingUpdate }, updateTraining] = useFetch(
    updateTrainingRequest,
    '',
    false,
    null,
    () => {},
    () => {
      if (currentTopic) {
        fetchTrainings(currentTopic);
      }
      setInitialValues({
        topico_id: currentTopic,
      })
    });
  const [{ isFetching: isFetchingTopics, data: topics}, fetchTopics] = useFetch(findTopicsByCourseRequest, course.id, true, []);
  const [{ isFetching: isFetchingTraining, data: training}, fetchTrainingById] = useFetch(
    findTrainingRequest,
    '',
    false,
    null,
    () => {},
    (result) => {
      setInitialValues(result.data);
      openAccordion();
    });
  const [{ isFetching: isFetchingTrainings, data: trainings}, fetchTrainings] = useFetch(findTrainingsByTopicRequest, '', false, []);
  const [{ isFetching: isDeletingTraining}, deleteTopic] = useFetch(
    deleteTrainingRequest,
    '',
    false,
    false,
    () => {},
    () => currentTopic ? fetchTrainings(currentTopic) : () => {});
  const openAccordion = useCallback( () => setActiveKey('1'),[setActiveKey]);
  const closeAccordion = useCallback( () => setActiveKey(''),[setActiveKey]);

  useEffect(() => {
    if (currentTopic) {
      setInitialValues(i => ({ ...i, topico_id: currentTopic}))
      fetchTrainings(currentTopic);
    }
  },[currentTopic])

  const columns = [
    {
      title: "Título",
      dataIndex: "ds_titulo",
      key: "ds_titulo",
    },
    {
      title: "Resumo",
      dataIndex: "ds_resumo",
      key: "ds_resumo",
    },
    {
      title: "Ativo",
      dataIndex: "fg_ativo",
      key: "fg_ativo",
      width: 10,
      render: (text, record) => (
        <span>
          { record.fg_ativo ?
            <CheckCircleOutlined /> :
            <CloseOutlined />
          }
        </span>
      ),
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 10,
      render: (text, record) => (
        <span>
          <Dropdown
            overlay={
              <TableMenu
                record={record}
                handleRemove={deleteTopic}
                handleEdit={(t)=> {
                  fetchTrainingById(t.id);
                }}
                deleteProp='id'
                descProp='ds_titulo'
                desc='Treinamento'
              />}
            placement="bottomRight"
            trigger={["click"]}>
            <AiOutlineMore size={25} style={{ cursor: "pointer" }} />
          </Dropdown>
        </span>
      ),
    },
  ];

  return (
    <div style={styles.formContainer}>
      <h1><b>Treinamentos</b></h1>
      <Col style={{marginBottom: Metrics.spacing.md}}>
        <span>Selecione o <b>tópico</b> ao qual pertencem os treinamentos listados e o treinamento a ser cadastrado:</span>
        <Select style={{ width: 370 }} onChange={setCurrentTopic} placeholder='Selecione...'>
          {topics.map((t, i) =>
            <Option key={i} value={t.id}>{t.ds_titulo}</Option>
          )}
        </Select>
      </Col>
      <Collapse activeKey={activeKey} accordion onChange={(e) => {
        if (!currentTopic) {
          Toast.showErrorMessage('Selecione o tópico ao qual este treinamento deve pertencer!');
        } else {
          setActiveKey(e);
        }
      }} style={{marginBottom: Metrics.spacing.lg}}>
        <Panel header="Cadastro/Edição de Treinamento" key="1" >
          <TrainingForm
            handleSubmitForm={(val) => {
              if (val.id) {
                closeAccordion();
                updateTraining(val);
              } else {
                closeAccordion();
                createTraining(val);
              }
            }}
            initialValues={initialValues}
            handleCancel={() => {}} />
        </Panel>
      </Collapse>
      { currentTopic &&
        <Table style={styles.table} columns={columns} dataSource={trainings} />
      }
      {isDeletingTraining &&
      <Spin />
      }
    </div>
  );
};

export default TrainingData;
