import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators } from "../reducers/auth";

export const useAuthLoginRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (credentials, returnUrl) => {
      dispatch(Creators.loginRequest(credentials, returnUrl));
    },
    [dispatch]
  );
};

export const useAuthLoginFacebookRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (accessToken) => {
      dispatch(Creators.loginFacebookRequest(accessToken));
    },
    [dispatch]
  );
};

export const useAuthLoginGoogleRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (accessToken) => {
      dispatch(Creators.loginGoogleRequest(accessToken));
    },
    [dispatch]
  );
};

export const useAuthRegisterRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (credentials, returnUrl) => {
      dispatch(Creators.registerRequest(credentials, returnUrl));
    },
    [dispatch]
  );
};

export const useAuthForgotPasswordRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (email) => {
      dispatch(Creators.forgotPasswordRequest(email));
    },
    [dispatch]
  );
};

export const useAuthForgotPasswordFinishRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (password, key) => {
      dispatch(Creators.forgotPasswordFinishRequest(password, key));
    },
    [dispatch]
  );
};

export const useAuthChangePasswordRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (data) => {
      dispatch(Creators.changePasswordRequest(data));
    },
    [dispatch]
  );
};

export const useAuthChangePasswordEmailCpfRequest = () => {
  const dispatch = useDispatch();
  return useCallback(
    (data) => {
      dispatch(Creators.changePasswordEmailCpfRequest(data));
    },
    [dispatch]
  );
};

export const useAuthLogout = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(Creators.logout());
  }, [dispatch]);
};

export const useAuthSelectTenant = () => {
  const dispatch = useDispatch();
  return useCallback(
    (tenant) => {
      dispatch(Creators.selectTenant(tenant));
    },
    [dispatch]
  );
};

export const useAuthSelectTenantInternal = () => {
  const dispatch = useDispatch();
  return useCallback(
    (tenant) => {
      dispatch(Creators.selectTenantInternal(tenant));
    },
    [dispatch]
  );
};

export const useAuthShowTenantSelectorHandler = () => {
  const dispatch = useDispatch();
  return useCallback(
    (tenants) => {
      dispatch(Creators.necessaryTenant(tenants));
    },
    [dispatch]
  );
};

export const useAuthUpdateCredentialsHandler = () => {
  const dispatch = useDispatch();
  return useCallback(
    (credentials, courseId, pendent, returnTo) => {
      dispatch(Creators.updateCredentialsRequest(credentials, courseId, pendent, returnTo));
    },
    [dispatch]
  );
};

export const useAuthToken = () => {
  return useSelector((state) => state.auth.token);
};

export const useAuthUser = () => {
  return useSelector((state) => state.auth.user);
};

export const useAuthCity = () => {
  return useSelector((state) => state.auth.city);
};

export const useAuthIsFetching = () => {
  return useSelector((state) => state.auth.isfetching);
};

export const useAuthAuthenticated = () => {
  return useSelector((state) => state.auth.authenticated);
};

export const useAuthTenants = () => {
  return useSelector((state) => state.auth.tenants);
};

export const useAuthShowTenantSelector = () => {
  return useSelector((state) => state.auth.showTenantSelector);
};

export const useAuthTenantConfig = () => {
  return useSelector((state) => state.auth.tenantConfig);
};
