const primaryStrong = '#3fab09';
const primary = '#3EB3A9';
const secondary = '#5C6A79';
const softGray = '#ececec';
const blk = '#000';
const error = '#ff4d4f';
const white = '#ffffff';
const appBg = '#f3f3f3';
const greenCheck = '#88B52D';
const yellow = '#facf34';

export default {
  primary,
  primaryStrong,
  secondary,
  error,
  white,
  appBg,
  greenCheck,
  blk,
  yellow,
  softGray,
};
