import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PageTemplate from 'template/PageTemplate';
import { Spin } from 'antd';
import useFetch from '../../../../hooks/useFetch';
import { findCourseByIdRequest } from '../../../../services/course';
import CourseWizard from '../CourseWizard';


const BackofficeCourseEdit = () => {
  const history = useHistory();
  const { goBack } = history;
  const { id } = useParams();
  if (!id) {
    goBack();
  }
  const [{ isFetching: isFetchingUser, data: course }] = useFetch(findCourseByIdRequest, id, true, null);

  return (
    <PageTemplate title="Edição de Curso" background>
      <Spin spinning={isFetchingUser}>
        {course && (
          <CourseWizard
            initialValues={course}
          />
        )}
      </Spin>
    </PageTemplate>
  );
};

export default BackofficeCourseEdit;
