const pageSize = 10;
const smPageSize = 5;
const pBiFilterType = 1;
const pBiSchema = "http://powerbi.com/product/schema#basic";
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "fixedId";
const FACEBOOK_CLIENT_ID =
  process.env.REACT_APP_FACEBOOK_CLIENT_ID || "fixedId";
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1024 }

export default {
  BREAKPOINTS,
  pageSize,
  smPageSize,
  pBiSchema,
  pBiFilterType,
  GOOGLE_CLIENT_ID,
  FACEBOOK_CLIENT_ID,
};
