import styled from 'styled-components';
import { Form } from 'antd';
import { Metrics } from 'configs';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  min-height: 100%;
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Container = styled(Form)`
  padding: 50px 60px;
  background: #fff;
  box-shadow: 0 1px 6px #0000001a;
  width: 50% !important;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .ant-form-item {
    width: 100%;
  }

  @media (max-width: 1304px) {
    width: 80% !important;
  }
`;

export const Space = styled.div`
  display: flex;
  > :not(:nth-child(1)) {
    margin-left: 50px;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: ${Metrics.spacing.xlg};
  margin-bottom: ${Metrics.spacing.xlg};
  width: 100%;
`;
