import React, { useContext, useEffect } from 'react';
import useFetch from '../../../../../hooks/useFetch';
import { createCourseRequest, updateCourseRequest } from '../../../../../services/course';
import { Metrics } from '../../../../../configs';
import CourseForm from '../../Form';
import { CourseWizardContext } from '../index';
import { Toast } from '../../../../../utils';

const styles = {
  formContainer: {
    backgroundColor: 'white',
    padding: Metrics.spacing.md,
    borderRadius: Metrics.defaultRadius,
    marginTop: Metrics.spacing.md}
}

const CourseData = () => {
  const { currentStep, setCurrentStep, course, setCourse } = useContext(CourseWizardContext);
  const [{ isFetching, data }, createCourse] = useFetch(
    createCourseRequest,
    '',
    false,
    null
  );
  const [{ isFetching: isFetchingUpdateCourse, data: updatedCourse }, updateCourse] = useFetch(
    updateCourseRequest,
    '',
    false,
    null,
    () => {},
    () => Toast.showSuccessMessage("Curso alterado com sucesso!")
  );

  useEffect(() => {
    if (data) {
      setCourse(data);
      setCurrentStep(1);
    }
  },[data])

  return (
    <div style={styles.formContainer}>
      <h1><b>Dados do curso</b></h1>
      <CourseForm handleSubmitForm={(val) => {
        if (val.id) {
          updateCourse(val);
        } else {
          createCourse(val);
        }
      }} initialValues={course} handleCancel={() => {}} />
    </div>
  );
};

export default CourseData;
